import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useRef } from "react";
import * as Queries from "../../utils/queries";
import * as yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";

const categorySchema = yup.object({
  title: yup.string().required("Lütfen Kategori Adı Giriniz"),
  description: yup.string().required("Lütfen Açıklama Giriniz"),
  photo: yup.string().url().required("Lütfen Resim Seçiniz"),
});

const CategoryDialog = ({
  openCategoryDialog,
  setOpenCategoryDialog,
  categoriesRefetch
}) => {
  const fileInputRef = useRef(null);

  const { handleSubmit, handleChange, handleReset, errors, touched, values, setValues } = useFormik({
    initialValues: {
      title: "",
      description: "",
      photo: "",
    },
    onSubmit: async () => {
      const response = await Queries.createCategory({
        ...values,
        slug: values.title
      });

      if (response?._id) {
        toast.success("Kategori başarılı bir şekilde oluşturuldu");
        handleReset();
        categoriesRefetch();
        setOpenCategoryDialog(false)
      } else {
        toast.error("Kategori oluşturulurken bir hata meydana geldi. Lütfen tekrar deneyiniz")
      }
    },
    validationSchema: categorySchema
  });

  const handleImagesUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const response = await Queries.uploadMedia(file);

      if (response) {
        setValues({ ...values, photo: response?.secure_url })
      }
    } else {
      toast.error('Lütfen Resim Seçiniz');
      e.target.value = null; // Dosya seçiciyi temizle
    }
  };

  const handleImageInput = () => {
    fileInputRef.current.click();
  };

  return (
    <Dialog
      open={openCategoryDialog}
      onClose={() => setOpenCategoryDialog(false)}
      fullWidth
    >
      <DialogTitle>{"Kategori Ekle"}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} className="flex flex-col gap-3">
          {values.photo ?
            <div className="flex flex-1 justify-center items-center">
              <img
                src={values.photo}
                alt="resim"
                className="w-32 h-32 border"
              />
            </div>
            : <></>}

          <div className="flex flex-col space-y-2">
            <label htmlFor="" className="text-[18px]">
              Kategori Adı
            </label>
            <input
              type="text"
              className="flex flex-1 border border-gray-200 p-2  rounded-md shadow-sm"
              value={values.title}
              onChange={handleChange("title")}
            />
            {errors.title && touched.title && (
              <div className="p-2 rounded bg-red-100 text-red-700">
                {errors.title}
              </div>
            )}
          </div>

          <div className="flex flex-col space-y-2">
            <label htmlFor="" className="text-[18px]">
              Kategori Açıklaması
            </label>
            <ReactQuill
              theme="snow"
              value={values.description}
              onChange={(value) => setValues({ ...values, description: value })}
            />
            {errors.description && touched.description && (
              <div className="p-2 rounded bg-red-100 text-red-700">
                {errors.description}
              </div>
            )}
          </div>

          <div className="flex flex-col space-y-2">
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleImagesUpload}
            />
            <button
              type="button"
              onClick={handleImageInput}
              className="p-2 border-solid border-1 border-black flex flex-1 justify-center items-center rounded hover:bg-gray-50">
              Resim Seç
            </button>

            {errors.photo && touched.photo && (
              <div className="p-2 rounded bg-red-100 text-red-700">
                {errors.photo}
              </div>
            )}
          </div>

          <div className="flex flex-row space-x-6">
            <button type="submit" className="w-max px-3 py-2 buttonPurple rounded">
              Oluştur
            </button>

            <button type="button" onClick={() => {
              handleReset();
              setOpenCategoryDialog(false)
            }} className="w-max px-3 py-2 bg-gray-100 hover:bg-gray-200 rounded">
              Vazgeç
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CategoryDialog;
