import axios from "axios"

export const API_URL =
"https://king-prawn-app-fu5mi.ondigitalocean.app";   // 'https://avista-backend.vercel.app' "https://api.sutok.com" 
 
const instance = axios.create({
  baseURL: API_URL,
  timeout: 15 * 1000,
})


instance.interceptors.request.use(request => {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) {
    request.headers.authorization = accessToken;
  }

  return request;
});

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
)

export default instance
