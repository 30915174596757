import { TableRow } from '@material-ui/core';
import { TableCell } from '@mui/material';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const SutokProductCard = ({ product }) => {

    const navigate = useNavigate();

    return (
        <TableRow onClick={() => navigate(`/post?id=${product._id}`)} className="cursor-pointer h-[50px] hover:bg-gray-200 border-b border-gray-200">
            <TableCell>
                {product.title}
            </TableCell>
            <TableCell>{product.sku}</TableCell>
            <TableCell className="bg-gray-200">
                {product.stockState ?
                    <Link to="/post">Stokta</Link> : <div>Stokta Yok</div>}
            </TableCell>
            <TableCell>
                {product.stock}
            </TableCell>
        </TableRow>
    )
}

export default SutokProductCard