import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
  },
  toolbar: theme.mixins.toolbar,
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    margin:20,
    padding:15,
    display: "flex",
    flexDirection: "column",
    gap:10
  },
  formImgContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  userPhoto: {
    width: 70,
    height: 70,
    marginRight: theme.spacing(3),
  },
  userPhotoLargeContainer: {
    width: 150,
    height: 150,
    background: "#CDCDCD",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
  },
  userPhotoLarge: {
    width: 150,
    height: 150,
    cursor: "pointer",
  },
  sliderContainer: {
    flex: 1,
    aspectRatio: 2,
    background: "#CDCDCD",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
  },
  slider: {
    flex: 1,
    aspectRatio: 2,
    cursor: "pointer",
  },
  sliderImg: {
    width: 300,
    aspectRatio: 2,
  },
  userPhotoSmall: {
    width: 40,
    height: 40,
    borderRadius: "50%",
  },
  headerButtons: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  searchContainer: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchInput: {
    width: "350px",
    marginRight: theme.spacing(3),
    padding:10
  },
  searchTypeSelect: {},
  titleContainer: {
    display: "flex",
    padding: "1rem",
    alignItems: "center",
    borderBottom: "1px solid #E0E0E0",
  },
}))
