import { Pagination } from '@mui/material'
import React from 'react'
import OrderCard from './OrderCard'

const Orders = ({ ordersData, ordersFilterQuery, setOrdersFilterQuery }) => {
    return (
        <div className='flex flex-col space-y-3 bg-white rounded border-1 border-gray-100 py-3'>
            <div className='px-3 flex flex-row'>
                <div className='flex flex-1'>
                    <span className='pb-1 border-b borderPurple textPurple'>Siparişler</span>
                </div>

                {(ordersData?.totalCount !== 0) ?
                    <div className='flex flex-1 justify-end text-gray-600 text-sm'>
                        {`${ordersFilterQuery.page * ordersFilterQuery.limit - (ordersFilterQuery.limit - 1)} - ${(ordersData?.totalCount > ordersFilterQuery.limit ? (
                            ordersFilterQuery.page !== ordersData?.totalPage ? ordersFilterQuery.page * ordersFilterQuery.limit
                                : ordersData?.totalCount
                        ) : ordersData?.totalCount)
                            } / ${ordersData?.totalCount} adet`}
                    </div> : <></>}
            </div>

            {(ordersData?.totalCount !== 0) ?
                <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead class="text-xs text-gray-700 bg-gray-100">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Sipariş
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Toplam Tutar
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Tarih
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Sipariş Durumu
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {ordersData?.orders?.map((order) => (
                            <OrderCard
                                key={order._id}
                                order={order}
                            />
                        ))}
                    </tbody>
                </table>
                :
                <div className='w-max p-2 mx-3 rounded bg-blue-100 text-blue-700'>
                    Kullanıcıya ait henüz bir sipariş yok
                </div>}

            {(ordersData?.totalCount !== 0 && ordersData?.totalPage > 1) ?
                <div className='flex flex-1 px-3 justify-center'>
                    <Pagination
                        count={ordersData?.totalPage}
                        page={ordersFilterQuery.page}
                        onChange={(_, value) => setOrdersFilterQuery({ ...ordersFilterQuery, page: value })}
                        variant='outlined'
                        color='primary'
                        shape='rounded'
                    />
                </div> : <></>}
        </div>
    )
}

export default Orders