import React from "react"
import { SwipeableDrawer, Button } from "@material-ui/core"
import { Editor } from "@tinymce/tinymce-react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import * as Queries from "../../utils/queries"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import DialogField from "../../components/DialogField"

const getAttributeFields = () => [
  {
    label: "",
    id: "cover",
    type: "IMAGE",
  },
  {
    label: "Başlık",
    id: "title",
  },
  {
    label: "Açılama",
    id: "description",
  },
]

const articleSchema = yup.object({
  title: yup.string().required(),
  description: yup.string().required(),
  cover: yup.string().required(),
})

const ArticleDrawer = ({ showDrawer, setShowDrawer, selectedArticle, setMessage }) => {
  const editorRef = React.useRef(null)
  const queryClient = useQueryClient()
  const { register, watch, control, handleSubmit } = useForm({
    defaultValues: selectedArticle || {},
    resolver: yupResolver(articleSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const createMutation = useMutation(Queries.saveArticle, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(['LIST_ARTICLES'])
      setMessage("Kaydedildi.")
      setShowDrawer(false)
    },
    onError: (err) => {
      console.log("err", err)
    },
  })

  const updateMutation = useMutation(Queries.updateArticle, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(['LIST_ARTICLES'])
      setMessage("Güncellendi.")
      setShowDrawer(false)
    },
    onError: (err) => {
      console.log("err", err)
    },
  })

  const onValid = (data) => {
    if (!editorRef.current.getContent())
      return setMessage("Lütfen içerik ekleyin.")

    const dto = {
      ...data,
      content: editorRef.current.getContent(),
    }

    if (selectedArticle) {
      updateMutation.mutate([selectedArticle._id, dto])
    } else {
      createMutation.mutate(dto)
    }
  }

  const onInvalid = (errors) => {
    const firstErr = Object.values(errors)[0]

    if (Array.isArray(firstErr)) {
      setMessage(firstErr[0].message)
    } else {
      setMessage(firstErr.message)
    }
  }

  return (
    <SwipeableDrawer
      anchor={"right"}
      disableEnforceFocus
      open={showDrawer}
      onClose={() => setShowDrawer(false)}
      onOpen={() => setShowDrawer(true)}
      PaperProps={{
        style: {
          minWidth: "75%",
        },
      }}
    >
      <div
        style={{
          margin: 20,
        }}
      >
        {getAttributeFields().map((item) => {
          return (
            <DialogField
              item={item}
              control={control}
              register={register}
              watch={watch}
              disabled={false}
            />
          )
        })}

        <div style={{ height: 15 }} />

        <Editor
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={selectedArticle?.content || ""}
          init={{
            height: 400,
            menubar: 'insert',
            placeholder: "İçerik",
            plugins: ["link"],
            selector: 'textarea',
            toolbar:
              "undo redo | formatselect | " +
              "link bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />

        <Button
          variant="contained"
          size="large"
          fullWidth
          style={{
            backgroundColor: "#000000",
            color: "#fff",
            marginTop: 20,
          }}
          onClick={handleSubmit(onValid, onInvalid)}
        >
          Kaydet
        </Button>
      </div>
    </SwipeableDrawer>
  )
}

export default ArticleDrawer
