import { Cross2Icon, TextAlignCenterIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { useQuery } from "react-query";
import * as Queries from "../../utils/queries";
import Select from "react-select";

const FilterPopover = ({
  categoriesData,
  searchQuery,
  setSearchQuery,
  filter,
  setFilter,
}) => {
  const { data: brandsData } = useQuery(
    ["LIST_BRANDS"],
    () => Queries.getBrands("page=1&limit=1000"),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: {
        page: [],
      },
    }
  );

  const { data: productModelsData } = useQuery(
    ["PRODUCT_MODELS_Q"],
    () => Queries.listModels("page=1&limit=1000"),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: {
        page: [],
      },
    }
  );

  const handleReset = async () => {
    setSearchQuery({
      ...searchQuery,
      categoryLabel: "",
      categoryContaining: "",
      brandLabel: "",
      brandContaining: "",
      modelLabel: "",
      modelContaining: "",
    });

    setFilter({
      categoryLabel: "",
      categoryContaining: "",
      brandLabel: "",
      brandContaining: "",
      modelLabel: "",
      modelContaining: "",
    });
  };

  return (
    <>
      <div className="flex items-center  space-y-2 gap-3 justify-center">
        <div className="flex flex-col space-x-3 mt-2">
          <div className="w-20 font-semibold ml-4 mb-2">Kategori</div>
          <div className="flex gap-3">
            <select
              className="px-4 py-2 borderGray rounded w-40"
              value={filter.categoryContaining}
              onChange={(e) =>
                setFilter({ ...filter, categoryContaining: e.target.value })
              }
            >
              <option
                disabled
                value={""}
                selected={filter.categoryContaining === ""}
              >
                Seçiniz
              </option>
              <option
                value={true}
                selected={filter.categoryContaining === true}
              >
                İçeren
              </option>
              <option
                value={false}
                selected={filter.categoryContaining === false}
              >
                İçermeyen
              </option>
            </select>

            <select
              className="px-4 py-2 borderGray rounded w-40"
              value={filter.categoryLabel}
              onChange={(e) =>
                setFilter({ ...filter, categoryLabel: e.target.value })
              }
            >
              <option
                disabled
                value={""}
                selected={filter.categoryContaining === ""}
              >
                Seçiniz
              </option>
              {categoriesData?.page?.length !== 0 &&
                categoriesData?.page?.map((category) => {
                  return (
                    <option
                      key={category._id}
                      value={category._id}
                      selected={filter.categoryLabel === category._id}
                    >
                      {
                        categoriesData.page.find(
                          (item) => item._id === category._id
                        ).title
                      }
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="flex flex-col space-x-3 ">
          <div className="w-20 font-semibold ml-4 mb-2">Marka</div>
          <div className="flex gap-3 items-center ">
            <select
              className="px-4 py-2 borderGray  rounded w-40"
              value={filter.brandContaining}
              onChange={(e) =>
                setFilter({ ...filter, brandContaining: e.target.value })
              }
            >
              <option
                disabled
                value={""}
                selected={filter.brandContaining === ""}
              >
                Seçiniz
              </option>
              <option value={true} selected={filter.brandContaining === true}>
                İçeren
              </option>
              <option value={false} selected={filter.brandContaining === false}>
                İçermeyen
              </option>
            </select>

            {brandsData?.page?.length !== 0 && (
              <Select
                className="w-40"
                isSearchable
                value={
                  filter.brandLabel && {
                    value: filter.brandLabel,
                    label: brandsData?.page.find(
                      (item) => item._id === filter.brandLabel
                    )?.title,
                  }
                }
                defaultValue={filter.brandLabel}
                onChange={(e) => setFilter({ ...filter, brandLabel: e.value })}
                options={brandsData?.page?.map((brand) => {
                  return { value: brand._id, label: brand?.title };
                })}
              />
            )}
          </div>
        </div>

        <div className="flex flex-col space-x-3 ">
          <div className="w-20 font-semibold ml-4 mb-2">Model</div>
          <div className="flex gap-3">
            <select
              className="px-4 py-2 borderGray rounded w-40"
              value={filter.modelContaining}
              onChange={(e) =>
                setFilter({ ...filter, modelContaining: e.target.value })
              }
            >
              <option
                disabled
                value={""}
                selected={filter.modelContaining === ""}
              >
                Seçiniz
              </option>
              <option value={true} selected={filter.modelContaining === true}>
                İçeren
              </option>
              <option value={false} selected={filter.modelContaining === false}>
                İçermeyen
              </option>
            </select>

            {productModelsData?.page?.length !== 0 && (
              <Select
                className="w-40"
                isSearchable
                value={
                  filter.modelLabel && {
                    value: filter.modelLabel,
                    label: productModelsData?.page.find(
                      (item) => item._id === filter.modelLabel
                    )?.title,
                  }
                }
                defaultValue={filter.modelLabel}
                onChange={(e) => setFilter({ ...filter, modelLabel: e.value })}
                options={productModelsData?.page?.map((model) => {
                  return { value: model._id, label: model?.title };
                })}
              />
            )}
          </div>
        </div>

        <div className="flex flex-1 flex-row space-x-3 pt-4">
          <button
            onClick={() => handleReset()}
            className="px-3 py-2 bg-gray-100 hover:bg-gray-200 rounded mt-1"
          >
            Sıfırla
          </button>

          <button
            onClick={() => setSearchQuery({ ...searchQuery, ...filter })}
            className="px-3 py-2 buttonPurple rounded mt-1"
          >
            Uygula
          </button>
        </div>
      </div>
    </>
  );
};

export default FilterPopover;
