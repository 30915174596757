import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "../styles";
import {
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Paper
} from "@material-ui/core";
import CustomDrawer from "../../components/CustomDrawer";
import { InputGroup } from "react-bootstrap";
import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import * as Queries from "../../utils/queries";
import { useQuery } from "react-query";
import Select from "react-select";
import toast from "react-hot-toast";
import VariantCard from "./VariantCard";
import ProductattributeCard from "./ProductattributeCard";
import AddVariantSelectBox from "./AddVariantSelectBox";
import CreateProductattributeForm from "./CreateProductattributeForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ImFolderPlus } from "react-icons/im";
import { FaRegTrashCan } from "react-icons/fa6";
import EditProductAttributes from "./EditProductAttributes";
import { useAtom } from "jotai";
import { userAtom } from "../../utils/atoms";
const ProductDetail = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [user, setUser] = useAtom(userAtom);
  const [productGroupData, setProductGroupData] = useState(null);
  const [selectedAttribute, setSelectedAttribute] = useState(false);
  const [newproductattributes, setNewProductattributes] = useState(null);
  const [newVariantTerms, setNewVariantTerms] = useState({
    size: null,
    color: null,
    otherTerms: []
  });
  const [newVariantClicked, setNewVariantClicked] = useState(false);
  const [isProductattributeFormVisible, setIsProductattributeFormVisible] =
    useState(false);
  const [isFirst, setIsFirst] = useState(false);
  const [productGroupDetails, setProductGroupDetails] = useState(null)
  const [productGroupCategory, setProductGroupCategory] = useState(null)
  const fileInputRef = useRef(null);

  const scrollToX = (id) => {
    const niteliklerElement = document.getElementById(id);
    if (niteliklerElement) {
      niteliklerElement.classList.add("scrollBelirt");
      niteliklerElement.classList.remove("border");
      niteliklerElement.classList.remove("shadow-sm");
      niteliklerElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });

      setTimeout(() => {
        window.scrollBy(0, -67);
        niteliklerElement.classList.remove("scrollBelirt");
        niteliklerElement.classList.add("border");
        niteliklerElement.classList.add("shadow-sm");
      }, 650);
    }
  };

  const genders = [
    {
      value: "UNISEX",
      label: "Unisex",
    },
    {
      value: "MALE",
      label: "Erkek",
    },
    {
      value: "FEMALE",
      label: "Kadın",
    },
  ];

  const {
    data: { productGroup },
    refetch: productGroupRefetch,
  } = useQuery(
    ["getProductGroupDetails", searchParams.get("id")],
    () => Queries.getProductGroupDetails(searchParams.get("id")),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: {
        productGroup: null,
      },
    }
  );

  const { data: categoriesData } = useQuery(
    ["LIST_CATEGORIES"],
    () => Queries.listCategories("page=1&limit=1000"),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: {
        page: [],
      },
    }
  );

  const { data: brandsData } = useQuery(
    ["LIST_BRANDS"],
    () => Queries.getBrands("page=1&limit=1000"),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: {
        page: [],
      },
    }
  );

  const { data: productModelsData } = useQuery(
    ["PRODUCT_MODELS_Q"],
    () => Queries.listModels("page=1&limit=1000"),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: {
        page: [],
      },
    }
  );

  const { data: variantsData, refetch: variantsDataRefetch } = useQuery(
    ["listVariants", searchParams.get("id")],
    () => Queries.listVariants(searchParams.get("id")),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: null,
    }
  );

  const { data: productattributesData, refetch: productattributesRefetch } =
    useQuery(
      ["listAllProductattributes"],
      () => Queries.listAllProductattributes(),
      {
        onError: (err) => {
          console.log("err", err);
        },
        placeholderData: null,
      }
    );

  const {
    data: sellersData,
    refetch: sellersDataRefetch,
  } = useQuery(
    ["getProductSellers", searchParams.get("id")],
    () => Queries.getProductSellers(searchParams.get("id")),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: {
        productGroup: null,
      },
    }
  );

  const changeProductGroupData = async () => {
    //if (!isFirst) {
    setIsFirst(true);

    let brands = [];
    let models = [];

    const category = categoriesData?.page?.find(
      (item) => item?._id === productGroup?.category
    );

    if (productGroup?.brands?.length !== 0) {
      for (let i = 0; i < productGroup?.brands?.length; i++) {
        const brand = brandsData?.page?.find(
          (item) => item?._id === productGroup?.brands[i]
        );
        if (brand) {
          brands = [...brands, { value: brand?._id, label: brand?.title }];
        }
      }
    }
    if (productGroup?.models?.length !== 0) {
      for (let j = 0; j < productGroup?.models?.length; j++) {
        const model = productModelsData?.page?.find(
          (item) => item?._id === productGroup?.models[j]
        );
        if (model) {
          models = [...models, { value: model?._id, label: model?.title }];
        }
      }
    }

    const gender = genders?.find(
      (item) => item?.value === productGroup?.gender
    );

    setProductGroupDetails({
      brands: brands,
      models: models,
      price: productGroup?.price,
      discountedPrice: productGroup?.discountedPrice || 0,
      season: productGroup?.season,
      sku: productGroup?.sku,
      gender: gender,
    })
    setProductGroupCategory({
      category: category ? { value: category._id, label: category.title } : null,
      description: productGroup?.description
    })
    setProductGroupData({
      ...productGroup,
      gender: gender || null,
      pageTitle: productGroup?.pageTitle || "",
      pageDescription: productGroup?.pageDescription || "",
    });
    // }
  };

  const handleUpdateProductGroup = async (data) => {
    await Queries.updateGroup(searchParams.get("id"), data);
    await productGroupRefetch();
    toast.success("Değişiklik başarılı bir şekilde kaydedildi");
  };

  const handleCreateVariant = async () => {
    let terms = [];
    if (newVariantTerms.size) terms.push(newVariantTerms.size.value);
    if (newVariantTerms.color) terms.push(newVariantTerms.color.value);
    if (newVariantTerms?.otherTerms?.length !== 0) {
      for (let i = 0; i < newVariantTerms.otherTerms.length; i++) {
        terms.push(newVariantTerms.otherTerms[i]?.value)
      }
    }

    const response = await Queries.createProduct({
      group: productGroup?._id,
      terms,
      sku: "",
      releaseDate: new Date(),
      colorway: "",
      style: "",
      stock: "0",
      price: `${productGroup?.price || 0}`,
      Images: [],
      kg: 0,
      cmU: 0,
      cmY: 0,
      cmG: 0,
    });
    if (response?._id) {
      toast.success("Varyant başarılı bir şekilde oluşturuldu");
    } else {
      toast.error(
        "Varyant oluşturulurken bir hata oluştu. Lütfen tekrar deneyiniz"
      );
    }
    setNewVariantClicked(false);
    setNewVariantTerms({
      size: null,
      color: null,
      otherTerms: []
    });
    variantsDataRefetch();
  };

  const handleImagesUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const response = await Queries.uploadMedia(file);
      handleUpdateProductGroup({
        photos: [...productGroup?.photos, response?.secure_url],
      });
    } else {
      toast.error(
        "Fotoğraf Seçilirken Bir Hata Oluştu. Lütfen Tekrar Deneyiniz"
      );
      e.target.value = null;
    }
  };

  const handleImageInput = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (productGroup?._id
      && categoriesData?.page?.length !== 0
      && productModelsData?.page?.length !== 0
      && brandsData?.page?.length !== 0) {
      changeProductGroupData();
    }
  }, [productGroup, categoriesData, brandsData, productModelsData]);

  /*useEffect(() => {
    productGroupRefetch();
    variantsDataRefetch();
  }, [searchParams.get("id")]);*/

  useEffect(() => {
    if (newVariantClicked) {
      if (
        (productGroup?.attributes.length > 1 &&
          newVariantTerms.size &&
          newVariantTerms.color) ||
        (productGroup?.attributes.length === 1 &&
          (newVariantTerms.size || newVariantTerms.color))
      ) {
        handleCreateVariant();
      } else {
        setNewVariantClicked(false);
      }
    }
  }, [newVariantClicked, newVariantTerms]);

  return (
    <div className="flex">
      <CustomDrawer />

      <div className={classes.content}>
        <Toolbar />

        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-3 bg-white shadow-sm border rounded-md p-3">
            <button
              className=" border-b border-purple-800 text-purple-800"
              onClick={() => scrollToX("temelBilgi")}
            >
              Temel Bilgi
            </button>
            <button
              className="text-gray-600"
              onClick={() => scrollToX("urunDetay")}
            >
              Ürün Detayı
            </button>
            <button
              className="text-gray-600"
              onClick={() => scrollToX("kategori")}
            >
              Kategori
            </button>
            <button
              className="text-gray-600 no-underline"
              onClick={() => scrollToX("asds")}
            >
              Nitelikler
            </button>
            <button
              className="text-gray-600 no-underline"
              onClick={() => scrollToX("varyant")}
            >
              Varyant
            </button>
            <button
              className="text-gray-600 no-underline"
              onClick={() => scrollToX("seo")}
            >
              Arama Motoru Optimizasyonu (SEO)
            </button>
            <button
              className="text-gray-600 no-underline"
              onClick={() => scrollToX("sellers")}
            >
              Ürüne Stok Ekleyen Satıcılar
            </button>
          </div>

          <div
            style={{
              borderWidth: 1, borderColor: (!productGroupData?.title) ? "red" : "#f3f4f6"
            }}
            className="shadow-sm rounded-md p-3 bg-white flex flex-col gap-2"
            id="temelBilgi"
          >
            <h6>Temel Bilgi</h6>
            <div>
              <p className={`${!productGroupData?.title ? "text-red-600" : ""}`}>Ürün Adı</p>
              <Form.Control
                type="text"
                placeholder="Ürün Adı"
                value={productGroupData?.title}
                onChange={(e) =>
                  setProductGroupData({
                    ...productGroupData,
                    title: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <p>Ürün Alt Adı</p>
              <Form.Control
                type="text"
                placeholder="Ürün Alt Adı"
                value={productGroupData?.subtitle}
                onChange={(e) =>
                  setProductGroupData({
                    ...productGroupData,
                    subtitle: e.target.value,
                  })
                }
              />
            </div>

            <button
              onClick={() => {
                if (productGroupData.title) {
                  handleUpdateProductGroup({
                    title: productGroupData?.title,
                    subtitle: productGroupData?.subtitle,
                  });
                } else {
                  toast.error("Lütfen Başlık Giriniz")
                }
              }}
              className="w-max buttonPurple px-3 py-2 rounded"
            >
              Kaydet
            </button>
          </div>

          {/* Ürün Detay */}
          <div
            style={{
              borderWidth: 1, borderColor: ((!productGroupDetails?.brands || productGroupDetails?.brands?.length === 0)
                || (!productGroupDetails?.models || productGroupDetails?.models?.length === 0)
                || !productGroupDetails?.sku
                || !productGroupDetails?.price
                || (!productGroupDetails?.gender || !productGroupDetails?.gender?.value)
                || !productGroupDetails?.season) ? "red" : "#f3f4f6"
            }}
            className="bg-white p-3 shadow-sm rounded-md"
            id="urunDetay"
          >
            <div className=" flex justify-between items-center">
              <h6>Ürün Detay</h6>
            </div>
            <Form.Group className="flex flex-1 flex-col gap-3">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-3">
                <div>
                  <Form.Label className={`${(!productGroupDetails?.brands || productGroupDetails?.brands?.length === 0) ? "text-red-600" : ""}`}>Marka</Form.Label>
                  {brandsData?.page?.length !== 0 && (
                    <Select
                      isMulti
                      isSearchable
                      value={productGroupDetails?.brands}
                      onChange={(e) =>
                        setProductGroupDetails({ ...productGroupDetails, brands: e })
                      }
                      options={brandsData?.page?.map((brand) => {
                        return { value: brand._id, label: brand?.title };
                      })}
                    />
                  )}
                </div>
                <div>
                  <Form.Label className={`${!productGroupDetails?.sku ? "text-red-600" : ""}`}>SKU Numarası</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Jotun"
                    value={productGroupDetails?.sku}
                    onChange={(e) =>
                      setProductGroupDetails({
                        ...productGroupDetails,
                        sku: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <Form.Label className={`${(!productGroupDetails?.models || productGroupDetails?.models?.length === 0) ? "text-red-600" : ""}`}>Model</Form.Label>
                  {productModelsData?.page?.length !== 0 && (
                    <Select
                      isMulti
                      isSearchable
                      value={productGroupDetails?.models}
                      onChange={(e) =>
                        setProductGroupDetails({ ...productGroupDetails, models: e })
                      }
                      options={productModelsData?.page?.map((model) => {
                        return { value: model._id, label: model?.title };
                      })}
                    />
                  )}
                </div>
                <div>
                  <Form.Label className={`${(!productGroupDetails?.gender || !productGroupDetails?.gender?.value) ? "text-red-600" : ""}`}>Cinsiyet</Form.Label>
                  <Select
                    value={productGroupDetails?.gender}
                    onChange={(e) =>
                      setProductGroupDetails({ ...productGroupDetails, gender: e })
                    }
                    options={genders}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                <div>
                  <Form.Label className={`${!productGroupDetails?.price ? "text-red-600" : ""}`}>Fiyat</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Fiyat"
                    value={productGroupDetails?.price}
                    onChange={(e) =>
                      setProductGroupDetails({
                        ...productGroupDetails,
                        price: e.target.value,
                      })
                    }
                  />
                </div>

                <div>
                  <Form.Label className={`${(!productGroupDetails?.discountedPrice && productGroupDetails?.discountedPrice !== 0) ? "text-red-600" : ""}`}>İndirimli Fiyat</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="İndirimli Fiyat"
                    value={productGroupDetails?.discountedPrice}
                    onChange={(e) =>
                      setProductGroupDetails({
                        ...productGroupDetails,
                        discountedPrice: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div>
                <Form.Label className={`${!productGroupDetails?.season ? "text-red-600" : ""}`}>Sezon</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Sezon"
                  value={productGroupDetails?.season}
                  onChange={(e) =>
                    setProductGroupDetails({
                      ...productGroupDetails,
                      season: e.target.value,
                    })
                  }
                />
              </div>

              <button
                onClick={() => {
                  if (productGroupDetails?.brands?.length !== 0
                    && productGroupDetails?.models?.length !== 0
                    && productGroupDetails?.sku
                    && productGroupDetails?.price
                    && productGroupDetails?.discountedPrice
                    && productGroupDetails?.season
                    && productGroupDetails?.gender?.value) {
                    handleUpdateProductGroup({
                      brands: productGroupDetails.brands?.map((item) => {
                        return item.value;
                      }),
                      models: productGroupDetails.models?.map((item) => {
                        return item.value;
                      }),
                      sku: productGroupDetails?.sku || productGroupDetails?.sku,
                      price: productGroupDetails?.price || productGroupDetails?.price,
                      discountedPrice: productGroupDetails?.discountedPrice || productGroupDetails?.discountedPrice,
                      season: productGroupDetails?.season || productGroupDetails?.season,
                      gender: productGroupDetails.gender.value || productGroupDetails?.gender,
                    });
                  } else {
                    if (productGroupDetails?.brands?.length === 0) {
                      toast.error("Lütfen Marka Seçiniz")
                    } else if (productGroupDetails?.models?.length === 0) {
                      toast.error("Lütfen Model Seçiniz")
                    } else if (!productGroupDetails?.sku) {
                      toast.error("Lütfen Sutok Kodu Giriniz")
                    } else if (!productGroupDetails?.price) {
                      toast.error("Lütfen Fiyat Giriniz")
                    } else if (!productGroupDetails?.discountedPrice) {
                      toast.error("Lütfen İndirimli Fiyat Giriniz")
                    } else if (!productGroupDetails?.season) {
                      toast.error("Lütfen Sezon Bilgisi Giriniz")
                    } else if (!productGroupDetails?.gender?.value) {
                      toast.error("Lütfen Cinsiyet Seçiniz")
                    }
                  }
                }}
                className="w-max buttonPurple px-3 py-2 rounded"
              >
                Kaydet
              </button>
            </Form.Group>
          </div>
          {/* Görseller */}
          <div className="p-3 border shadow-sm rounded-md bg-white">
            <div className="flex justify-between">
              <p>Ürün Görseli</p>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleImagesUpload}
              />
              <button
                disabled={productGroup?.photos?.length === 10}
                onClick={handleImageInput}
                className="textPurple flex items-center gap-2"
              >
                <ImFolderPlus />
                Daha Fazla
              </button>
            </div>
            <div className="grid grid-cols-4 justify-center items-center gap-3">
              {productGroup?.photos?.map((photo, index) => {
                return (
                  <div key={index} className="relative trashMain">
                    <img
                      src={photo}
                      className=" h-[250px]  object-cover "
                      style={{ borderRadius: "7px" }}
                      alt=""
                    />
                    <div>
                      <button
                        onClick={() =>
                          handleUpdateProductGroup({
                            photos: productGroup?.photos?.filter(
                              (item) => item !== photo
                            ),
                          })
                        }
                        className="absolute top-4 right-3 p-1  trashDiv"
                      >
                        <FaRegTrashCan color="red" />{" "}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* Kategori */}
          <div
            style={{
              borderWidth: 1, borderColor: (
                (!productGroupCategory?.category || !productGroupCategory?.category?.value)
                || (!productGroupCategory?.description || productGroupCategory?.description === "<p><br></p>")) ? "red" : "#f3f4f6"
            }}
            className="bg-white p-3 shadow-sm rounded-md flex flex-col gap-3"
            id="kategori"
          >
            <div className=" flex justify-between items-center">
              <h6 className={`${(!productGroupCategory?.category || !productGroupCategory?.category?.value) ? "text-red-600" : ""}`}>Kategori</h6>
              {/*<button className="text-purple-800 pb-2 flex items-center gap-3">
                Kategorileri Düzenle
                <BiPencil />
            </button>*/}
            </div>

            {categoriesData?.page?.length !== 0 && (
              <Select
                isSearchable
                value={productGroupCategory?.category}
                onChange={(e) =>
                  setProductGroupCategory({ ...productGroupCategory, category: e })
                }
                options={categoriesData?.page?.map((category) => {
                  return { value: category._id, label: category?.title };
                })}
              />
            )}
            <h6 className={`${(!productGroupCategory?.description || productGroupCategory?.description === "<p><br></p>") ? "text-red-600" : ""}`}>Açıklama</h6>
            <ReactQuill
              theme="snow"
              value={productGroupCategory?.description}
              onChange={(value) =>
                setProductGroupCategory({ ...productGroupCategory, description: value })
              }
            />

            <span className="h-[50px]"></span>

            <button
              onClick={() => {
                if (productGroupCategory.category && productGroupCategory?.description && productGroupCategory?.description !== "<p><br></p>") {
                  handleUpdateProductGroup({
                    category: productGroupCategory.category.value,
                    description: productGroupCategory.description,
                  });
                } else {
                  if (!productGroupCategory.category) {
                    toast.error("Lütfen Kategori Seçiniz")
                  } else {
                    toast.error("Lütfen Açıklama Giriniz")
                  }
                }
              }}
              className="w-max buttonPurple px-3 py-2 rounded"
            >
              Kaydet
            </button>
          </div>

          {/* Nitelikler */}

          <div
            className="bg-white p-3 shadow-sm border rounded-md flex flex-col gap-3 "
            id="asds"
          >
            <div className="flex items-center justify-between">
              <h6>
                {isProductattributeFormVisible ? "Nitelik Ekle" : "Nitelikler"}
              </h6>
              {user?.role === "ADMIN" ?
                <div className="flex flex-col justify-start gap-1">
                  {!isProductattributeFormVisible && (
                    <button
                      onClick={() => setIsProductattributeFormVisible(true)}
                      className="textPurple pb-2 flex items-center gap-3"
                    >
                      Nitelik Ekle
                    </button>
                  )}
                </div> : <></>}
            </div>

            {!isProductattributeFormVisible ? (
              <div className="flex flex-col gap-3">
                <div className="flex flex-1 gap-3 items-center">
                  <button
                    onClick={() => {
                      if (newproductattributes) {
                        handleUpdateProductGroup({
                          attributes: productGroup?.attributes
                            ? [
                              ...productGroup?.attributes,
                              newproductattributes.value,
                            ]
                            : [newproductattributes.value],
                        });
                      }
                    }}
                    className=" p-2 whitespace-nowrap text-purple-600 border  rounded-md"
                  >
                    Yeni Ekle
                  </button>
                  <div className="flex flex-1">
                    {productattributesData?.length !== 0 && (
                      <Select
                        className="w-full"
                        isSearchable
                        value={newproductattributes}
                        onChange={(value) => setNewProductattributes(value)}
                        options={productattributesData?.map((item) => {
                          return { value: item._id, label: item?.title };
                        })}
                      />
                    )}
                  </div>
                </div>

                {/* {productGroup?.attributes &&
                  productGroup?.attributes?.length !== 0 ? (
                  productGroup?.attributes?.map((item) => {
                    return (
                      <ProductattributeCard
                        key={item}
                        id={item}
                        productGroup={productGroup}
                        productattributesData={productattributesData}
                        handleUpdateProductGroup={handleUpdateProductGroup}
                      />
                    );
                  })
                ) : (
                  <div className="p-2 rounded flex flex-1 bg-blue-100 text-blue-700">
                    Bu Ürüne Henüz Bir Nitelik Eklenmemiş
                  </div>
                )} */}

                <div>
                  <TableContainer className="rounded-md">
                    <Table>
                      <TableHead className="bg-[#EEF2F6]">
                        <TableRow>
                          <TableCell>Tür</TableCell>
                          <TableCell>Tanımlanmış Değer</TableCell>
                          <TableCell className="w-[100px]">İşlem</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {productGroup?.attributes?.map(item => {
                          return (
                            <ProductattributeCard
                              key={item}
                              handleUpdateProductGroup={handleUpdateProductGroup}
                              id={item}
                              productGroup={productGroup}
                              productattributesData={productattributesData}
                              selectedAttribute={selectedAttribute}
                              setSelectedAttribute={setSelectedAttribute} />
                          )
                        })}
                        {/*
                        <TableRow>
                          <TableCell>Boyut</TableCell>
                          <TableCell>
                            <div className="flex flex-wrap gap-2">
                              <button className="flex items-center justify-center gap-2 p-2 w-[80px] border rounded-md">
                                37{" "}
                                <span
                                  className={`${seeLayout === "size" ? "" : "hidden"}`}
                                >
                                  <GridCloseIcon style={{ color: "#dedede" }} />
                                </span>
                              </button>
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <button
                              onClick={() => {
                                setSeeLayout("size");
                                if (seeLayout === "size") {
                                  setSeeLayout(false);
                                }
                              }}
                            >
                              <BiPencil />
                            </button>
                          </TableCell>
                        </TableRow>
                        */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {selectedAttribute ?
                    <EditProductAttributes
                      selectedAttribute={selectedAttribute}
                      setSelectedAttribute={setSelectedAttribute}
                      productGroup={productGroup}
                      handleUpdateProductGroup={handleUpdateProductGroup}
                    /> : <></>}
                </div>
              </div>
            ) : (
              <CreateProductattributeForm
                setIsProductattributeFormVisible={
                  setIsProductattributeFormVisible
                }
                productattributesRefetch={productattributesRefetch}
              />
            )}
            {/*
              <button className="p-2 buttonPurple rounded-md w-[150px]">
              Nitelikleri Kaydet
            </button>
            */}
          </div>

          {/* Varyant */}

          <div
            className="bg-white p-3 shadow-sm border rounded-md flex flex-col gap-3 "
            id="varyant"
          >
            <div className="flex items-center justify-between">
              <h6>Varyant</h6>
            </div>

            <div className="flex flex-col gap-3">
              {productGroup?.productattributeterms &&
                productGroup?.productattributeterms?.length !== 0 ? (
                <div className="flex flex-1 gap-3 items-center">
                  <button
                    onClick={() => setNewVariantClicked(true)}
                    className="text-purple-600 border p-2 whitespace-nowrap rounded-md"
                  >
                    Varyasyon Oluştur
                  </button>
                  <div className="flex flex-1 flex-row space-x-3">
                    {productGroup?.attributes?.map((item) => {
                      return (
                        <AddVariantSelectBox
                          key={item}
                          id={item}
                          newVariantTerms={newVariantTerms}
                          setNewVariantTerms={setNewVariantTerms}
                          productGroup={productGroup}
                          productattributesData={productattributesData}
                          newVariantClicked={newVariantClicked}
                          setNewVariantClicked={setNewVariantClicked}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="flex flex-col gap-3 overflow-auto max-h-[600px] pr-2">
                {variantsData?.length !== 0 &&
                  variantsData?.map((variant) => {
                    return (
                      <VariantCard
                        key={variant?._id}
                        variant={variant}
                        variantsDataRefetch={variantsDataRefetch}
                        productGroup={productGroup}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
          {/* Arama Motoru */}
          <div id="seo" className="grid grid-cols-2 gap-3 border border-gray-200  bg-white p-3  shadow-sm rounded-md">
            <div>
              <h5 className="">Arama Motoru Optimizasyonu (SEO)</h5>
              <InputGroup size="md">
                <InputGroup.Text id="inputGroup-sizing-lg">/</InputGroup.Text>
                <Form.Control
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  value={productGroupData?.slug}
                  onChange={(e) =>
                    setProductGroupData({
                      ...productGroupData,
                      slug: e.target.value,
                    })
                  }
                />
              </InputGroup>
              <Form.Group className="mt-3">
                <Form.Label>Sayfa Başlığı</Form.Label>
                <Form.Control
                  type="text"
                  className="max-w-[738px]"
                  value={productGroupData?.pageTitle}
                  onChange={(e) =>
                    setProductGroupData({
                      ...productGroupData,
                      pageTitle: e.target.value,
                    })
                  }
                />
                <Form.Label>Açıklama</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  value={productGroupData?.pageDescription}
                  onChange={(e) =>
                    setProductGroupData({
                      ...productGroupData,
                      pageDescription: e.target.value,
                    })
                  }
                />

                <button
                  onClick={() => {
                    if (
                      productGroupData?.slug &&
                      productGroupData.pageTitle &&
                      productGroupData?.pageDescription
                    ) {
                      handleUpdateProductGroup({
                        slug: productGroupData?.slug,
                        pageTitle: productGroupData?.pageTitle,
                        pageDescription: productGroupData?.pageDescription,
                      });
                    }
                  }}
                  className="w-max buttonPurple px-3 py-2 rounded mt-2"
                >
                  Kaydet
                </button>
              </Form.Group>
            </div>
            <div>
              <h5 className="text-purple-800">Önizleme</h5>
              <div className="p-3 bg-gray-100 border border-radius shadow-sm rounded-md">
                <p>sutok.com /shopping/{productGroupData?.slug}</p>
                <p className="text-purple-900">
                  {productGroupData?.pageTitle || "-"}
                </p>
                <p>{productGroupData?.pageDescription}</p>
              </div>
            </div>
          </div>

          {/* Ürüne Stok Ekleyen Satıcılar */}
          <div
            id="sellers" className="flex flex-col gap-3 border border-gray-200  bg-white p-3  shadow-sm rounded-md">
            <h5 className="">Ürüne Stok Ekleyen Satıcılar</h5>

            {sellersData?.length > 0 ?
              <TableContainer component={Paper}>
                <Table>
                  <TableHead className="bg-[#EEF2F6]">
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Adı Soyadı</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Eklediği Stok Sayısı</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sellersData?.map(item => {
                      return (
                        <TableRow key={item?._id} onClick={() => navigate(`/satici-detay?id=${item?._id}`)} className="flex flex-1 flex-row gap-3 p-3 rounded border hover:bg-gray-50 cursor-pointer">
                          <TableCell>{item?._id}</TableCell>
                          <TableCell>{`${item?.name} ${item?.surname}`}</TableCell>
                          <TableCell>{item?.email}</TableCell>
                          <TableCell>{item?.stockCount}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer> : <div className="p-3 flex flex-1 rounded bg-blue-100 text-blue-700">
                Ürüne Stok Ekleyen Satıcı Bulunamamaktadır
              </div>}

          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
