import { TableCell, TableRow } from "@material-ui/core";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import React from "react";
import { FaArrowUpRightFromSquare, FaRegImage, FaSlash } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const ModelCard = ({ modelData }) => {
  const navigation = useNavigate();
  return (
    <TableRow onClick={() => {
      navigation(`/modelDetails?id=${modelData._id}`);
    }} className="border-b hover:bg-gray-100 cursor-pointer">
      <TableCell
        scope="row"
        class="px-6 py-3 font-medium text-gray-900 whitespace-nowrap"
      >
        <div className="w-max p-1 border border-gray-200 rounded">
          {modelData.cover ? (
            <img src={modelData?.cover} alt="Fotoğraf" className="w-16 h-16" />
          ) : (
            <FaRegImage className="w-16 h-16" />
          )}
        </div>
      </TableCell>
      <TableCell class="px-6 py-3">{modelData.title}</TableCell>
      <TableCell class="px-6 py-3">
        {modelData.parentModel?.title || "-"}
      </TableCell>
      <TableCell class="px-6 py-3 text-black">
        {modelData.brand?.title || "-"}
      </TableCell>
    </TableRow>
  );
};

export default ModelCard;
