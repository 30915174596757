import { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import * as Queries from '../../utils/queries'
import { useAtom } from "jotai";
import { userAtom } from "../../utils/atoms";

const VariantCard = ({ productGroup, variant, variantsDataRefetch }) => {

    const [user, setUser] = useAtom(userAtom);
    const [variantData, setVariantData] = useState(null)
    const fileInputRef = useRef(null);
    const defaultImages = Array.from({ length: 6 }, (_, index) => index);

    const handleUpdate = async (item) => {
        let data;
        if (item) {
            data = {
                ...item,
                terms: variantData?.terms.map(item => { return item._id }),
                releaseDate: new Date(),
                colorway: item?.colorway || "",
                style: item?.style || "",
                stock: item?.stock || "0",
                price: item?.price || "0",
                kg: parseInt(item?.kg) || 0,
                cmU: parseInt(item?.cmU) || 0,
                cmY: parseInt(item?.cmY) || 0,
                cmG: parseInt(item?.cmG) || 0,
            };
        } else {
            data = {
                ...variantData,
                terms: variantData?.terms.map(item => { return item._id }),
                releaseDate: new Date(),
                colorway: variantData?.colorway || "",
                style: variantData?.style || "",
                stock: variantData?.stock || "0",
                price: variantData?.price || `${productGroup?.price}` || "0",
                kg: parseInt(variantData?.kg) || 0,
                cmU: parseInt(variantData?.cmU) || 0,
                cmY: parseInt(variantData?.cmY) || 0,
                cmG: parseInt(variantData?.cmG) || 0,
            };
        }
        delete data._id;
        delete data.__v;
        const response = await Queries.updateProduct(variant?._id, {
            terms: {
                size: (!variant.terms[0]?.colorCode ? variant.terms[0]?._id : variant.terms[1]?._id) || null,
                color: (variant.terms[0]?.colorCode ? variant.terms[0]?._id : variant.terms[1]?._id) || null
            },
            product: data
        });
        if (!item) {
            if (response?._id) {
                if (user?.role === "SELLER" && data.stock !== "0") {
                    toast.success("Stok ekleme isteğiniz oluşturuldu. İsteğiniz yetkililer tarafından kontrol edilecek.");
                } else {
                    toast.success("Varyant başarılı bir şekilde güncellendi");
                }
            } else {
                toast.error("Varyant Güncellenirken bir hata oluştu. Lütfen tekrar deneyiniz")
            }
        }
        variantsDataRefetch();
    }

    const handleImagesUpload = async (e) => {
        const files = e.target.files;
        if (files && files.length > 0 && files.length < 7) {
            let list = [];
            for (let i = 0; i < files.length; i++) {
                const response = await Queries.uploadMedia(files[i]);
                list.push(response.secure_url);
            }

            setVariantData({ ...variantData, Images: list })
        } else {
            toast.error('En fazla 6 resim seçebilirsiniz.');
            e.target.value = null; // Dosya seçiciyi temizle
        }
    };

    const handleImageInput = () => {
        fileInputRef.current.click();
    };

    const handleDelete = async () => {
        await handleUpdate({ ...variantData, stock: "0" });
        const response = await Queries.deleteProduct(variant?._id);
        if (response?.message === "Product has been deleted") {
            toast.success("Varyant başarılı bir şekilde silindi");
        } else {
            toast.error("Varyant silinirken bir hata oluştu. Lütfen tekrar deneyiniz")
        }
        await variantsDataRefetch();
    }

    useEffect(() => {
        setVariantData(variant);
    }, [variant])

    return (
        <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="0">
                <Accordion.Header>{variantData?.terms?.length === 1 ?
                    variantData?.terms[0]?.value
                    : variantData?.terms?.map((item, index) => {
                        return (
                            <span key={index}>
                                {index + 1 === variantData?.terms?.length ?
                                    item.value : ` ${item.value} - `}
                            </span>
                        )
                    })}</Accordion.Header>
                <Accordion.Body>
                    <div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 mb-2">
                            <div style={{ flex: 1 }} className="grid grid-cols-6 gap-3">
                                {(variantData?.Images && variantData?.Images?.length !== 0) ? (
                                    variantData?.Images?.map((image, index) => {
                                        return (
                                            <div className="flex flex-1">
                                                <img
                                                    key={index}
                                                    src={image}
                                                    alt={`image ${index + 1}`}
                                                    className="flex flex-1 object-contain"
                                                />
                                            </div>
                                        )
                                    })
                                ) :
                                    <>
                                        {defaultImages.map((index) => (
                                            <img
                                                key={index}
                                                src="https://placehold.co/400"
                                                alt=""
                                                className="w-[70px] h-[70px] object-contain"
                                            />
                                        ))}
                                    </>}
                            </div>
                            <input
                                type="file"
                                multiple
                                accept="image/*"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                onChange={handleImagesUpload}
                            />
                            <button
                                onClick={handleImageInput}
                                className="p-1 border-dashed border-2 border-black flex flex-1 justify-center items-center rounded hover:bg-gray-50">
                                Resim Seç
                            </button>
                        </div>

                        <div className="flex flex-col gap-3">
                            <div className="grid grid-cols-2 gap-3">
                                <div>
                                    <Form.Label>Stok Kodu(SKU)</Form.Label>
                                    <Form.Control type="text"
                                        value={variantData?.sku}
                                        onChange={(e) => setVariantData({ ...variantData, sku: e.target.value })} />
                                </div>
                                <div>
                                    <Form.Label>Normal Fiyat</Form.Label>
                                    <Form.Control type="text"
                                        value={variantData?.price}
                                        onChange={(e) => setVariantData({ ...variantData, price: e.target.value })} />
                                </div>
                            </div>
                            <div>
                                <Form.Label>Stok Sayısı</Form.Label>
                                <Form.Control type="number"
                                    value={variantData?.stock}
                                    onChange={(e) => setVariantData({ ...variantData, stock: e.target.value })} />
                            </div>
                            <div className="grid grid-cols-2 gap-3">
                                <div>
                                    <Form.Label>Ağırlık (KG)</Form.Label>
                                    <Form.Control type="number"
                                        value={variantData?.kg}
                                        onChange={(e) => setVariantData({ ...variantData, kg: e.target.value })} />
                                </div>
                                <div>
                                    <Form.Label>Boyutlar (U x G x Y) (cm)</Form.Label>
                                    <div className="flex gap-3">
                                        <Form.Control
                                            type="number"
                                            placeholder="uzunluk"
                                            value={variantData?.cmU}
                                            onChange={(e) => setVariantData({ ...variantData, cmU: e.target.value })} />
                                        <Form.Control
                                            type="number"
                                            placeholder="Genişlik"
                                            value={variantData?.cmG}
                                            onChange={(e) => setVariantData({ ...variantData, cmG: e.target.value })} />
                                        <Form.Control
                                            type="number"
                                            placeholder="Yükseklik"
                                            value={variantData?.cmY}
                                            onChange={(e) => setVariantData({ ...variantData, cmY: e.target.value })} />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-1 flex-row">
                                <div className="flex flex-1">
                                    <button onClick={() => handleUpdate()} className="px-3 py-2 buttonPurple rounded">
                                        Kaydet
                                    </button>
                                </div>

                                <div className="flex flex-1 justify-end">
                                    <button onClick={() => handleDelete()} className="px-3 py-2 rounded bg-red-600 hover:bg-red-700 text-white">
                                        Sil
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default VariantCard