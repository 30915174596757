import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Portal,
    Snackbar,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import DialogField from "../../components/DialogField"
import { useMutation, useQueryClient } from "react-query"
import * as Queries from "../../utils/queries"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import CustomDialogActions from "../../components/CustomDialogActions"
import moment from "moment"

const getFeeFields = () => {
    return [
        {
            label: "Komisyon Bedeli",
            id: "commissionFee",
        },
        {
            label: "Hizmet Bedeli",
            id: "serviceFee",
        }
    ]
}

const feeSchema = yup.object({
    commissionFee: yup.string().test({
        name: 'is-number',
        exclusive: true,
        message: 'Lütfen bir sayı girin.',
        test: value => /^\d+$/.test(value), // Sadece sayıya izin verir
    }).required("Bu alan boş bırakılamaz."),
    serviceFee: yup.string().required("Bu alan boş bırakılamaz."),
});

const FeeDialog = ({
    openFeeDialog,
    setOpenFeeDialog,
    initialData,
}) => {

    const [message, setMessage] = React.useState()
    const queryClient = useQueryClient();
    const [userBirthDate, setUserBirthDate] = useState(null)

    const { register, watch, control, handleSubmit } = useForm({
        resolver: yupResolver(feeSchema),
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            commissionFee: initialData?.commissionFee || "",
            serviceFee: initialData?.serviceFee || "",
        },
    })

    const updateMutation = useMutation(
        (userId, commissionFee, serviceFee) => Queries.updateUserFees(userId, commissionFee, serviceFee),
        {
            onSuccess: (res) => {
                console.log("res", res)
                setOpenFeeDialog(false)
                setMessage("Kullanıcı güncellendi.")
                queryClient.invalidateQueries(["LIST_USERS"])
            },
            onError: (err) => {
                console.log("err", err)
            },
        }
    )

    const onValid = (data) => {
        updateMutation.mutate({ userId: initialData._id, ...data })
    }

    const onInvalid = (errors) => {
        const firstErr = Object.values(errors)[0]

        if (Array.isArray(firstErr)) {
            setMessage(firstErr[0].message)
        } else {
            setMessage(firstErr.message)
        }
    }

    /*const [message, setMessage] = React.useState()
    const queryClient = useQueryClient()

    const createMutation = useMutation(Queries.createFee, {
        onSuccess: (res) => {
            console.log("res", res)
            setOpenFeeDialog(false)
            setMessage("Varyant oluşturuldu.")
            queryClient.invalidateQueries(["LIST_PRODUCTS"])
        },
        onError: (err) => {
            console.log("err", err)
        },
    })

    const updateMutation = useMutation(
        ([termId, termDto]) => Queries.updateFee(termId, termDto),
        {
            onSuccess: (res) => {
                console.log("res", res)
                setOpenFeeDialog(false)
                setMessage("Varyant güncellendi.")
                queryClient.invalidateQueries(["LIST_PRODUCTS"])
            },
            onError: (err) => {
                console.log("err", err)
            },
        }
    )

    const deleteMutation = useMutation(Queries.deleteFee, {
        onSuccess: (res) => {
            console.log("res", res)
            setOpenFeeDialog(false)
            setMessage("Varyant silindi.")
            queryClient.invalidateQueries(["LIST_PRODUCTS"])
        },
        onError: (err) => {
            console.log("err", err)
        },
    })

    const onValid = (data) => {
        const { sku, releaseDate, colorway, style, ...rest } = data

        if (
            Object.values(rest).filter((val) => !!val).length !==
            attributeInformations.length
        ) {
            return setMessage("Please fill all required fields")
        }

        const dto = {
            sku,
            releaseDate: moment(releaseDate).toDate(),
            colorway,
            style,
            group: selectedFeeGroup._id,
            terms: Object.values(rest),
        }

        if (initialData) {
            updateMutation.mutate([initialData._id, dto])
        } else {
            createMutation.mutate(dto)
        }
    }

    const onInvalid = (errors) => {
        const firstErr = Object.values(errors)[0]

        if (Array.isArray(firstErr)) {
            setMessage(firstErr[0].message)
        } else {
            setMessage(firstErr.message)
        }
    }*/

    const editUserBirthDate = async (date) => {
        const formattedDate = (new Date(date)).toLocaleDateString('tr-TR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
        await setUserBirthDate(formattedDate)
    }

    useEffect(() => {
        if (initialData.birthDate) {
            editUserBirthDate(initialData.birthDate)
        }
    }, [initialData])

    return (
        <>
            <Dialog
                open={openFeeDialog}
                onClose={() => setOpenFeeDialog(false)}
                fullWidth
            >
                <DialogTitle>{"Kullanıcı Detayı"}</DialogTitle>
                <DialogContent>
                    <div>
                        <span className='fw600'>Adı: </span>
                        <span>{initialData.name || "-"}</span>
                    </div>

                    <div>
                        <span className='fw600'>Soyadı: </span>
                        <span>{initialData.surname || "-"}</span>
                    </div>

                    <div>
                        <span className='fw600'>E-posta: </span>
                        <span>{initialData.email || "-"}</span>
                    </div>

                    <div>
                        <span className='fw600'>Cinsiyet: </span>
                        <span>{initialData.gender || "-"}</span>
                    </div>

                    <div>
                        <span className='fw600'>Doğum Tarihi: </span>
                        <span>{userBirthDate || "-"}</span>
                    </div>

                    <div>
                        <span className='fw600'>Telefon Numarası: </span>
                        <span>{initialData.phoneNumber || "-"}</span>
                    </div>

                    <div>
                        <span className='fw600'>Iban Numarası: </span>
                        <span>{initialData?.ibanNumber || "-"}</span>
                    </div>

                    <div>
                        <span className='fw600'>T.C Kimlik Numarası: </span>
                        <span>{initialData.identityNumber || "-"}</span>
                    </div>

                    <div>
                        <span className='fw600'>Posta Kodu: </span>
                        <span>{initialData?.postcode || "-"}</span>
                    </div>

                    <div>
                        <span className='fw600'>Şehir: </span>
                        <span>{initialData.city || "-"}</span>
                    </div>

                    <div>
                        <span className='fw600'>İlçe: </span>
                        <span>{initialData?.district || "-"}</span>
                    </div>

                    <div>
                        <span className='fw600'>Açık Adres: </span>
                        <span>{initialData.openAddress || "-"}</span>
                    </div>

                    {initialData.role === "SELLER" && (
                        <>
                            <div>
                                <span className='fw600'>Şirket Adı: </span>
                                <span>{initialData.companyName || "-"}</span>
                            </div>

                            <div>
                                <span className='fw600'>V.K.N: </span>
                                <span>{initialData?.vkn || "-"}</span>
                            </div>

                            <div>
                                <span className='fw600'>V.D: </span>
                                <span>{initialData.vd || "-"}</span>
                            </div>
                        </>
                    )}

                    {getFeeFields().map((item) => {
                        return (
                            <DialogField
                                item={item}
                                control={control}
                                register={register}
                                watch={watch}
                                disabled={false}
                            />
                        )
                    })}

                    <DialogActions>
                        <div style={{ flex: 1 }} />
                        <Button onClick={handleSubmit(onValid, onInvalid)}             style={{backgroundColor: "#000000", color: "white"}}
>
                            Güncelle
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            <Portal>
                <Snackbar
                    autoHideDuration={3000}
                    message={"message"}
                />
            </Portal>
        </>
    )
}

export default FeeDialog
