const SaleCard = ({ sale }) => {
  return (
    <tr className="border-b hover:bg-gray-100">
      <th scope="row" class="px-6 py-3 font-medium textPurple">
        {sale._id.toUpperCase()}
      </th>
      <td class="px-6 py-3 text-black">
        <div>{sale.productGroup.title}</div>
      </td>
      <td class="px-6 py-3 text-black">
        <div>{`₺ ${sale.price}`}</div>
      </td>
      <td class="px-6 py-3 text-black">
        <div>₺ {(sale.serviceFee + sale.commission) %1===0?(sale.serviceFee + sale.commission):(sale.serviceFee + sale.commission).toFixed(2)}</div>
      </td>
      <td class="px-6 py-3 text-black">
        <div>₺ {sale.finalAmount}</div>
      </td>
    </tr>
  )
}

export default SaleCard