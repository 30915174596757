import React, { useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  SwipeableDrawer,
  useTheme,
  TableContainer,
  Paper,
  IconButton,
  TablePagination,
  TableFooter,
  Button,
  Box,
} from "@material-ui/core"
import { Info as InfoIcon, Add as AddIcon, FileCopyOutlined } from "@material-ui/icons"
import { useQuery } from "react-query"
import { useStyles } from "../styles"
import * as Queries from "../../utils/queries"
import { Typography } from "@mui/material"
import ProductDialog from "./ProductDialog"
import CopyVariantDialog from "./CopyVariantDialog"

const ProductsDrawer = ({
  showDrawer,
  setShowDrawer,
  selectedProductGroup,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [openProductDialog, setOpenProductDialog] = useState(false)
  const [copyVariantDialog, setCopyVariantDialog] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(false)

  const [searchQuery, setSearchQuery] = useState("")
  const [page, setPage] = useState(1)

  const {
    data: { page: products, totalProducts },
  } = useQuery(
    ["LIST_PRODUCTS", searchQuery, page, selectedProductGroup._id],
    () => {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0

      return Queries.listProducts(
        selectedProductGroup._id,
        new URLSearchParams({
          filterName: searchQuery,
          page,
        }).toString()
      )
    },
    {
      placeholderData: {
        page: [],
        totalProducts: 0,
      },
      keepPreviousData: false,
    }
  )

  const { data: attributeInformations } = useQuery(
    ["GET_GROUP_ATTRIBUTE_INFORMATIONS", selectedProductGroup._id],
    () => Queries.getGroupAttributeInformations(selectedProductGroup._id),
    {
      placeholderData: [],
      keepPreviousData: false,
    }
  )

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={showDrawer}
      onClose={() => setShowDrawer(false)}
      onOpen={() => setShowDrawer(true)}
      PaperProps={{
        style: {
          minWidth: "75%",
        },
      }}
    >
      <Box
        style={{
          display: "flex",
          padding: "1rem",
          alignItems: "center",
          borderBottom: "1px solid #E0E0E0",
        }}
      >
        <Typography variant="h6">
          {selectedProductGroup.title} - {selectedProductGroup.brand?.title}
        </Typography>
        <div style={{ flex: 1 }} />
        <Button
          variant="contained"
                      style={{backgroundColor: "#000000", color: "white"}}

          style={{ marginRight: 15 }}
          startIcon={<FileCopyOutlined />}
          onClick={() => {
            setCopyVariantDialog(true)
          }}
        >
          Varyantları Kopyala
        </Button>

        <Button
          variant="contained"
                      style={{backgroundColor: "#000000", color: "white"}}

          startIcon={<AddIcon />}
          onClick={() => {
            setSelectedProduct(undefined)
            setOpenProductDialog(true)
          }}
        >
          Varyant Oluştur
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {attributeInformations.map((attribute) => (
                <TableCell>{attribute.title}</TableCell>
              ))}
              <TableCell>Sku</TableCell>
              <TableCell>Çıkış Tarihi</TableCell>
              <TableCell>Colorway</TableCell>
              <TableCell>Style</TableCell>
              <TableCell align="right">Detaylar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => {
              const terms = product.terms.sort((a, b) => {
                return selectedProductGroup.attributes.indexOf(a.attribute) >
                  selectedProductGroup.attributes.indexOf(b.attribute)
                  ? 1
                  : -1
              })

              return (
                <TableRow key={product._id}>
                  {terms.map((item) => (
                    <TableCell>{item.value}</TableCell>
                  ))}
                  <TableCell>{product.sku}</TableCell>
                  <TableCell>{product.releaseDate}</TableCell>
                  <TableCell>{product.colorway}</TableCell>
                  <TableCell>{product.style}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => {
                        setSelectedProduct(product)
                        setOpenProductDialog(true)
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>

          <TableFooter>
            <TablePagination
              count={totalProducts}
              page={page - 1}
              rowsPerPageOptions={[{ value: 10 }]}
              rowsPerPage={10}
              onPageChange={(_, newPage) => {
                setPage(newPage + 1)
              }}
            />
          </TableFooter>
        </Table>
      </TableContainer>

      {openProductDialog && (
        <ProductDialog
          openProductDialog={openProductDialog}
          setOpenProductDialog={setOpenProductDialog}
          initialData={selectedProduct}
          selectedProductGroup={selectedProductGroup}
          attributeInformations={attributeInformations}
        />
      )}

      {copyVariantDialog && (
        <CopyVariantDialog
          openVariantDialog={copyVariantDialog}
          setOpenVariantDialog={setCopyVariantDialog}
          selectedProductGroup={selectedProductGroup}
        />
      )}
    </SwipeableDrawer>
  )
}

export default ProductsDrawer
