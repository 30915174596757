import React, { useEffect, useState } from 'react'
import { CiDeliveryTruck } from 'react-icons/ci'
import { FaCheck, FaPlus, FaRegClock } from 'react-icons/fa';
import { FaMoneyBill, FaMoneyBill1, FaX } from 'react-icons/fa6';

const TimelineCard = ({ data }) => {

    const [date, setDate] = useState("")

    const editDate = () => {
        const postDate = new Date(data.date);
        const currentDate = new Date();

        const timeDiff = Math.abs(currentDate - postDate);

        const yearsDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));
        const monthsDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30));
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutesDiff = Math.floor(timeDiff / (1000 * 60));
        const secondsDiff = Math.floor(timeDiff / 1000);

        if (yearsDiff > 0) {
            setDate(`${yearsDiff} yıl önce`);
        } else if (monthsDiff > 0) {
            setDate(`${monthsDiff} ay önce`);
        } else if (daysDiff > 0) {
            setDate(`${daysDiff} gün önce`);
        } else if (hoursDiff > 0) {
            setDate(`${hoursDiff} saat önce`);
        } else if (minutesDiff > 0) {
            setDate(`${minutesDiff} dakika önce`);
        } else {
            setDate(`${secondsDiff} saniye önce`);
        }
    };

    useEffect(() => {
        editDate();
    }, [data]);

    return (
        <div className="flex gap-5">
            <div className="w-[70px] h-[70px] rounded-full relative shadow-md  bg-gray-600 flex items-center justify-center">
                {
                    data.type === "Created" ?
                        <FaPlus size={30} color="#fff" />
                        : data.type === "PENDING" ?
                            <FaRegClock size={30} color="#fff" />
                            : data.type === "DECLINED" ?
                                <FaX size={30} color="#fff" />
                                : data.type === "APPROVED" ?
                                    <FaCheck size={30} color="#fff" />
                                    : data.type === "PAYMENTPENDING" ?
                                        <FaRegClock size={30} color="#fff" />
                                        : data.type === "PAYMENTMADE" ?
                                            <FaMoneyBill1 size={30} color="#fff" />
                                            : <></>
                }
                {data?.id !== 1 ?
                    <div className="absolute top-100 transform -translate-x-1/2 -z-[5px] left-1/2 h-8 w-[3px] bg-gray-600"></div>
                    : <></>}
            </div>

            <div className="mt-2">
                <h6 className="text-[18px]">{date}</h6>
                <p className="text-gray-600">{
                    data.label
                }</p>
            </div>
        </div>
    )
}

export default TimelineCard