import React, { useEffect } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "./utils/atoms";
import Login from "./screens/Login/Login";
import Users from "./screens/Users/Users";
import Articles from "./screens/Articles/Articles";
import Orders from "./screens/Orders/Orders";
import Attributes from "./screens/Attributes/Attributes";
import Sliders from "./screens/Sliders/Sliders";
import Categories from "./screens/Categories/Categories";
import ProductGroups from "./screens/Products/ProductGroups";
import Brands from "./screens/Brands/Brands";
import Sellers from "./screens/Sellers/Sellers";
import Offers from "./screens/Offers/Offers";
import Cargo from "./screens/Cargo/Cargo";
import SaleSettings from "./screens/SaleSettings/SaleSettings";
import Report from "./screens/Report/Report";
import Models from "./screens/Models/Models";
import HomeSections from "./screens/HomeSections/HomeSections";
import AddProduct from "./screens/AddProduct";
import SutokProducts from "./components/SutokProducts";
import OrderDetail from "./screens/Orders/OrderDetail";
import SellerDetail from "./screens/SellerDetail";
import "bootstrap/dist/css/bootstrap.min.css";
import Post from "./components/Post";
import "react-quill/dist/quill.snow.css";
import CategoryDetail from "./screens/Categories/CategoriDetail";
import BrandsDetail from "./screens/Brands/BrandDetail";
import UsersDetail from "./screens/UserDetail";
import ProductDetail from "./screens/Products/ProductDetail";
import ModelDetail from "./screens/Models/ModelDetail";
import ProductAttributes from "./screens/Products/ProductAttributes";
import { initGA, logPageView, sendEvent } from "./utils/googleanalytics";
import SellerPayments from "./screens/SellerPayments";
import SellerPaymentDetail from "./screens/SellerPayments/SellerPaymentDetail";
import DiscountCoupons from "./screens/DiscountCoupons";
import ProductsPendingApproval from "./screens/ProductsPendingApproval";
import SellerRequests from "./screens/SellerRequests";
const App = () => {
  const [user, setUser] = useAtom(userAtom);

  const location = useLocation();

  // Google Analytics'i başlat
  initGA();

  /*useEffect(() => {
    // Her sayfa değiştiğinde Google Analytics'e sayfa görüntülemesi gönder
    logPageView(location?.pathname);
  }, [])*/

  useEffect(() => {
    sendEvent("action", "category", "label", "value");
  }, [])

  React.useEffect(() => {
    if (user && !localStorage.getItem("accessToken")) {
      localStorage.clear();
      setUser(null);
    }
  }, [user]);

  const routeList = user
    ? [
      {
        path: "/home-sections",
        element: <HomeSections />,
      },
      {
        path: "/product/detail",
        element: <ProductDetail />,
      },
      {
        path: "/userDetail",
        element: <UsersDetail />,
      },
      {
        path: "/seller-payments",
        element: <SellerPayments />,
      },
      {
        path: "/paymentDetail",
        element: <SellerPaymentDetail />,
      },
      {
        path: "/brandsDetail",
        element: <BrandsDetail />,
      },
      {
        path: "/post",
        element: <Post />,
      },
      {
        path: "/modelDetails",
        element: <ModelDetail />,
      },

      {
        path: "/reports",
        element: <Report />,
      },
      {
        path: "/orders",
        element: <Orders />,
      },
      {
        path: "/categoriesdetail",
        element: <CategoryDetail />,
      },
      {
        path: "/orderDetail",
        element: <OrderDetail />,
      },
      {
        path: "/products",
        element: <ProductGroups />,
      },
      {
        path: "sutok-products",
        element: <SutokProducts />,
      },
      {
        path: "/categories",
        element: <Categories />,
      },
      {
        path: "/models",
        element: <Models />,
      },
      {
        path: "/brands",
        element: <Brands />,
      },
      {
        path: "/sliders",
        element: <Sliders />,
      },
      {
        path: "/attributes",
        element: <Attributes />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/sellers",
        element: <Sellers />,
      },
      {
        path: "/satici-detay",
        element: <SellerDetail />,
      },
      {
        path: "/product-attributes",
        element: <ProductAttributes />,
      },
      {
        path: "/offers",
        element: <Offers />,
      },
      {
        path: "/cargo",
        element: <Cargo />,
      },
      {
        path: "/sale-settings",
        element: <SaleSettings />,
      },
      {
        path: "/articles",
        element: <Articles />,
      },
      {
        path: "/addProduct",
        element: <AddProduct />,
      },
      {
        path: "/discount-coupons",
        element: <DiscountCoupons />,
      },
      {
        path: "/productsPendingApproval",
        element: <ProductsPendingApproval />,
      },
      {
        path: "/sellerRequests",
        element: <SellerRequests />,
      },
      {
        path: "*",
        element: <Navigate to="/orders" replace />,
      },
    ]
    : [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "*",
        element: <Navigate to="/login" replace />,
      },
    ];

  return useRoutes(routeList);
};

export default App;
