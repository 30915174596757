import React, { useEffect, useState } from "react";
import { useStyles } from "../styles";
import { Toolbar } from "@material-ui/core";
import CustomDrawer from "../../components/CustomDrawer";
import * as Queries from "../../utils/queries";
import CustomLoading from "../../components/CustomLoading";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { useQuery } from "react-query";
import { useAtom } from "jotai";
import { userAtom } from "../../utils/atoms";
import toast, { Toaster } from "react-hot-toast";
import Orders from "./Orders";
import PersonalInformations from "./PersonalInformations";
import Addresses from "../../components/Addresses";
import UserTimeline from "../../components/UserTimeline";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
 
const UserDetail = () => {
  const classes = useStyles();
  const [user] = useAtom(userAtom);
  const [searchParams] = useSearchParams();
  const [userData, setUserData] = useState(null);
  const [pendingPayment, setPendingPayment] = useState(0);
  const [ordersFilterQuery, setOrdersFilterQuery] = useState({
    page: 1,
    limit: 5,
  });

  const formatPendingPayment = async (adverts) => {
    let price = 0;
    for (let i = 0; i < adverts.length; i++) {
      price += adverts[i].price;
    }
    setPendingPayment(price);
  };

  const getUserDetail = async (userId) => {
    const response = await Queries.getUserDetail(userId);
    setUserData(response?.user);
    formatPendingPayment(response?.user?.adverts);
  };
  const navigation = useNavigate();
  const { data: ordersData } = useQuery(
    [
      "getOrdersData",
      {
        ...ordersFilterQuery,
        userId: searchParams.get("id"),
      },
    ],
    () => {
      return Queries.getUserOrders(
        new URLSearchParams({
          ...ordersFilterQuery,
          userId: searchParams.get("id"),
        }).toString()
      );
    },
    {
      placeholderData: {
        orders: [],
        totalCount: 0,
        totalPage: 0,
      },
      keepPreviousData: false,
    }
  );

  useEffect(() => {
    getUserDetail(searchParams.get("id"));
  }, [searchParams.get("id")]);

  if (userData) {
    return (
      <div className="flex flex-1 bgf7fafc">
        <Toaster />
        <CustomDrawer />
        <main className={classes.content}>
          <Toolbar />
          <div className="flex flex-1 flex-col gap-3">
            <NavLink to="/users" className="text-gray-600  border p-2 w-[35px] bg-white rounded-md">
              <span>
                <IoArrowBack />
              </span>
            </NavLink>
            <PersonalInformations userData={userData} />

            <div className="p-3 flex flex-row  bg-white rounded-md shadow-sm border">
              <div className="flex flex-1 flex-col space-y-2">
                <div className="flex flex-row space-x-3 items-center">
                  <span className="text-sm text-gray-500">
                    Total Satış Sayısı
                  </span>
                </div>
                <div className="font-semibold text-lg">
                  {userData.advertCount}
                </div>
              </div>

              <div className="flex flex-1 flex-col space-y-2 border-l-2 border-r-2 border-slate-200 px-3">
                <div className="flex flex-row space-x-3 items-center">
                  <span className="text-sm text-gray-500">
                    Ort. Sepet Tutarı
                  </span>
                  <InfoCircledIcon />
                </div>
                <div className="font-semibold text-lg">
                  ₺{" "}
                  {(userData.averageBasketInformation.averageBasketAmount %
                    1 ===
                  0
                    ? userData.averageBasketInformation.averageBasketAmount
                    : userData.averageBasketInformation.averageBasketAmount.toFixed(
                        2
                      )) || 0}
                </div>
              </div>

              <div className="flex flex-1 flex-col space-y-2 px-3 border-l border-slate-200">
                <div className="flex flex-row space-x-3 items-center">
                  <span className="text-sm text-gray-500">
                    Ort. Sepet Büyüklüğü
                  </span>
                  <InfoCircledIcon />
                </div>
                <div className="font-semibold text-lg">
                  {(userData.averageBasketInformation.averageBasketSize % 1 ===
                  0
                    ? userData.averageBasketInformation.averageBasketSize
                    : userData.averageBasketInformation.averageBasketSize.toFixed(
                        2
                      )) || 0}
                </div>
              </div>
              {userData?.role === "SELLER" ? (
                <div className="flex items-center">
                  <button
                    className="buttonPurple px-3 py-2 rounded"
                    onClick={() =>
                      navigation(`/satici-detay?id=${userData?._id}`)
                    }
                  >
                    Satıcı
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="grid grid-cols-[25%,75%] gap-3 mr-4">
              <div className=" bg-white rounded-md border shadow-sm p-3">
                <Addresses userData={userData} />
              </div>
              <div className="flex flex-col gap-3">
                <div className=" bg-white rounded-md border shadow-sm p-3">
                  <Orders
                    ordersData={ordersData}
                    ordersFilterQuery={ordersFilterQuery}
                    setOrdersFilterQuery={setOrdersFilterQuery}
                  />
                </div>
                <UserTimeline
                  userData={userData}
                  getUserDetail={getUserDetail}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <CustomDrawer />
        <main className={classes.content}>
          <Toolbar />
          <div className="flex flex-1 h-full justify-center items-center">
            <CustomLoading
              className={""}
              color={"black"}
              size={24}
              type={"clip"}
            />
          </div>
        </main>
      </div>
    );
  }
};

export default UserDetail;
