import React, { useState } from "react";
import {
  Toolbar,
  Typography,
  TextField,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import {
  Add as AddIcon,
  ChevronRightRounded,
  Info as InfoIcon,
} from "@material-ui/icons";
import { useQuery } from "react-query";
import AttributeDialog from "./AttributeDialog";
import { useStyles } from "../styles";
import * as Queries from "../../utils/queries";
import CustomDrawer from "../../components/CustomDrawer";
import TermsDrawer from "./TermsDrawer";
import { ChromePicker } from "react-color";
import { BiPencil } from "react-icons/bi";
import { GridCloseIcon } from "@mui/x-data-grid";
import { IoMdSearch } from "react-icons/io";
import AttributeCard from "./AttributeCard";
import EditAttribute from "./EditAttribute";
import { Pagination } from "@mui/material";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";

const Attributes = () => {
  const classes = useStyles();
  const [openAttributeDialog, setOpenAttributeDialog] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    limit: 10,
    filterName: ""
  });

  const {
    data,
    refetch: attributesRefetch
  } = useQuery(
    ["LIST_ATTRIBUTES", searchQuery],
    () => {
      return Queries.getAttributes(
        new URLSearchParams({
          ...searchQuery
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalCount: 0,
        totalPage: 0,
      },
      keepPreviousData: false,
      onError: (err) => {
        console.log("err", err);
      },
    }
  );

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className={classes.root}>
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />

        <div className="flex flex-1 flex-row space-x-4 items-center px-3 bg-white p-3 shadow-sm rounded-md border">
          <div className="flex flex-1 text-xl font-semibold">Ürün Özellikleri</div>

          <Button
            variant="contained"
            style={{ backgroundColor: "#000000", color: "white" }}
            startIcon={<AddIcon />}
            onClick={() => {
              setSelectedAttribute(undefined);
              setOpenAttributeDialog(true);
            }}
          >
            Özellik Oluştur
          </Button>
        </div>

        <div className="bg-white flex justify-between p-3 rounded-md border shadow-sm">
          <div className="w-72 flex flex-row space-x-1 border border-gray-200 rounded items-center">
            <label
              htmlFor="searchInput"
              className="px-2 py-3 flex items-center justify-center"
            >
              <MagnifyingGlassIcon />
            </label>
            <input
              id="searchInput"
              className="h-full flex flex-1"
              placeholder="Ara"
              value={searchQuery.filterName}
              onChange={e => setSearchQuery({ ...searchQuery, filterName: e.target.value })}
            />
          </div>
        </div>

        <div className="p-3 bg-white border rounded-md shadow-sm">
          <TableContainer className="rounded-md">
            <Table>
              <TableHead className="bg-[#EEF2F6]">
                <TableRow>
                  <TableCell>Tür</TableCell>
                  <TableCell>Tanımlanmış Değer</TableCell>
                  <TableCell className="w-[100px]">İşlem</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.page?.map(item => {
                  return (
                    <AttributeCard
                      key={item?._id}
                      attributeData={item}
                      attributesRefetch={attributesRefetch}
                      selectedAttribute={selectedAttribute}
                      setSelectedAttribute={setSelectedAttribute} />
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {selectedAttribute ?
            <EditAttribute
              selectedAttribute={selectedAttribute}
              setSelectedAttribute={setSelectedAttribute}
              attributesRefetch={attributesRefetch}
            /> : <></>}

          <div className="flex flex-1 items-center justify-center mt-3">
            <Pagination
              count={data?.totalPage}
              page={searchQuery.page}
              onChange={(_, value) => setSearchQuery({ ...searchQuery, page: value })}
              shape="rounded"
              variant="outlined"
              color="primary"
            />
          </div>
        </div>
      </main>

      {openAttributeDialog && (
        <AttributeDialog
          openAttributeDialog={openAttributeDialog}
          setOpenAttributeDialog={setOpenAttributeDialog}
          attributesRefetch={attributesRefetch}
        />
      )}

      {showDrawer && selectedAttribute && (
        <TermsDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          selectedAttribute={selectedAttribute}
        />
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="İşlem başarılı."
      />
    </div>
  );
};

export default Attributes;
