import { Pagination } from '@mui/material'
import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import SellerPaymentCard from './SellerPaymentCard'

const SellerPayments = ({ sellerPaymentsData, sellerPaymentsFilterQuery, setSellerPaymentsFilterQuery }) => {

    const navigate = useNavigate();

    return (
        <div className='flex flex-1 flex-col spacec-y-3'>

            <div className='px-3 flex flex-row'>
                <div className='flex flex-1'>
                    <span className='pb-1 border-b borderPurple textPurple'>
                        {" "}
                    </span>
                </div>

                {(sellerPaymentsData?.totalCount !== 0) ?
                    <div className='flex flex-1 justify-end text-gray-600 text-sm'>
                        {`${sellerPaymentsFilterQuery.page * sellerPaymentsFilterQuery.limit - (sellerPaymentsFilterQuery.limit - 1)} - ${(sellerPaymentsData?.totalCount > sellerPaymentsFilterQuery.limit ? (
                            sellerPaymentsFilterQuery.page !== sellerPaymentsData?.totalPage ? sellerPaymentsFilterQuery.page * sellerPaymentsFilterQuery.limit
                                : sellerPaymentsData?.totalCount
                        ) : sellerPaymentsData?.totalCount)
                            } / ${sellerPaymentsData?.totalCount} adet`}
                    </div> : <></>}
            </div>

            <TableContainer>
                <Table>
                    <TableHead className="bg-[#F3F4F6]">
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Ödeme Tutarı</TableCell>
                            <TableCell>Ödeme Durumu</TableCell>
                            <TableCell>Ödeme Tarihi</TableCell>
                            <TableCell>Detay</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sellerPaymentsData?.page?.map(item => {
                            return (
                                <SellerPaymentCard
                                    key={item?._id}
                                    sellerPayment={item}
                                />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {(sellerPaymentsData?.totalCount !== 0 && sellerPaymentsData?.totalPage > 1) ?
                <div className='flex flex-1 px-3 justify-center mt-3'>
                    <Pagination
                        count={sellerPaymentsData?.totalPage}
                        page={sellerPaymentsFilterQuery.page}
                        onChange={(_, value) => setSellerPaymentsFilterQuery({ ...sellerPaymentsFilterQuery, page: value })}
                        variant='outlined'
                        color='primary'
                        shape='rounded'
                    />
                </div> : <></>}
        </div>
    )
}

export default SellerPayments