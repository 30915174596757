import React, { useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  SwipeableDrawer,
  useTheme,
  TableContainer,
  Paper,
  IconButton,
  TablePagination,
  TableFooter,
  Button,
  Box,
} from "@material-ui/core"
import { Info as InfoIcon, Add as AddIcon } from "@material-ui/icons"
import { useQuery } from "react-query"
import * as Queries from "../../utils/queries"
import { Typography } from "@mui/material"
import TermDialog from "./TermDialog"

const TermsDrawer = ({ showDrawer, setShowDrawer, selectedAttribute }) => {
  const [openTermDialog, setOpenTermDialog] = useState(false)
  const [selectedTerm, setSelectedTerm] = useState(false)

  const [searchQuery, setSearchQuery] = useState("")
  const [page, setPage] = useState(1)

  const {
    data: { page: terms, totalTerms },
  } = useQuery(
    ["LIST_TERMS", searchQuery, page, selectedAttribute._id],
    () => {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0

      return Queries.listTerms(
        selectedAttribute._id,
        new URLSearchParams({
          filterName: searchQuery,
          page,
        }).toString()
      )
    },
    {
      placeholderData: {
        page: [],
        totalTerms: 0,
      },
      keepPreviousData: false,
    }
  )

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={showDrawer}
      onClose={() => setShowDrawer(false)}
      onOpen={() => setShowDrawer(true)}
      PaperProps={{
        style: {
          minWidth: "55%",
        },
      }}
    >
      <Box
        style={{
          display: "flex",
          padding: "1rem",
          alignItems: "center",
          borderBottom: "1px solid #E0E0E0",
        }}
      >
        <Typography variant="h6">{selectedAttribute.title}</Typography>
        <div style={{ flex: 1 }} />
        <Button
          variant="contained"
                      style={{backgroundColor: "#000000", color: "white"}}

          startIcon={<AddIcon />}
          onClick={() => {
            setSelectedTerm(undefined)
            setOpenTermDialog(true)
          }}
        >
          Değer Oluştur
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Değer</TableCell>
              <TableCell align="right">Detaylar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {terms.map((term) => {
              return (
                <TableRow key={term._id}>
                  <TableCell component="th" scope="row">
                    {term.value}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => {
                        setSelectedTerm(term)
                        setOpenTermDialog(true)
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>

          <TableFooter>
            <TablePagination
              count={totalTerms}
              page={page - 1}
              rowsPerPageOptions={[{ value: 10 }]}
              rowsPerPage={10}
              onPageChange={(_, newPage) => {
                setPage(newPage + 1)
              }}
            />
          </TableFooter>
        </Table>
      </TableContainer>

      {openTermDialog && selectedAttribute && (
        <TermDialog
          openTermDialog={openTermDialog}
          setOpenTermDialog={setOpenTermDialog}
          initialData={selectedTerm}
          selectedAttribute={selectedAttribute}
        />
      )}
    </SwipeableDrawer>
  )
}

export default TermsDrawer
