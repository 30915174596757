import React, { useEffect, useState } from "react";
import {
  Toolbar,
  Typography,
  TextField,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  MenuItem,
} from "@material-ui/core";
import { useStyles } from "../styles";
import CustomDrawer from "../../components/CustomDrawer";
import { BarPlot } from "@mui/x-charts/BarChart";
import { LinePlot, LineChart } from "@mui/x-charts/LineChart";
import { ChartContainer } from "@mui/x-charts/ChartContainer";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { useQuery } from "react-query";
import * as Queries from "../../utils/queries";
import Form from "react-bootstrap/Form";
import { FaArrowUp } from "react-icons/fa6";
import { PieChart } from "@mui/x-charts/PieChart";
import SutokProductCard from "./SutokProductCard";
import { Pagination } from "@mui/material";

const Report = () => {
  const classes = useStyles();
  const [whichButton, setWhichButton] = useState("daily");
  const [searchQuery, setSearchQuery] = useState({
    productState: "soldOut",
    page: 1,
    limit: 10,
  });
  const {
    data: {
      totalOrdersCount,
      totalSoldAdverts,
      totalEarnings,
      totalProfit,
      totalServiceFee,
      totalCommission,
      weeklyTotal,
      weekly,
      orders,
    },
  } = useQuery(["STATISTICS", {
    statisticType: whichButton,
  }],
    () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      return Queries.getStatistics(
        new URLSearchParams({
          statisticType: whichButton,
        }).toString()
      );
    }, {
    placeholderData: {
      totalOrdersCount: 0,
      totalSoldAdverts: 0,
      totalEarnings: 0,
      totalProfit: 0,
      totalServiceFee: 0,
      totalCommission: 0,
      weeklyTotal: 0,
      weekly: {},
      orders: {}
    },
  });

  const { data: sutokProductsData, isLoading: sutokProductsIsLoading } =
    useQuery(
      ["getSutokProducts", searchQuery],
      () => {
        return Queries.getSutokProducts(
          new URLSearchParams(searchQuery).toString()
        );
      },
      {
        placeholderData: {
          pageData: [],
        },
        keepPreviousData: false,
      }
    );

  const { data: sutokProductsCountsData } = useQuery(
    ["getSutokProductsCounts"],
    () => {
      return Queries.getSutokProductsCounts();
    },
    {
      placeholderData: {
        totalCount: 0,
        noStockCount: 0,
        stockTotalCount: 0,
        lowStockCount: 0,
        soldOutStockCount: 0,
      },
      keepPreviousData: false,
    }
  );

  const { data: analysisData } = useQuery(
    ["listAnalysis", { dateType: whichButton }],
    () => {
      return Queries.listAnalysis({
        dateType: whichButton
      });
    },
    {
      placeholderData: {
        numberOfInstantVisitors: 0,
        totalVisitor: 0,
        totalBasket: 0,
        totalPaymentSuccessful: 0,
        totalShoppingSuccessful: 0,
        averageOrderAmount: 0,
        averageBasketQuantity: 0,
        averageAdvertAmount: 0
      },
      keepPreviousData: false,
    }
  );

  const series = [
    {
      type: "bar",
      stack: "",
      yAxisKey: "eco",
      data: Object.values(weekly)
        .sort((a, b) => +new Date(a.day) - +new Date(b.day))
        .map((item) => item.totalEarnings),
    },
    {
      type: "line",
      yAxisKey: "pib",
      color: "red",
      data: Object.values(weekly).map(() => weeklyTotal),
    },
    {
      type: "line",
      yAxisKey: "ort",
      color: "blue",
      data: Object.values(weekly).map(
        () => weeklyTotal / Object.keys(weekly).length
      ),
    },
  ];

  const styles = {
    card: {
      flex: 1,
      background: "rgb(209, 233, 252)",
      paddingTop: 20,
      paddingBottom: 20,
      borderRadius: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    cardVal: { marginTop: 20 },
  };

  /*useEffect(() => {
    console.log(
      totalSoldAdverts,
      totalOrdersCount,
      totalEarnings,
      totalProfit,
      totalServiceFee,
      totalCommission,
      weeklyTotal,
      weekly,
      orders
    );
  }, [
    totalEarnings,
    totalProfit,
    totalServiceFee,
    totalCommission,
    weeklyTotal,
    weekly,
    totalSoldAdverts,
    totalOrdersCount,
    orders
  ]);*/
  return (
    <div className={classes.root}>
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />

        <div className="p-3 flex items-center gap-3 bg-white border shadow-sm rounded-md">
          <div>
            <Form.Select aria-label="Default select example">
              <option>Hepsi</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </div>
          <div className="flex">
            <button
              className={` p-2 w-[100px] text-center ${whichButton === "daily"
                ? "text-purple-800 bg-purple-100 sss "
                : "border"
                }`}
              onClick={() => setWhichButton("daily")}
            >
              Bugün
            </button>
            <button
              className={` p-2 w-[100px] text-center ${whichButton === "yesterday"
                ? "text-purple-800 bg-purple-100 sss "
                : "border"
                }`}
              onClick={() => setWhichButton("yesterday")}
            >
              Dün
            </button>
            <button
              className={` p-2 w-[100px] text-center ${whichButton === "weekly"
                ? "text-purple-800 bg-purple-100 sss "
                : "border"
                }`}
              onClick={() => setWhichButton("weekly")}
            >
              Bu Hafta
            </button>
            <button
              className={` p-2 w-[100px] text-center ${whichButton === "monthly"
                ? "text-purple-800 bg-purple-100 sss "
                : "border"
                }`}
              onClick={() => setWhichButton("monthly")}
            >
              Bu Ay
            </button>
            <button
              className={` p-2 w-[100px] text-center ${whichButton === "yearly"
                ? "text-purple-800 bg-purple-100 sss "
                : "border"
                }`}
              onClick={() => setWhichButton("yearly")}
            >
              Bu Yıl
            </button>
            <input
              type="date"
              value={whichButton}
              onChange={e => setWhichButton(e.target.value)}
              className="p-2 w-[150px] border"
              max={new Date().toISOString().split("T")[0]} />
          </div>
        </div>
        <div className="flex flex-col p-3 bg-white rounded-md shadow-sm border ">
          <div className="flex items-center gap-3">
            <h5 className="py-3 mt-1">Genel Özet</h5>
            <div className="p-1 border rounded-md shadow-sm bg-gray-200 text-gray-600">
              {
                whichButton === "daily" && "Bugüne"
                || whichButton === "weekly" && "Bu Haftaya"
                || whichButton === "monthly" && "Bu Aya"
                || whichButton === "yearly" && "Bu Yıla"
                || whichButton === "yesterday" && "Düne"} Göre
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-1 flex-col space-y-5 p-4 border-l  border-t">
              <div className="text-sm text-gray-500">Toplam Satışlar</div>
              <div className="text-black text-lg">
                ₺{" "}
                {totalEarnings % 1 === 0
                  ? totalEarnings
                  : totalEarnings.toFixed(2)}
              </div>
            </div>
            <div className="flex flex-1 flex-col space-y-5 p-4 border-l  border-t">
              <div className="text-sm text-gray-500">Net Satışlar</div>
              <div className="text-black text-lg">
                ₺ {totalProfit % 1 === 0 ? totalProfit : totalProfit.toFixed(2)}
              </div>
            </div>
            <div className="flex flex-1 flex-col space-y-5 p-4 border-l  border-t">
              <div className="text-sm text-gray-500">Siparişler</div>
              <div className="text-black text-lg">{totalOrdersCount}</div>
            </div>
            <div className="flex flex-1 flex-col space-y-5 p-4 border-l  border-t border-r">
              <div className="text-sm text-gray-500">Satılan Ürünler</div>
              <div className="text-black text-lg">{totalSoldAdverts}</div>
            </div>
          </div>

          <div className="flex">
            <div className="flex flex-1 flex-col space-y-5 p-4 border-l  border-t">
              <div className="text-sm text-gray-500">Bekleyen Siparişler</div>
              <div className="text-black text-lg">
                {orders?.totalOrdersForPending}
              </div>
            </div>
            <div className="flex flex-1 flex-col space-y-5 p-4 border-l  border-t">
              <div className="text-sm text-gray-500">Kargoda Olan Siparişler</div>
              <div className="text-black text-lg">
                {orders?.totalOrdersForShipped}
              </div>
            </div>
            <div className="flex flex-1 flex-col space-y-5 p-4 border-l  border-t">
              <div className="text-sm text-gray-500">Teslim Edilen Siparişler</div>
              <div className="text-black text-lg">{orders?.totalOrdersForDelivered}</div>
            </div>
            <div className="flex flex-1 flex-col space-y-5 p-4 border-l  border-t border-r">
              <div className="text-sm text-gray-500">Reddedilen Siparişler</div>
              <div className="text-black text-lg">{orders?.totalOrdersForDeclined}</div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-[69%,30%] gap-3">
          <div className="flex flex-col gap-3">
            <div className="p-3 bg-white border rounded-md shadow-sm ">
              <h5>Satış Grafiği</h5>
              {(weeklyTotal > 0 && (
                <LineChart
                  xAxis={[
                    {
                      scaleType: "point",
                      data: Object.entries(weekly).map((item) => item[1].day),
                    },
                  ]}
                  series={[
                    {
                      label: "Toplam Satış",
                      data: Object.entries(weekly).map(
                        (item) => item[1].totalEarnings
                      ),
                    },
                  ]}
                  height={400}
                />
              )) || (
                  <div className="flex flex-1 p-3 rounded bg-blue-100 text-blue-700">
                    Henüz Satış Yapmadığınızdan Dolayı Satış Grafiğini
                    Göremezsiniz
                  </div>
                )}
            </div>
            <div className="bg-white p-3 shadow-sm border rounded-md">
              <div className="flex items-center justify-between border-b border-gray-200 pb-4">
                <div className="flex gap-2">
                  <h5 className="mt-1">En Çok Satanlar</h5>
                  <div className="p-1 border rounded-md shadow-sm bg-gray-200 text-gray-600">
                    {
                      whichButton === "daily" && "Bugüne"
                      || whichButton === "weekly" && "Bu Haftaya"
                      || whichButton === "monthly" && "Bu Aya"
                      || whichButton === "yearly" && "Bu Yıla"
                      || whichButton === "yesterday" && "Düne"} Göre
                  </div>
                </div>
                <div className="w-[200px]">
                  <Form.Select aria-label="Default select example">
                    <option>Ürünler</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </div>
              </div>
              <div className="min-h-[270px] flex flex-col space-y-2 items-center justify-center">
                {sutokProductsData?.totalCount !== 0 ?
                  <TableContainer component={Paper}>
                    <Table>


                      <TableHead className="bg-[#EEF2F6]">
                        <TableRow>
                          <TableCell>Ürün / Varyasyon</TableCell>
                          <TableCell>Stok Kodu(SKU)</TableCell>
                          <TableCell>Durum</TableCell>
                          <TableCell>Stok</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="text-center">
                        {sutokProductsData?.pageData &&
                          sutokProductsData?.pageData?.length !== 0 &&
                          sutokProductsData?.pageData?.map((product) => {
                            return (
                              <SutokProductCard
                                product={product}
                              />
                            );
                          })}
                      </TableBody>

                    </Table>
                  </TableContainer>
                  :
                  <h3>Seçilen tarihlerde ürün bulunamadı</h3>}

                <div className="flex flex-1 justify-center">
                  <Pagination
                    page={searchQuery?.page}
                    count={Math.ceil(
                      ((searchQuery.productState === "soldOut" &&
                        sutokProductsCountsData?.soldOutStockCount)) /
                      searchQuery.limit
                    )}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    onChange={(_, value) => setSearchQuery({ ...searchQuery, page: value })}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* 
          
          //::REVIEW Right side of the page
          
          */}
          <div className="flex flex-col gap-3">
            <div className="bg-white p-3 shadow-sm border rounded-md">
              <h5 className="pb-4 border-b border-gray-200">
                Anlık Ziyaretçi Sayısı
              </h5>
              <div className="min-h-[120px] flex items-center justify-center">
                <p className="text-gray-600 text-center font-bold">
                  {analysisData?.numberOfInstantVisitors}
                </p>
              </div>
            </div>
            <div className="bg-white p-3 shadow-sm border rounded-md">
              <div className="flex justify-between items-center border-b border-gray-200 pb-4">
                <h5>Dönüşüm Hunisi</h5>
                {/*<div className="p-1 border rounded-md shadow-sm bg-gray-200 text-gray-600">
                  Geçen Haftaya Göre
                </div> */}
              </div>
              <div className="min-h-[120px]">
                <div className="border-b border-gray-200 pb-3">
                  <div className="mt-3">
                    <div className="flex items-center gap-3">
                      <p>Toplam Ziyaretçi</p>
                      <p className="text-green-600"> {analysisData?.totalVisitor} </p>
                    </div>
                    <div className="border h-[17px]">
                      <div className={`bgPurple h-[17px] text-center`} style={{ width: `${(analysisData?.totalVisitor > 100 ? 100 : analysisData?.totalVisitor)}%` }}>
                        <span className="text-[14px] text-white"></span>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end mt-2">
                    <p className="flex items-center gap-3">
                      <FaArrowUp color="green" size={18} />
                      <span className="text-green-600">% {(analysisData?.totalVisitor).toFixed(2)}</span>
                    </p>
                  </div>
                </div>

                <div className="border-b border-gray-200 pb-3">
                  <div className="mt-3">
                    <div className="flex items-center gap-3">
                      <p>Oluşturulan Sepet</p>
                      <p className="text-purple-400"> {analysisData?.totalBasket} </p>
                    </div>
                    <div className="border h-[17px]">
                      <div className={`bgPurple h-[17px] text-center`} style={{ width: `${(analysisData?.totalBasket > 100 ? 100 : analysisData?.totalBasket)}%` }}>
                        <span className="text-[14px] text-white"></span>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end mt-2">
                    <p>% {(analysisData?.totalBasket).toFixed(2)}</p>
                  </div>
                </div>

                <div className="border-b border-gray-200 pb-3">
                  <div className="mt-3">
                    <div className="flex items-center gap-3">
                      <p>Ödemeye Ulaşanlar</p>
                      <p className="text-purple-400"> {analysisData?.totalPaymentSuccessful} </p>
                    </div>
                    <div className="border h-[17px]">
                      <div className={`bgPurple h-[17px] text-center`} style={{ width: `${(analysisData?.totalPaymentSuccessful > 100 ? 100 : analysisData?.totalPaymentSuccessful)}%` }}>
                        <span className="text-[14px] text-white"></span>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end mt-2">
                    <p>% {(analysisData?.totalPaymentSuccessful).toFixed(2)}</p>
                  </div>
                </div>

                <div className="border-b border-gray-200 pb-3">
                  <div className="mt-3">
                    <div className="flex items-center gap-3">
                      <p>Alışverişi Tamamlayanlar</p>
                      <p className="text-purple-400"> {analysisData?.totalShoppingSuccessful} </p>
                    </div>
                    <div className="border h-[17px]">
                      <div className={`bgPurple h-[17px] text-center`} style={{ width: `${(analysisData?.totalShoppingSuccessful > 100 ? 100 : analysisData?.totalShoppingSuccessful)}%` }}>
                        <span className="text-[14px] text-white"></span>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end mt-2">
                    <p>% {(analysisData?.totalShoppingSuccessful).toFixed(2)}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white p-3 shadow-sm border rounded-md">
              <h5 className="pb-4 border-b border-gray-200">
                Trafik Kaynakları
              </h5>
              <div className="min-h-[120px] flex items-center justify-center">
                <PieChart
                  series={[
                    {
                      data: [
                        { id: 0, value: 10, label: "Direkt", color: "#664df2" },
                        { id: 1, value: 15, label: "Organik Alan", color: "pink" },
                        { id: 2, value: 20, label: "Referral" },
                      ],
                    },
                  ]}
                  width={400}
                  height={200}
                />
              </div>
            </div>

            <div className="bg-white p-3 shadow-sm border rounded-md">
              <div className="pb-4 border-b border-gray-200 flex justify-between items-center">
                <h5>Kritik Göstergeler</h5>
              </div>
              <div className="flex flex-col gap-3">
                <div className="flex justify-between items-center h-[60px] border-b border-gray-200">
                  <p>Ort. Ürün Fiyatı </p>
                  <p>{analysisData?.averageAdvertAmount}</p>
                </div>
                <div className="flex justify-between items-center h-[60px] border-b border-gray-200">
                  <p>Ort. Sipariş Tutarı </p>
                  <p>{analysisData?.averageOrderAmount}</p>
                </div>
                <div className="flex justify-between items-center h-[60px] ">
                  <p>Ort. Sepet Büyüklüğü </p>
                  <p>{analysisData?.averageBasketQuantity}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Report;
