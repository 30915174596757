import { CubeIcon, PersonIcon } from "@radix-ui/react-icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaCube, FaRegComment, FaRegUser } from "react-icons/fa6";

const TimelineCard = ({ timelineData }) => {
  const [date, setDate] = useState("");

  const editDate = () => {
    const postDate = new Date(timelineData.date);
    const currentDate = new Date();

    const timeDiff = Math.abs(currentDate - postDate);

    const yearsDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));
    const monthsDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30));
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutesDiff = Math.floor(timeDiff / (1000 * 60));
    const secondsDiff = Math.floor(timeDiff / 1000);

    if (yearsDiff > 0) {
      setDate(`${yearsDiff} yıl önce`);
    } else if (monthsDiff > 0) {
      setDate(`${monthsDiff} ay önce`);
    } else if (daysDiff > 0) {
      setDate(`${daysDiff} gün önce`);
    } else if (hoursDiff > 0) {
      setDate(`${hoursDiff} saat önce`);
    } else if (minutesDiff > 0) {
      setDate(`${minutesDiff} dakika önce`);
    } else {
      setDate(`${secondsDiff} saniye önce`);
    }
  };

  useEffect(() => {
    editDate();
  }, [timelineData]);

  return (
    <div className="flex flex-1 flex-row space-x-3 relative">
      <div className="w-10 h-10 flex items-center justify-center z-10 border border-purple-500 bg-purple-100 text-purple-700 rounded-full font-semibold relative ">
        <div>
          {(timelineData.type === "createdUser" && <FaRegUser size={16} />) ||
            (timelineData.type === "order" && <FaCube size={16} />) ||
            (timelineData.type === "comment" && <FaRegComment size={16} />) ||
            (timelineData.type === "sale" && `₺`)}
        </div>
      </div>
      <div className="bg-purple-800 w-[1px] h-[300px] absolute bottom-full left-2 transform -translate-x-1/2 z-0"></div>

      <div className="flex flex-col space-y-2">
        <div className="flex flex-row space-x-2">
          <div className="font-semibold text-sm">{date}</div>
          <div className="text-sm">
            {`${
              new Date(timelineData.date).getHours() < 10
                ? "0" + new Date(timelineData.date).getHours()
                : new Date(timelineData.date).getHours()
            }:${
              new Date(timelineData.date).getMinutes() < 10
                ? "0" + new Date(timelineData.date).getMinutes()
                : new Date(timelineData.date).getMinutes()
            }`}
          </div>
        </div>

        <div className="flex flex-row space-x-2">
          <div>
            {(timelineData.type === "createdUser" &&
              "Kullanıcı siteye üye oldu.") ||
              (timelineData.type === "order" &&
                "Sipariş oluşturuldu. Sipariş ID:") ||
              (timelineData.type === "sale" && "Satış yapıldı. Satış ID:") ||
              (timelineData.type === "comment" &&
                "Personeller tarafından yorum oluşturuldu. Yorum;")}
          </div>

          <div className="textPurple">
            {(timelineData.type === "order" || timelineData.type === "sale") &&
              timelineData._id}
          </div>
        </div>

        {timelineData.type === "comment" ? timelineData.description : ""}
      </div>
    </div>
  );
};

export default TimelineCard;
