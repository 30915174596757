import ClipLoader from 'react-spinners/ClipLoader'
import HashLoader from 'react-spinners/HashLoader'

const CustomLoading = ({ type, size, color, className }) => {

    if (type === "clip") {
        return (
            <div className={className}>
                <ClipLoader
                    color={color}
                    loading={true}
                    size={size}
                />
            </div>
        );
    } else if (type === "hash") {
        return (
            <div className={className}>
                <HashLoader
                    color={color}
                    loading={true}
                    size={size}
                />
            </div>
        )
    }
};

export default CustomLoading;
