import { TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import { FaArrowUpRightFromSquare } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import * as Queries from '../../utils/queries'
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons'
import '../../checkbox.css';

const BrandCard = ({ brandData, brandsRefetch }) => {

    const navigation = useNavigate();

    const updatePopularityStatus = async (value) => {
        await Queries.updateBrand(brandData?._id, {
            popularityStatus: value
        });
        brandsRefetch();
    }

    return (
        <TableRow className="hover:bg-gray-100">
            <TableCell>
                <img
                    src={brandData?.logo}
                    alt='markalogo'
                    className='border p-1 rounded w-16 h-16'
                />
            </TableCell>
            <TableCell>{brandData?.title}</TableCell>
            <TableCell>
                <Checkbox.Root className="CheckboxRoot" id="c1"
                    checked={brandData?.popularityStatus ? true : false}
                    onCheckedChange={(value) => {
                        updatePopularityStatus(value)
                    }}>
                    <Checkbox.Indicator className="CheckboxIndicator">
                        <CheckIcon />
                    </Checkbox.Indicator>
                </Checkbox.Root>
            </TableCell>
            <TableCell>
                <button onClick={() => navigation(`/brandsDetail?id=${brandData?._id}`)}>
                    <FaArrowUpRightFromSquare />
                </button>
            </TableCell>
        </TableRow>
    )
}

export default BrandCard