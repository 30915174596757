import * as React from "react";
import { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import { useLocation, useNavigate } from "react-router-dom";

const DrawerItem = ({ item, open }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [opens, setOpens] = useState(false);

  const handleItemClick = () => {
    if (item.onPress) {
      item.onPress();
    } else if (item.path) {
      navigate(item.path);
    } else if (item.subtitle) {
      setOpens(!opens);
    }
  };

  return (
    <React.Fragment key={item.text}>
      <ListItem disablePadding sx={{ display: "block" }} className={`${item.path === pathname ? "bg-gray-800" : ""}`}>
        <ListItemButton
          selected={item.path === pathname}
          onClick={handleItemClick}
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
              color:"#ccd5df"
              
            }}
            
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} style={{color:"#ccd5df"}} />
        </ListItemButton>
      </ListItem>

      {item.subtitle && (
        <Collapse in={opens} style={{display: open ? "" : "none"}}>
          <List style={{backgroundColor:"#000000"}}>
            {item.subtitle.map((subItem, key) => (
              <ListItemButton
                key={key}
                onClick={() => {
                  navigate(subItem.path);
                }}
                style={{ display: "block" }}
              >
                <ListItemText primary={subItem.text} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
};

export default DrawerItem;
