import React, { useEffect } from 'react'
import * as Queries from '../../utils/queries'
import { useQuery } from 'react-query';

const BasketCard = ({ data }) => {

    const { data: advertData, refetch: advertDataRefetch } = useQuery(
        ["getAdvert", data?.advert],
        () => Queries.getAdvert(data?.advert),
        {
            onError: (err) => {
                console.log("err", err);
            },
            placeholderData: null,
        }
    );

    if (advertData) {
        return (
            <tr>
                <td className="flex items-center gap-2">
                    <img
                        src={advertData?.productGroup?.photos[0]}
                        alt={advertData?.productGroup?._id}
                        className="w-[70px]"
                    />
                    <h6>{`${advertData?.productGroup?.brands[0]?.title}, ${advertData?.productGroup?.title}`}</h6>
                </td>
                <td>{data?.quantity}</td>
                <td>{data?.price}</td>
                <td>{data?.price * data?.quantity}</td>
            </tr>
        )
    }
}

export default BasketCard