import React, { useState } from "react";
import {
  Toolbar,
  Typography,
  TextField,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { Pagination } from '@mui/material'
import {
  Add as AddIcon,
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  Info as InfoIcon,
} from "@material-ui/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import CategoryDialog from "./CategoryDialog";
import { useStyles } from "../styles";
import * as Queries from "../../utils/queries";
import CustomDrawer from "../../components/CustomDrawer";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { IoMdSearch } from "react-icons/io";
import CategoryCard from "./CategoryCard";
import { Toaster } from "react-hot-toast";

const Categories = () => {
  const classes = useStyles();
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const queryClient = useQueryClient();
  const navigation = useNavigate();
  const [searchQuery, setSearchQuery] = useState({
    filterName: "",
    page: 1,
    limit: 10
  });

  const {
    data,
    refetch: categoriesRefetch
  } = useQuery(
    ["LIST_CATEGORIES", searchQuery],
    () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      return Queries.listCategories(
        new URLSearchParams({
          ...searchQuery
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalCategories: 0,
      },
      keepPreviousData: false,
      onError: (err) => {
        console.log("err", err);
      },
    }
  );

  const swapOrderMutation = useMutation(Queries.swapCategories, {
    onSuccess: () => {
      queryClient.invalidateQueries(["LIST_CATEGORIES"]);
    },
    onError: (err) => {
      console.log("err", err.response.data);
    },
  });

  const handleSearch = (event) => {
    setSearchQuery({ ...searchQuery, filterName: event.target.value });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className={classes.root}>
      <CustomDrawer />
      <Toaster />
      <main className={classes.content}>
        <Toolbar />
        <div className="flex flex-1 flex-row space-x-4 items-center px-3 bg-white p-3 shadow-sm rounded-md border">
          <div className="flex flex-1 text-xl font-semibold">Kategoriler</div>

          <button onClick={() => setOpenCategoryDialog(true)} className="flex flex-row space-x-2 items-center justify-center buttonPurple px-3 py-2 rounded">
            <AddIcon />
            <span>Kategori Oluştur</span>
          </button>
        </div>

        <div className="p-3 bg-white border rounded-md flex items-center justify-between w-[100%]">
          <div className="flex items-center border border-gray-200 rounded-md">
            <IoMdSearch size={20} className="ml-3" />{" "}
            <input
              type="text"
              placeholder="Ara"
              variant="outlined"
              size="small"
              value={searchQuery.filterName}
              onChange={handleSearch}
              className={classes.searchInput}
            />
          </div>
        </div>

        <div className="p-3 bg-white border rounded-md">
          <TableContainer component={Paper}>
            <Table>
              <TableHead className="bg-[#EEF2F6]">
                <TableRow>
                  <TableCell>Kategori ID</TableCell>
                  <TableCell>Kategori Görseli</TableCell>
                  <TableCell>Kategori Adı</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.page?.map(category => {
                  return (
                    <CategoryCard
                      key={category?._id}
                      category={category}
                    />
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="flex flex-1 flex-center justify-center mt-3">
            <Pagination
              count={Math.ceil(data?.totalCategories / searchQuery.limit)}
              page={searchQuery.page}
              onChange={(_, value) => setSearchQuery({ ...searchQuery, page: value })}
              shape="rounded"
              variant="outlined"
              color="primary"
            />
          </div>
        </div>
      </main>

      {openCategoryDialog && (
        <CategoryDialog
          openCategoryDialog={openCategoryDialog}
          setOpenCategoryDialog={setOpenCategoryDialog}
          categoriesRefetch={categoriesRefetch}
        />
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="İşlem başarılı."
      />
    </div>
  );
};

export default Categories;
