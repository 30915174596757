import React, { useState } from "react";
import {
  Toolbar,
  Typography,
  TextField,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  MenuItem,
} from "@material-ui/core";
import { Pagination } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useStyles } from "../styles";
import * as Queries from "../../utils/queries";
import CustomDrawer from "../../components/CustomDrawer";
import moment from "moment";
import "moment/locale/tr";
import { Cancel, CheckCircle, InfoOutlined } from "@material-ui/icons";
import { Info } from "@mui/icons-material";
import { Form, FormControl } from "react-bootstrap";
import FeeDialog from "./FeeDialog";
import { IoMdSearch } from "react-icons/io";
import { IoClose, IoFilterOutline } from "react-icons/io5";
import { CiImport } from "react-icons/ci";
import { CiExport } from "react-icons/ci";
import { FaArrowUpRightFromSquare, FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import UserCard from "./UserCard";
const Users = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openFeeDialog, setOpenFeeDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [filterStatus, setFilterStatus] = React.useState("ALL");
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    limit: 10,
    filterName: "",
  });
  const navigation = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const verifyUserMutation = useMutation(Queries.verifyUser, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["LIST_USERS"]);
      alert("İşlem başarılı.");
    },
    onError: (err) => {
      console.log("err", err);
    },
  });

  const denyUserMutation = useMutation(Queries.denyUser, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["LIST_USERS"]);
      alert("İşlem başarılı.");
    },
    onError: (err) => {
      console.log("err", err);
    },
  });

  const {
    data,
  } = useQuery(
    ["LIST_USERS", {
      ...searchQuery,
      filterStatus,
    }],
    () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      return Queries.listUsers(
        new URLSearchParams({
          ...searchQuery,
          filterStatus,
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalUsers: 0,
        totalPage: 0,
      },
      keepPreviousData: false,
    }
  );

  const handleSearch = (event) => {
    setSearchQuery({ ...searchQuery, filterName: event.target.value });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  console.log(data)

  return (
    <div className={classes.root}>
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />
        <div className="bg-white p-3 shadow-sm border rounded-md flex justify-between items-center">
          <Typography variant="h6" gutterBottom>
            Kullanıcılar
          </Typography>
          {/*
          <div className="flex gap-3">
            <button className="bg-gray-200 border rounded-md shadow-sm p-2 flex items-center gap-2 text-gray-600">
              <CiExport /> Dışa Aktar
            </button>
            <button className="bg-gray-200 border rounded-md shadow-sm p-2 flex items-center gap-2 text-gray-600">
              <CiImport /> İçe Aktar
            </button>
            <button
              className="bg-[#6f55ff] text-white p-2 rounded-md shadow-sm"
              onClick={handleOpen}
            >
              Kullanıcı Ekle
            </button>
          </div>
          */}
        </div>
        <Modal show={open} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Kullanıcı Ekle</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="w-[95%]">
              <Form.Label>Kullanıcı Adı</Form.Label>
              <Form.Control type="text" />

              <Form.Label>Email</Form.Label>
              <Form.Control type="email" />

              <Form.Label>Şifre</Form.Label>
              <Form.Control type="password" />
            </Form.Group>
            <button className="py-2 text-center border rounded-md shadow-sm bg-[#6f55ff] text-white my-3 w-full">
              Ekle
            </button>
          </Modal.Body>
        </Modal>

        <div className="bg-white p-3 shadow-sm rounded-md border flex justify-between items-center">
          <div className="w-96 flex items-center border border-gray-200 rounded-md">
            <label
              htmlFor="searchInput"
              className="px-2 py-3 flex items-center justify-center"
            >
              <IoMdSearch size={20} />
            </label>
            <input
              id="searchInput"
              type="text"
              placeholder="Ara"
              variant="outlined"
              size="small"
              value={searchQuery.filterName}
              onChange={handleSearch}
              className={"flex flex-1 h-full"}
            />
          </div>
        </div>

        <div className="p-3 border rounded-md bg-white shadow-sm">
          <TableContainer component={Paper}>
            <Table>
              <TableHead className="bg-[#EEF2F6]">
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Ad Soyad</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Kayıt Tarihi</TableCell>
                  <TableCell>Toplam Sipariş</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.page?.map((user) => {
                  return <UserCard key={user._id} userData={user} />;
                })}
              </TableBody>
              <TableFooter className="flex flex-1 justify-center items-center p-2">
                <div className="flex flex-1 justify-center items-center p-2">
                  <Pagination
                    page={searchQuery.page}
                    onChange={(_, newPage) =>
                      setSearchQuery({ ...searchQuery, page: newPage })
                    }
                    count={data?.totalPage}
                    shape="rounded"
                    variant="outlined"
                    color="primary"
                  />
                </div>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </main>

      {openFeeDialog && (
        <FeeDialog
          openFeeDialog={openFeeDialog}
          setOpenFeeDialog={setOpenFeeDialog}
          initialData={selectedUser}
        />
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="İşlem başarılı."
      />
    </div>
  );
};

export default Users;
