import {
    TableCell,
    TableRow,
} from "@material-ui/core";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const SellerCard = ({ user }) => {

    const navigate = useNavigate();

    return (
        <TableRow onClick={() => {
            navigate(`/satici-detay?id=${user?._id}`);
        }} className="hover:bg-gray-100 cursor-pointer">
            <TableCell>{user._id}</TableCell>
            <TableCell>{((user.name && user.surname) && `${user.name} ${user.surname}`) || (user.name && `${user.name} -`) || (user.name && `- ${user.surname}`) || "-"}</TableCell>
            <TableCell>{user.email || "-"}</TableCell>
            <TableCell>{moment(user.createdAt).format("LL")}</TableCell>
            <TableCell>{user.advertCount}</TableCell>
            <TableCell>{user.pendingPayment}</TableCell>
            <TableCell>{user.stockCount}</TableCell>
        </TableRow>
    )
}

export default SellerCard