import React, { useEffect, useState } from "react";
import { Toolbar, Typography } from "@material-ui/core";
import { useStyles } from "../styles";
import CustomDrawer from "../../components/CustomDrawer";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Table } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";
import { PiNotePencil } from "react-icons/pi";
import { CiClock2 } from "react-icons/ci";
import { AiFillCreditCard } from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import { FaArrowUpRightFromSquare, FaCheck } from "react-icons/fa6";
import { Close } from "@material-ui/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import * as Queries from '../../utils/queries'
import moment from "moment/moment";
import TimelineCard from "./TimelineCard";
import Select from 'react-select'
import BasketCard from "./BasketCard";
import { useAtom } from "jotai";
import { userAtom } from "../../utils/atoms";

const SellerPaymentDetail = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [user, setUser] = useAtom(userAtom);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [searchQuery, setSearchQuery] = useState({
    seller: "",
    status: "PAYMENTMADE"
  });
  const [totalEarnings, setTotalEarnings] = useState(0)

  const statusList = [
    { value: "PENDING", label: "Beklemede" },
    { value: "PAYMENTMADE", label: "Ödemesi Tamamlandı" },
    { value: "DECLINED", label: "Reddedildi" },
  ]

  const { data: sellerPaymentData, refetch: sellerPaymentDataRefetch } = useQuery(
    ["getSellerPayment", searchParams.get("id")],
    () => Queries.getSellerPayment(searchParams.get("id")),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: null,
    }
  );

  const {
    data: { page: sellerPayments },
    refetch: sellerPaymentsRefetch
  } = useQuery(
    ["listAllSellerPayment", searchQuery],
    () => {
      return Queries.listAllSellerPayment(
        new URLSearchParams({
          ...searchQuery
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalCount: 0,
        totalPage: 0
      },
      keepPreviousData: false,
    }
  );

  const handleUpdate = async (data) => {
    const response = await Queries.updateSellerPayment(searchParams.get("id"), data);
    if (response?._id) {
      toast.success("Ödeme başarılı bir şekilde güncellendi");
    } else {
      toast.error("Ödeme güncellenirken bir hata oluştu. Lütfen tekrar deneyiniz");
    }
    sellerPaymentDataRefetch();
    if (data.status && data?.status === "PAYMENTMADE") {
      await Queries.updateUser(sellerPaymentData?.seller?._id, {
        pendingPayment: sellerPaymentData?.seller?.pendingPayment - sellerPaymentData?.amount
      });
    }
    sellerPaymentsRefetch();
  }

  const formatTotalEarnings = async () => {
    let price = 0;
    for (let i = 0; i < sellerPayments.length; i++) {
      price += sellerPayments[i].amount;
    }
    setTotalEarnings(price)
  }

  useEffect(() => {
    if (sellerPaymentData) {
      const status = statusList.find(item => item.value === sellerPaymentData.status)
      setPaymentStatus(status);
      setSearchQuery({
        ...searchQuery,
        seller: sellerPaymentData?.seller?._id
      })
    }
  }, [sellerPaymentData]);

  useEffect(() => {
    formatTotalEarnings();
  }, [sellerPayments])

  return (
    <div className={classes.root}>
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />
        <Toaster />

        <div className="grid grid-cols-[80%,15%] gap-4">
          <div>
            <div className="border bg-white p-3 shadow-sm rounded-md mb-3">
              <div className="flex justify-between items-center">
                <h5 className="flex items-center gap-2 p-2 ">
                  <IoMdInformationCircleOutline size={20} />
                  Ödeme Detayı
                </h5>
              </div>
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <p className="text-gray-500">Ödeme ID</p>
                  <p>{sellerPaymentData?._id}</p>
                </div>
                <div>
                  <p className="text-gray-500">Sipariş Tamamlanma Tarihi</p>
                  <p>{moment(new Date(sellerPaymentData?.createdAt)).format("LL")}</p>
                </div>
                <div>
                  <p className="text-gray-500">Ödeme Durumu</p>
                  {sellerPaymentData?.status === "PENDING" && (
                    <p className="text-gray-500">Beklemede</p>
                  ) || sellerPaymentData?.status === "DECLINED" && (
                    <p className="text-red-500">Reddedildi</p>
                  ) || sellerPaymentData?.status === "PAYMENTMADE" && (
                    <p className="text-green-500">Ödeme Tamamlandı</p>
                  )}
                </div>
                <div>
                  <p className="text-gray-500">Ödeme Tutarı</p>
                  <p>{sellerPaymentData?.amount} TL</p>
                </div>
              </div>
              <div className="flex flex-1 flex-row space-x-3">
                {user?.role === "ADMIN" ?
                  <div className="flex flex-1 flex-row space-x-3">
                    <div className="flex flex-col space-y-1">
                      <div className="font-semibold">Ödeme Durumu</div>
                      <Select
                        className="w-72"
                        value={paymentStatus}
                        onChange={(value) => setPaymentStatus(value)}
                        options={statusList}
                      />
                    </div>
                    <div className="flex items-end">
                      <button onClick={() => handleUpdate({
                        status: paymentStatus?.value,
                        timelines: [
                          ...sellerPaymentData?.timelines,
                          { id: sellerPaymentData?.timelines?.length + 1, type: paymentStatus.value, label: paymentStatus.label, date: new Date() }
                        ]
                      })} className="px-3 py-2 rounded buttonPurple">
                        Kaydet
                      </button>
                    </div>
                  </div> : <></>}

                {sellerPaymentData?.status === "PAYMENTMADE" ?
                  <div className="flex flex-1 flex-col space-y-1 justify-center">
                    <div className="text-gray-500">Ödeme Tamamlanma Tarihi</div>
                    <div>{moment(sellerPaymentData?.updatedAt).format("LL")}</div>
                  </div> : <></>}
              </div>
            </div>

            <div className="border bg-white p-3 shadow-sm rounded-md mb-3">
              <h6 className="flex items-center gap-2 p-2 afterLine">
                Ürünler
              </h6>

              <div className="flex flex-1 flex-col">
                <Table hovered responsive>
                  <thead>
                    <tr>
                      <th style={{ background: "#EEF2F6" }}>Ürün</th>
                      <th style={{ background: "#EEF2F6" }}>Adet</th>
                      <th style={{ background: "#EEF2F6" }}>Fiyat</th>
                      <th style={{ background: "#EEF2F6" }}>Toplam Tutar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sellerPaymentData?.products?.map((item, index) => {
                      return <BasketCard key={index} data={item} />;
                    })}
                  </tbody>
                </Table>
              </div>
            </div>

            <div className="border my-3 p-3 shadow-sm rounded-md bg-white relative">
              <h6 className="flex items-center gap-2 p-2 afterLine">
                <FaUserAlt color="#000000" />
                Satıcı
              </h6>

              <div onClick={() => navigate(`/satici-detay?id=${sellerPaymentData?.seller?._id}`)} className="absolute top-2.5 right-4 cursor-pointer">
                <FaArrowUpRightFromSquare />
              </div>

              <div className="grid grid-cols-3">
                <div className="border-r p-2 border-gray-200">
                  <h6 className="text-purple-800">
                    {((sellerPaymentData?.seller?.name && sellerPaymentData?.seller?.surname) && `${sellerPaymentData?.seller?.name} ${sellerPaymentData?.seller?.surname}`) || (sellerPaymentData?.seller?.name && `${sellerPaymentData?.seller?.name} -`) || (sellerPaymentData?.seller?.name && `- ${sellerPaymentData?.seller?.surname}`) || "-"}
                  </h6>
                  <p>
                    {sellerPaymentData?.seller?.phoneNumber || "-"}
                  </p>
                </div>

                <div className="p-2 border-r border-gray-200">
                  <div className="flex items-center justify-between">
                    <h5>Banka Bilgileri</h5>
                  </div>
                  <p>
                    {sellerPaymentData?.seller?.ibanBankName || "-"}
                  </p>
                  <p className="text-gray-600">
                    {sellerPaymentData?.seller?.ibanAccountName || "-"}
                  </p>
                  <p className="text-gray-600">
                    {sellerPaymentData?.seller?.ibanNumber || "-"}
                  </p>
                </div>

                <div className="p-2">
                  <div className="flex items-center justify-between">
                    <h5> Adresi</h5>
                    {/*<PiNotePencil size={20} color="#000000" />*/}
                  </div>
                  <p>Ev</p>
                  <p className="text-gray-600">
                    {`${sellerPaymentData?.seller?.openAddress} ${sellerPaymentData?.seller?.city || "-"}/${sellerPaymentData?.seller?.district || "-"}`}
                  </p>
                </div>
              </div>
            </div>

            <div className="border bg-white shadow-sm rounded-md p-3">
              <div className="flex items-center gap-2 p-2 afterLine2">
                <CiClock2 size={20} />
                <h5 className="mt-2 ">Zaman Çizelgesi</h5>
              </div>
              <div className="flex flex-col gap-3 p-5">
                {sellerPaymentData?.timelines
                  ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                  ?.map((timeline) => {
                    return <TimelineCard key={timeline?.id} data={timeline} />;
                  })}
              </div>
            </div>
          </div>

          {/* 
            //TODO: Right Side
          */}
          <div className="flex flex-col gap-4">
            <div className="border  p-3 shadow-sm rounded-md bg-white">
              <h5 className="pt-2">Ödeme Özeti</h5>
              <hr />
              <p>
                {((sellerPaymentData?.seller?.name && sellerPaymentData?.seller?.surname) && `${sellerPaymentData?.seller?.name} ${sellerPaymentData?.seller?.surname}`) || (sellerPaymentData?.seller?.name && `${sellerPaymentData?.seller?.name} -`) || (sellerPaymentData?.seller?.name && `- ${sellerPaymentData?.seller?.surname}`) || "-"}
              </p>
              <div className="grid grid-cols-2">
                <p className="text-gray-500">Bekleyen Ödeme</p>
                <p>{sellerPaymentData?.seller?.pendingPayment % 1 === 0 ? sellerPaymentData?.seller?.pendingPayment : sellerPaymentData?.seller?.pendingPayment.toFixed(2)} TL</p>
              </div>

              <div className="grid grid-cols-2">
                <p className="text-gray-500">Toplam Kazanç</p>
                <p>{totalEarnings % 1 === 0 ? totalEarnings : totalEarnings.toFixed(2)} TL</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SellerPaymentDetail;
