import { PhoneOutlined } from '@material-ui/icons'
import { ClockIcon, EnvelopeClosedIcon, GlobeIcon } from '@radix-ui/react-icons'
import moment from 'moment'
import React from 'react'
import { FaRegUser } from 'react-icons/fa'

const PersonalInformations = ({ userData }) => {
    return (
        <div className='flex flex-1 flex-col bg-white rounded border-1 border-gray-200'>
            <div className='p-4 border-b-2 border-slate-200'>
                {((userData.name && userData.surname) && `${userData.name} ${userData.surname}`) || (userData.name && `${userData.name}`) || (userData.name && `${userData.surname}`) || "-"}
            </div>
            <div className='p-4 flex flex-row space-x-4'>
                <div className='flex flex-1 flex-col space-y-2'>
                    <div className='flex flex-row space-x-3 items-center'>
                        <EnvelopeClosedIcon />
                        <span className='text-sm text-gray-500'>E-Posta</span>
                    </div>
                    <div>
                        {userData.email || "-"}
                    </div>
                </div>

                <div className='flex flex-1 flex-col space-y-2 border-l-2 border-r-2 border-slate-200 px-3'>
                    <div className='flex flex-row space-x-3 items-center'>
                        <PhoneOutlined />
                        <span className='text-sm text-gray-500'>Telefon Numarası</span>
                    </div>
                    <div>
                        {userData.phoneNumber || "-"}
                    </div>
                </div>

                <div className='flex flex-1 flex-col space-y-2 px-3'>
                    <div className='flex flex-row space-x-3 items-center'>
                        <FaRegUser />
                        <span className='text-sm text-gray-500'>Üyelik Tarihi</span>
                    </div>
                    <div>
                        {moment(userData.createdAt).format("LL")}
                    </div>
                </div>

                <div className='flex flex-1 flex-col space-y-2 border-l-2 border-r-2 border-slate-200 px-3'>
                    <div className='flex flex-row space-x-3 items-center'>
                        <ClockIcon />
                        <span className='text-sm text-gray-500'>En Son Ziyaret</span>
                    </div>
                    <div>
                        {moment(userData.lastSession).format("LL")}
                    </div>
                </div>

                <div className='flex flex-1 flex-col space-y-2 px-3'>
                    <div className='flex flex-row space-x-3 items-center'>
                        <GlobeIcon />
                        <span className='text-sm text-gray-500'>Dil Tercihi</span>
                    </div>
                    <div>
                        Türkçe
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalInformations