import React, { useState } from 'react'
import Select from 'react-select'
import * as Queries from '../../utils/queries'
import toast from 'react-hot-toast'

const CreateProductattributeForm = ({ setIsProductattributeFormVisible, productattributesRefetch }) => {

    const [formValues, setFormValues] = useState({
        title: "",
        view: null
    })

    const views = [
        { value: "LIST", label: "Liste" },
        { value: "COLOR_PICKER", label: "Renk" }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formValues.title && formValues.view) {
            const response = await Queries.createAttribute({
                title: formValues.title,
                view: formValues.view.value
            });
            if (response?._id) {
                toast.success("Nitelik başarılı bir şekilde oluşturuldu");
                setFormValues({
                    title: "",
                    view: null
                });
                setIsProductattributeFormVisible(false)
            }
        } else {
            if (!formValues.title) {
                toast.error("Lütfen yeni nitelik için başlık giriniz");
            }
            if (!formValues.view) {
                toast.error("Lütfen yeni nitelik için görünüm seçiniz");
            }
        }
        productattributesRefetch();
    }

    return (
        <form onSubmit={handleSubmit} className='flex flex-1 flex-col space-y-3'>
            <div className='flex flex-1 flex-col space-y-1'>
                <label>Nitelik Türü Adı</label>
                <input
                    className='flex flex-1 borderGray p-2 rounded'
                    name='title'
                    value={formValues.title}
                    onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
                    placeholder='Başlık'
                />
            </div>

            <div className='flex flex-1 flex-col space-y-1'>
                <label>Nitelikler</label>
                <Select
                    options={views}
                    placeholder="Seçiniz"
                    value={formValues.view}
                    onChange={(value) => setFormValues({ ...formValues, view: value })}
                />
            </div>

            <div className='flex flex-1 flex-row space-x-3'>
                <button type='submit' className='px-3 py-2 rounded buttonPurple'>
                    Oluştur
                </button>

                <button onClick={() => setIsProductattributeFormVisible(false)} type='button' className='px-3 py-2 rounded bg-gray-100 hover:bg-gray-200'>
                    Vazgeç
                </button>
            </div>
        </form>
    )
}

export default CreateProductattributeForm