import React, { useState } from "react"
import {
  Toolbar,
  Typography,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  TextField,
  MenuItem,
} from "@material-ui/core"
import { useQuery } from "react-query"
import { useStyles } from "../styles"
import * as Queries from "../../utils/queries"
import CustomDrawer from "../../components/CustomDrawer"
import moment from "moment"
import "moment/locale/tr"

const Offers = () => {
  const classes = useStyles()
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [filterStatus, setFilterStatus] = useState("PENDING")

  const [page, setPage] = useState(1)

  const {
    data: { page: offers, totalOffers },
  } = useQuery(
    ["LIST_OFFERS", page, filterStatus],
    () => {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0

      return Queries.listOffers(
        new URLSearchParams({
          page,
          status: filterStatus
        }).toString()
      )
    },
    {
      placeholderData: {
        page: [],
        totalOffers: 0,
      },
      keepPreviousData: false,
    }
  )

  const handleSnackbarClose = () => {
    setOpenSnackbar(false)
  }

  return (
    <div className={classes.root}>
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar  />
        <Typography variant="h6" gutterBottom>
          Teklifler
        </Typography>

        <div className={classes.searchContainer}>
          <TextField
            select
            label="Teklif Durumu"
            variant="outlined"
            size="small"
            fullWidth
            value={filterStatus}
            className={classes.searchTypeSelect}
            onChange={(e) => {
              setFilterStatus(e.target.value)
            }}
          >
            <MenuItem value="ALL">Tümü</MenuItem>
            <MenuItem value="PENDING">Bekleyenler</MenuItem>
            <MenuItem value="APPROVED">Onaylananlar</MenuItem>
            <MenuItem value="DENIED">Reddedilenler</MenuItem>
          </TextField>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Gönderen</TableCell>
                <TableCell>Ürün</TableCell>
                <TableCell>Fiyat</TableCell>
                <TableCell>Tarih</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {offers.map((offer) => (
                <TableRow key={offer.id}>
                  <TableCell component="th" scope="row">
                    {offer.sender?.email}
                  </TableCell>
                  <TableCell>{offer.advert.productGroup.title}</TableCell>
                  <TableCell>{offer.price} TL</TableCell>
                  <TableCell>{moment(offer.createdAt).format("LL")}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TablePagination
                count={totalOffers}
                page={page - 1}
                rowsPerPageOptions={[{ value: 10 }]}
                rowsPerPage={10}
                onPageChange={(_, newPage) => {
                  setPage(newPage + 1)
                }}
              />
            </TableFooter>
          </Table>
        </TableContainer>
      </main>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="İşlem başarılı."
      />
    </div>
  )
}

export default Offers
