import React, { useState } from "react";
import {
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { Pagination } from "@mui/material";
import { useQuery } from "react-query";
import { useStyles } from "../styles";
import * as Queries from "../../utils/queries";
import CustomDrawer from "../../components/CustomDrawer";
import { IoMdSearch } from "react-icons/io";
import { Toaster } from "react-hot-toast";
import SellerRequestCard from "./SellerRequestCard";

const SellerRequests = () => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    limit: 10,
    filterName: "",
  });

  const {
    data,
    refetch: sellerRequestsRefetch
  } = useQuery(
    ["LIST_SELLERREQUESTS", searchQuery],
    () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      return Queries.listSellerRequests(
        new URLSearchParams({
          ...searchQuery,
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalCount: 0,
        totalPage: 0,
      },
      keepPreviousData: false,
      onError: (err) => {
        console.log("err", err);
      },
    }
  );

  return (
    <div className="flex flex-1 bg-white">
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />
        <Toaster />
        <div className="flex flex-1 flex-row space-x-4 items-center px-3 bg-white p-3 shadow-sm rounded-md border">
          <div className="flex flex-1 text-xl font-semibold">Satıcı Talepleri</div>
        </div>

        <div className="flex gap-3 bg-white w-full shadow-sm border p-3 rounded-md ">
          <div className="flex items-center border border-gray-200 rounded-md">
            <IoMdSearch size={20} className="ml-3" />{" "}
            <input
              type="text"
              placeholder="Ara"
              variant="outlined"
              size="small"
              className={classes.searchInput}
              value={searchQuery.filterName}
              onChange={e => {
                setSearchQuery({ ...searchQuery, filterName: e.target.value });
              }}
            />
          </div>
        </div>

        <div className=" bg-white p-3 shadow-sm rounded-md border">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className="bg-[#EEF2F6]">
                  <TableCell>ID</TableCell>
                  <TableCell>Talep Sahibi</TableCell>
                  <TableCell>Açıklama</TableCell>
                  <TableCell>Tarih</TableCell>
                  <TableCell>{" "}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.totalCount !== 0 ?
                  data?.page?.map((sellerRequest) => {
                    return (
                      <SellerRequestCard
                        key={sellerRequest?._id}
                        sellerRequestData={sellerRequest}
                        sellerRequestsRefetch={sellerRequestsRefetch}
                      />
                    );
                  }) : <></>}
              </TableBody>
            </Table>
          </TableContainer>

          {data?.totalPage > 1 ?
            <div className="flex flex-1 justify-center py-2">
              <Pagination
                page={searchQuery.page}
                onChange={(_, value) => {
                  setSearchQuery({
                    ...searchQuery,
                    page: value,
                  });
                }}
                count={data?.totalPage}
                shape="rounded"
                variant="outlined"
                color="primary"
              />
            </div> : <></>}
        </div>
      </main>
    </div>
  );
};

export default SellerRequests;