import React, { useState } from "react";
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@material-ui/core";
import { useQuery } from "react-query";
import { useStyles } from "../styles";
import * as Queries from "../../utils/queries";
import CustomDrawer from "../../components/CustomDrawer";
import { IoMdSearch } from "react-icons/io";
import { Pagination } from '@mui/material'
import { Toaster } from "react-hot-toast";
import ProductsPendingApprovalCard from "./ProductsPendingApprovalCard";

const ProductsPendingApproval = () => {
    const classes = useStyles();
    const [searchQuery, setSearchQuery] = useState({
        filterName: "",
        page: 1,
        limit: 10
    });

    const {
        data: { page: productsPendingApproval, totalCount, totalPage },
        refetch: productsPendingApprovalRefetch
    } = useQuery(
        ["LIST_PRODUCTSPENDINGAPPROVAL", searchQuery],
        () => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;

            return Queries.listProductsPendingApproval(
                new URLSearchParams({
                    ...searchQuery
                }).toString()
            );
        },
        {
            placeholderData: {
                page: [],
                totalCount: 0,
                totalPage: 0,
            },
            keepPreviousData: false,
        }
    );
    { console.log(productsPendingApproval) }
    const handleSearch = (event) => {
        setSearchQuery({ ...searchQuery, filterName: event.target.value });
    };

    return (
        <div className="flex flex-1 bg-white">
            <CustomDrawer />
            <main className={classes.content}>
                <Toolbar />
                <Toaster />
                <div className="flex flex-1 flex-row space-x-4 items-center px-3 bg-white p-3 shadow-sm rounded-md border">
                    <div className="flex flex-1 text-xl font-semibold">Onay Bekleyen Ürünler</div>
                </div>

                <div className="flex justify-between items-center bg-white p-3 shadow-sm rounded-md border ">
                    <div className="flex items-center border border-gray-200 rounded-md">
                        <IoMdSearch size={20} className="ml-3" />{" "}
                        <input
                            type="text"
                            placeholder="Ara"
                            variant="outlined"
                            size="small"
                            value={searchQuery.filterName}
                            onChange={handleSearch}
                            className={classes.searchInput}
                        />
                    </div>
                </div>

                <div className="p-3 bg-white rounded-md border shadow-sm ">
                    {totalCount !== 0 ?
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead className="bg-[#EEF2F6]">
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Ürün</TableCell>
                                        <TableCell>Oluşturan</TableCell>
                                        <TableCell>Stok</TableCell>
                                        <TableCell>Fiyat</TableCell>
                                        <TableCell>Tür</TableCell>
                                        <TableCell align="right">{" "}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {productsPendingApproval?.map(item => {
                                        return (
                                            <ProductsPendingApprovalCard
                                                key={item?._id}
                                                productsPendingApprovalData={item}
                                                productsPendingApprovalsRefetch={productsPendingApprovalRefetch}
                                            />
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :
                        <div className="p-2 rounded bg-blue-100 text-blue-700">
                            Onay Bekleyen Ürün Bulunmamaktadır
                        </div>}

                    {totalPage > 1 ?
                        <div className="flex flex-1 mt-3 justify-center items-center">
                            <Pagination
                                count={Math.ceil(totalPage)}
                                page={searchQuery.page}
                                onChange={(_, value) => setSearchQuery({ ...searchQuery, page: value })}
                                shape="rounded"
                                variant="outlined"
                                color="primary"
                            />
                        </div> : <></>}
                </div>
            </main>
        </div>
    );
};

export default ProductsPendingApproval;
