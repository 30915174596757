import { TableCell, TableRow } from '@material-ui/core'
import { Cross2Icon } from '@radix-ui/react-icons'
import * as Popover from '@radix-ui/react-popover'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import * as Queries from '../../utils/queries'

const CargoCard = ({ advert }) => {

    const [totalPrice, setTotalPrice] = useState("");
    const [adverts, setAdverts] = useState([]);

    const getTotalPrice = async () => {
        let price = "";

        for (let i = 0; i < advert.basket.length; i++) {
            price = price + advert.basket[i].price;
        }

        formatPrice(price);
    }

    const formatPrice = (value) => {
        if (value) {
            if (value > 999) {
                // Sayı 999'dan büyükse düzenle
                const formatedValue = value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                setTotalPrice(formatedValue);
            } else {
                // Sayı 999 veya daha küçükse düzenlemeye gerek yok
                setTotalPrice(value.toString());
            }
        } else {
            setTotalPrice("-");
        }
    };

    const getAdvertData = async () => {
        let list = [];

        for (let i = 0; i < advert.basket.length; i++) {
            const response = await Queries.getAdvert(advert.basket[i].advert);
            list.push(response);
        }

        setAdverts(list);
    }

    useEffect(() => {
        getTotalPrice();
        getAdvertData();
    }, [advert])

    return (
        <TableRow key={advert.id}>
            <TableCell component="th" scope="row">
                <Popover.Root>
                    <Popover.Trigger asChild className="p-2 rounded hover:bg-gray-100 cursor-pointer">
                        <button className="IconButton cursor-pointer" aria-label="Update dimensions">
                            {advert?.user?.name}
                        </button>
                    </Popover.Trigger>
                    <Popover.Portal>
                        <Popover.Content className="PopoverContent" style={{ zIndex: 9999, marginLeft: 10 }} sideOffset={5}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
                                    <div style={{ flex: 1, fontWeight: 600 }}>Mail</div>
                                    <div style={{ flex: 1 }}>{advert?.user?.email || "-"}</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
                                    <div style={{ flex: 1, fontWeight: 600 }}>İsim</div>
                                    <div style={{ flex: 1 }}>{advert?.user?.name || "-"}</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
                                    <div style={{ flex: 1, fontWeight: 600 }}>Soyisim</div>
                                    <div style={{ flex: 1 }}>{advert?.user?.surname || "-"}</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
                                    <div style={{ flex: 1, fontWeight: 600 }}>Adres</div>
                                    <div style={{ flex: 1 }}>{`${advert?.user?.openAddress}    ${advert?.user?.district || "-"}/${advert?.user?.city || "-"}`}</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
                                    <div style={{ flex: 1, fontWeight: 600 }}>Iban</div>
                                    <div style={{ flex: 1 }}>{advert?.user?.ibanNumber || "-"}</div>
                                </div>
                            </div>
                            <Popover.Close className="PopoverClose" aria-label="Close">
                                <Cross2Icon />
                            </Popover.Close>
                            <Popover.Arrow className="PopoverArrow" />
                        </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </TableCell>
            <TableCell>{adverts[0]?.productGroup?.title}</TableCell>
            <TableCell>{totalPrice} TL</TableCell>
            <TableCell>{moment(advert.createdAt).format("LL")}</TableCell>
        </TableRow>
    )
}

export default CargoCard