import { Dialog, DialogContent, DialogTitle, Snackbar } from "@material-ui/core"
import React from "react"
import { useForm } from "react-hook-form"
import DialogField from "../../components/DialogField"
import CustomDialogActions from "../../components/CustomDialogActions"
import { yupResolver } from "@hookform/resolvers/yup"
import { useMutation } from "react-query"
import * as Queries from "../../utils/queries"
import * as yup from "yup"
import { useNavigate } from "react-router-dom"

const getOrderFields = () => [
  {
    label: "Durum",
    id: "status",
    type: "SELECT",
    options: [
      {
        label: "Beklemede",
        id: "PENDING",
      },
      {
        label: "İptal Edildi",
        id: "DECLINED",
      },
      {
        label: "Kargoya Verildi",
        id: "SHIPPED",
      },
    ],
  },
]

const orderSchema = yup.object({
  status: yup.string().required(),
})

const OrderDialog = ({ openOrderDialog, setOpenOrderDialog, orderId }) => {
  const [message, setMessage] = React.useState()
  const navigate = useNavigate()

  const { register, watch, control, handleSubmit } = useForm({
    resolver: yupResolver(orderSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const updateOrderMutation = useMutation(
    ([orderId, orderDto]) => Queries.updateOrder(orderId, orderDto),
    {
      onSuccess: (res) => {
        console.log("res", res)
        setOpenOrderDialog(false)
        setMessage("Sipariş durumu güncellendi.")
        navigate(0)
      },
      onError: (err) => {
        console.log("err", err)
      },
    }
  )

  const onValid = (data) => {
    updateOrderMutation.mutate([orderId, data])
  }

  const onInvalid = (errors) => {
    setMessage(Object.values(errors)[0].message)
  }

  return (
    <>
      <Dialog
        open={openOrderDialog}
        onClose={() => setOpenOrderDialog(false)}
        fullWidth
      >
        <DialogTitle>{"Sipariş Durumu"}</DialogTitle>
        <DialogContent>
          {getOrderFields().map((item) => {
            return (
              <DialogField
                item={item}
                control={control}
                register={register}
                watch={watch}
                disabled={false}
              />
            )
          })}
        </DialogContent>

        <CustomDialogActions onConfirm={handleSubmit(onValid, onInvalid)} />
      </Dialog>

      <Snackbar
        open={!!message}
        autoHideDuration={3000}
        onClose={() => setMessage(undefined)}
        message={message}
      />
    </>
  )
}

export default OrderDialog
