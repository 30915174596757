import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core"

const QuestionDialog = ({ title, openQuestionDialog, onYesPress, onNoPress }) => {
  return (
    <Dialog open={openQuestionDialog} onClose={onNoPress}>
      <DialogTitle>{title}</DialogTitle>
      <DialogActions>
        <Button onClick={onYesPress} color="secondary">
          Evet
        </Button>
        <Button onClick={onNoPress}             style={{backgroundColor: "#000000", color: "white"}}
>
          Hayır
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default QuestionDialog
