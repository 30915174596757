import React, { useEffect, useState } from "react";
import { useStyles } from "../styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from "@material-ui/core";
import CustomDrawer from "../../components/CustomDrawer";
import * as Queries from "../../utils/queries";
import CustomLoading from "../../components/CustomLoading";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { useQuery } from "react-query";
import { Toaster } from "react-hot-toast";
import PersonalInformations from "./PersonalInformations";
import Sales from "./Sales";
import Addresses from "../../components/Addresses";
import UserTimeline from "../../components/UserTimeline";
import { useNavigate, useSearchParams } from "react-router-dom";
import SellerPayments from "./SellerPayments";
import CommissionRateDialog from "./CommissionRateDialog";
import Products from "./Products";
import UpdateModelsDialog from "./UpdateModelsDialog";

const SellerDetail = () => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const [sellerData, setSellerData] = useState(null);
  const [pendingPayment, setPendingPayment] = useState(0);
  const navigation = useNavigate();
  const [ordersFilterQuery, setOrdersFilterQuery] = useState({
    page: 1,
    limit: 5,
  });
  const [salesFilterQuery, setSalesFilterQuery] = useState({
    page: 1,
    limit: 5,
    user: searchParams.get("id"),
  });
  const [productsFilterQuery, setProductsFilterQuery] = useState({
    page: 1,
    limit: 5,
    createdBy: searchParams.get("id"),
  });
  const [sellerPaymentsFilterQuery, setSellerPaymentsFilterQuery] = useState({
    page: 1,
    limit: 5,
    seller: searchParams.get("id"),
    status: "PAYMENTMADE"
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openUpdateModelsDialog, setOpenUpdateModelsDialog] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1)

  const formatPendingPayment = async (adverts) => {
    let price = 0;
    for (let i = 0; i < adverts.length; i++) {
      price += adverts[i].price;
    }
    setPendingPayment(price);
  };

  const getSellerDetail = async (userId) => {
    const response = await Queries.getUserDetail(userId);
    setSellerData(response?.user);
    formatPendingPayment(response?.user?.adverts);
  };

  const { data: ordersData } = useQuery(
    [
      "getOrdersData",
      {
        ...ordersFilterQuery,
        userId: searchParams.get("id"),
      },
    ],
    () => {
      return Queries.getUserOrders(
        new URLSearchParams({
          ...ordersFilterQuery,
          userId: searchParams.get("id"),
        }).toString()
      );
    },
    {
      placeholderData: {
        orders: [],
        totalCount: 0,
        totalPage: 0,
      },
      keepPreviousData: false,
    }
  );

  const { data: salesData } = useQuery(
    [
      "listSales",
      {
        ...salesFilterQuery,
        user: searchParams.get("id"),
      },
    ],
    () => {
      return Queries.listSales(
        new URLSearchParams({
          ...salesFilterQuery,
          user: searchParams.get("id"),
        }).toString()
      );
    },
    {
      placeholderData: {
        sales: [],
        totalCount: 0,
        totalPage: 0,
      },
    }
  );

  const { data: sellerPaymentsData } = useQuery(
    [
      "listAllSellerPayment",
      {
        ...sellerPaymentsFilterQuery,
        seller: searchParams.get("id"),
      },
    ],
    () => {
      return Queries.listAllSellerPayment(
        new URLSearchParams({
          ...sellerPaymentsFilterQuery,
          seller: searchParams.get("id"),
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalCount: 0,
        totalPage: 0,
      },
    }
  );

  const { data: productsData } = useQuery(
    [
      "listProducts",
      {
        ...productsFilterQuery,
        createdBy: searchParams.get("id"),
      },
    ],
    () => {
      return Queries.listProductGroups(
        new URLSearchParams({
          ...productsFilterQuery,
          createdBy: searchParams.get("id"),
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalCount: 0,
        totalPage: 0,
      },
    }
  );

  useEffect(() => {
    getSellerDetail(searchParams.get("id"));
  }, [searchParams.get("id")]);

  if (sellerData) {
    return (
      <div className="flex flex-1 bgf7fafc">
        <Toaster />
        <CustomDrawer />
        <main className={classes.content}>
          <Toolbar />
          <div className="flex flex-1 flex-col gap-3">
            <PersonalInformations sellerData={sellerData} setOpenDialog={setOpenDialog} setOpenUpdateModelsDialog={setOpenUpdateModelsDialog} />

            {openDialog && (
              <CommissionRateDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                sellerData={sellerData}
                getSellerDetail={getSellerDetail}
              />
            )}
            {openUpdateModelsDialog && (
              <UpdateModelsDialog
                openDialog={openUpdateModelsDialog}
                setOpenDialog={setOpenUpdateModelsDialog}
                sellerData={sellerData}
                getSellerDetail={getSellerDetail}
              />
            )}

            <div className="p-3 flex flex-row  bg-white rounded-md shadow-sm border">
              <div className="flex flex-1 flex-col space-y-2">
                <div className="flex flex-row space-x-3 items-center">
                  <span className="text-sm text-gray-500">
                    Total Satış Sayısı
                  </span>
                </div>
                <div className="font-semibold text-lg">
                  {sellerData.advertCount}
                </div>
              </div>

              <div className="flex flex-1 flex-col space-y-2 border-l-2 border-r-2 border-slate-200 px-3">
                <div className="flex flex-row space-x-3 items-center">
                  <span className="text-sm text-gray-500">Bekleyen Ödeme</span>
                </div>
                <div className="font-semibold text-lg">
                  {pendingPayment % 1 === 0
                    ? pendingPayment
                    : pendingPayment.toFixed(2)}{" "}
                  TL
                </div>
              </div>

              <div className="flex flex-1 flex-col space-y-2 px-3">
                <div className="flex flex-row space-x-3 items-center">
                  <span className="text-sm text-gray-500">
                    Ort. Sepet Tutarı
                  </span>
                  <InfoCircledIcon />
                </div>
                <div className="font-semibold text-lg">
                  ₺{" "}
                  {(sellerData.averageBasketInformation.averageBasketAmount %
                    1 ===
                    0
                    ? sellerData.averageBasketInformation.averageBasketAmount
                    : sellerData.averageBasketInformation.averageBasketAmount.toFixed(
                      2
                    )) || 0}
                </div>
              </div>

              <div className="flex flex-1 flex-col space-y-2 px-3 border-l border-slate-200">
                <div className="flex flex-row space-x-3 items-center">
                  <span className="text-sm text-gray-500">
                    Ort. Sepet Büyüklüğü
                  </span>
                  <InfoCircledIcon />
                </div>
                <div className="font-semibold text-lg">
                  {(sellerData.averageBasketInformation.averageBasketSize %
                    1 ===
                    0
                    ? sellerData.averageBasketInformation.averageBasketSize
                    : sellerData.averageBasketInformation.averageBasketSize.toFixed(
                      2
                    )) || 0}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-[25%,75%] gap-3 mr-4">
              <div className="flex flex-col space-y-6 bg-white rounded-md border shadow-sm p-3">
                <div className="h-max">
                  <Addresses userData={sellerData} />
                </div>
                <div className='flex flex-col h-max bg-white rounded border-1 border-gray-200'>
                  <div className='p-3 border-b border-gray-200 font-semibold'>
                    Banka Bilgileri
                  </div>
                  <div className='flex flex-col space-y-2 p-3 h-max'>
                    <div className='flex flex-row border-b border-gray-200 pb-2'>
                      <div style={{ flex: 1 }} className='font-semibold'>Banka Adı</div>
                      <div style={{ flex: 1 }}>{sellerData?.ibanBankName}</div>
                    </div>
                    <div className='flex flex-row border-b border-gray-200 pb-2'>
                      <div style={{ flex: 1 }} className='font-semibold'>Hesap Adı</div>
                      <div style={{ flex: 1 }}>{sellerData?.ibanAccountName}</div>
                    </div>
                    <div className='flex flex-col  pb-2'>
                      <div className='font-semibold'>IBAN Numarası</div>
                      <small>{sellerData?.ibanNumber}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <div className=" bg-white rounded-md border shadow-sm p-3 flex flex-col space-y-1">
                  <div className="flex flex-1 flex-row space-x-5">
                    <span onClick={() => setSelectedTab(1)} className={`pb-1 cursor-pointer select-none ${selectedTab === 1 && "border-b borderPurple textPurple"}`}>Satışlar</span>
                    <span onClick={() => setSelectedTab(2)} className={`pb-1 cursor-pointer select-none ${selectedTab === 2 && "border-b borderPurple textPurple"}`}>Ödemeler</span>
                    <span onClick={() => setSelectedTab(3)} className={`pb-1 cursor-pointer select-none ${selectedTab === 3 && "border-b borderPurple textPurple"}`}>Ürünler</span>
                  </div>

                  {selectedTab === 1 && (
                    <Sales
                      salesData={salesData}
                      salesFilterQuery={salesFilterQuery}
                      setSalesFilterQuery={setSalesFilterQuery}
                    />
                  ) || selectedTab === 2 && (
                    <SellerPayments
                      sellerPaymentsData={sellerPaymentsData}
                      sellerPaymentsFilterQuery={sellerPaymentsFilterQuery}
                      setSellerPaymentsFilterQuery={setSellerPaymentsFilterQuery}
                    />
                  ) || selectedTab === 3 && (
                    <Products
                      productsData={productsData}
                      productsFilterQuery={productsFilterQuery}
                      setProductsFilterQuery={setProductsFilterQuery}
                    />
                  )}
                </div>
                <UserTimeline userData={sellerData} />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <CustomDrawer />
        <main className={classes.content}>
          <Toolbar />
          <div className="flex flex-1 h-full justify-center items-center">
            <CustomLoading
              className={""}
              color={"black"}
              size={24}
              type={"clip"}
            />
          </div>
        </main>
      </div>
    );
  }
};

export default SellerDetail;
