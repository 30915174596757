import { TableCell, TableRow } from "@material-ui/core";
import moment from "moment/moment";
import React from "react";
import * as Queries from '../../utils/queries'
import toast from "react-hot-toast";
import { FaCircleCheck, FaTrash } from "react-icons/fa6";

const SellerRequestCard = ({ sellerRequestData, sellerRequestsRefetch }) => {

    const handleAccept = async () => {
        if (sellerRequestData?.createdBy) {
            const response = await Queries.updateUser(sellerRequestData?.createdBy?._id, { role: "SELLER" });
            if (response?._id) {
                const deletedResponse = await Queries.deleteSellerRequest(sellerRequestData?._id);
                if (deletedResponse?._id) {
                    toast.success("Talep başarılı bir şekilde kabul edildi")
                } else {
                    toast.error("Talep kabul edilirken bir hata meydana geldi. Lütfen tekrar deneyiniz")
                }
            } else {
                toast.error("Talep kabul edilirken bir hata meydana geldi. Lütfen tekrar deneyiniz")
            }
        } else {
            const deletedResponse = await Queries.deleteSellerRequest(sellerRequestData?._id);
            if (deletedResponse?._id) {
                toast.success("Talep başarılı bir şekilde kabul edildi")
            } else {
                toast.error("Talep kabul edilirken bir hata meydana geldi. Lütfen tekrar deneyiniz")
            }
        }
        sellerRequestsRefetch();
    }

    const handleDelete = async () => {
        const response = await Queries.deleteSellerRequest(sellerRequestData?._id);
        if (response?._id) {
            toast.success("Talep başarılı bir şekilde silindi")
        } else {
            toast.error("Talep silinirken bir hata meydana geldi. Lütfen tekrar deneyiniz")
        }
        sellerRequestsRefetch();
    }

    return (
        <TableRow className="border-b hover:bg-gray-100 text-sm">
            <TableCell
                scope="row"
                class="px-3 py-3 text-gray-900"
            >
                {sellerRequestData?._id}
            </TableCell>
            <TableCell class="px-3 py-3">
                <div>{`${sellerRequestData?.name} ${sellerRequestData?.surname}`}</div>
                <div>{sellerRequestData?.email}</div>
            </TableCell>
            <TableCell class="px-3 py-3">
                {sellerRequestData?.message}
            </TableCell>
            <TableCell class="px-3 py-3">
                <div>{moment(sellerRequestData?.createdAt).format("LL")}</div>
                <div>{moment(sellerRequestData?.createdAt).format("hh:mm")}</div>
            </TableCell>
            <TableCell class="px-3 py-3 text-black">
                <div className="flex flex-row gap-3">
                    <button onClick={() => handleAccept()}>
                        <FaCircleCheck color="green" size={24} />
                    </button>

                    <button onClick={() => handleDelete()}>
                        <FaTrash color="red" size={24} />
                    </button>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default SellerRequestCard;