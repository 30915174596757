import { Box } from "@material-ui/core"
import React from "react"
import { Controller } from "react-hook-form"
import { ChromePicker } from "react-color"

const ColorPickerField = ({ item, control }) => {
  return (
    <Controller
      key={item.id}
      name={item.id}
      control={control}
      render={({ field }) => {
        return (
          <Box
            className="MuiFormControl-marginNormal"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              
            }}
          >
            <ChromePicker color={field.value} onChange={field.onChange} />
          </Box>
        )
      }}
    />
  )
}

export default ColorPickerField
