import { Pagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProductCard from './ProductCard'
import { useQuery } from 'react-query';
import * as Queries from '../../../utils/queries'
import { useSearchParams } from 'react-router-dom';

const Products = ({ /*productsData, productsFilterQuery, setProductsFilterQuery*/ }) => {

    const [searchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState({
        page: 1,
        limit: 5,
    });

    const { data: productsData } = useQuery(
        [
            "listProducts",
            {
                ...searchQuery,
                user: searchParams.get("id"),
            },
        ],
        () => {
            return Queries.getUserAdverts(
                new URLSearchParams({
                    ...searchQuery,
                    user: searchParams.get("id"),
                }).toString()
            );
        },
        {
            placeholderData: {
                allAdverts: {
                    data: [],
                    totalCount: 0,
                    totalPage: 0
                }
            },
        }
    );

    return (
        <div className='flex flex-col space-y-3 bg-white rounded border-1 border-gray-100 py-3'>
            <div className='px-3 flex flex-row'>
                <div className='flex flex-1'>
                    <span className='pb-1 border-b borderPurple textPurple'>
                        {" "}
                    </span>
                </div>

                {(productsData?.allAdverts?.totalCount !== 0) ?
                    <div className='flex flex-1 justify-end text-gray-600 text-sm'>
                        {`${searchQuery.page * searchQuery.limit - (searchQuery.limit - 1)} - ${(productsData?.allAdverts?.totalCount > searchQuery.limit ? (
                            searchQuery.page !== productsData?.allAdverts?.totalPage ? searchQuery.page * searchQuery.limit
                                : productsData?.allAdverts?.totalCount
                        ) : productsData?.allAdverts?.totalCount)
                            } / ${productsData?.allAdverts?.totalCount} adet`}
                    </div> : <></>}
            </div>

            {(productsData?.allAdverts?.totalCount !== 0) ?
                <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead class="text-xs text-gray-700 bg-gray-100">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                ID
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Ürün
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Fiyat
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Stok
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {productsData?.allAdverts?.data?.map((item) => (
                            <ProductCard
                                key={item._id}
                                product={item}
                            />
                        ))}
                    </tbody>
                </table>
                :
                <div className='w-max p-2 mx-3 rounded bg-blue-100 text-blue-700'>
                    Satıcıya ait henüz bir ürün yok
                </div>}

            {(productsData?.allAdverts?.totalCount !== 0 && productsData?.allAdverts?.totalPage > 1) ?
                <div className='flex flex-1 px-3 justify-center'>
                    <Pagination
                        count={productsData?.allAdverts?.totalPage}
                        page={searchQuery.page}
                        onChange={(_, value) => setSearchQuery({ ...searchQuery, page: value })}
                        variant='outlined'
                        color='primary'
                        shape='rounded'
                    />
                </div> : <></>}
        </div>
    )
}

export default Products