import { useAtom } from 'jotai';
import React, { useState } from 'react'
import { userAtom } from '../../utils/atoms';
import toast from 'react-hot-toast';
import * as Queries from '../../utils/queries'
import { LockClosedIcon } from '@radix-ui/react-icons';
import TimelineCard from './TimelineCard';

const UserTimeline = ({ userData, getUserDetail }) => {

    const [user] = useAtom(userAtom);
    const [newCommentText, setNewCommentText] = useState("");

    const handleCreateComment = async () => {
        const response = await Queries.createUserComment({
            description: newCommentText,
            user: userData._id,
            createdBy: user._id,
            type: "UserComment",
        });

        if (response) {
            toast.success("Yorumunuz başarılı bir şekilde paylaşıldı");
            setNewCommentText("");
            getUserDetail(userData?._id);
        } else {
            toast.error(
                "Yorumunuz paylaşılırken bir hata oluştu. Lütfen tekrar deneyiniz"
            );
        }
    };

    return (
        <div className="flex flex-col h-max bg-white rounded border-1 border-gray-200">
            <div className="p-3 border-b border-gray-200 font-semibold">
                Zaman Çizelgesi
            </div>
            <div className="flex flex-col space-y-3 p-3">
                <div className="flex flex-row space-x-3">
                    {userData.name && userData.surname ? (
                        <div className="w-max h-max p-3 rounded-full border border-gray-100 uppercase">
                            {userData.name[0]}
                            {userData.surname[0]}
                        </div>
                    ) : (
                        <></>
                    )}

                    <div className="flex flex-1 flex-col space-y-2">
                        <div className="flex flex-1 flex-row space-x-2 bgf7fafc border-slate-300 rounded-b rounded-tr">
                            <input
                                className="p-3 flex-1"
                                placeholder="Yorum Yaz..."
                                value={newCommentText}
                                onChange={(e) => setNewCommentText(e.target.value)}
                            />

                            <div className="flex flex-row items-center pr-2 py-3">
                                <div
                                    onClick={() =>
                                        setNewCommentText(`${newCommentText}@`)
                                    }
                                    className="w-8 h-8 flex justify-center items-center rounded-full hover:bg-gray-100 cursor-pointer select-none"
                                >
                                    @
                                </div>

                                <div
                                    onClick={() =>
                                        setNewCommentText(`${newCommentText}#`)
                                    }
                                    className="w-8 h-8 flex justify-center items-center rounded-full hover:bg-gray-100 cursor-pointer select-none"
                                >
                                    #
                                </div>

                                <button
                                    onClick={() => handleCreateComment()}
                                    disabled={!newCommentText}
                                    className={`buttonPurple rounded p-2 ${!newCommentText &&
                                        "disabled:bg-purple-300 disabled:cursor-not-allowed"
                                        }`}
                                >
                                    Gönder
                                </button>
                            </div>
                        </div>

                        <div className="text-gray-500 text-sm flex flex-row space-x-2 items-center ">
                            <LockClosedIcon />
                            <div>
                                Yorumları sadece siz ve diğer personeller görebilir
                            </div>
                        </div>
                    </div>
                </div>

                <div className='max-h-96 flex flex-1 flex-col space-y-6 overflow-auto'>
                    {userData.timelines.map(timeline => {
                        return (
                            <TimelineCard
                                key={timeline._id}
                                timelineData={timeline}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default UserTimeline