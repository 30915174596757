import React, { useEffect, useState } from "react";
import {
  Toolbar,
  Typography,
  Snackbar,
  TextField,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { Pagination } from '@mui/material'
import { useQuery } from "react-query";
import { useStyles } from "../styles";
import * as Queries from "../../utils/queries";
import CustomDrawer from "../../components/CustomDrawer";
import OrderDrawer from "./OrderDrawer";
import moment from "moment";
import { IoFilterOutline } from "react-icons/io5";
import { IoMdSearch } from "react-icons/io";
import OrderCard from "./OrderCard";
const orderStatusMessages = {
  PENDING: "Beklemede",
  DECLINED: "İptal Edildi",
  SHIPPED: "Kargoya Verildi",
};

const filterDates = {
  ALL_TIME: moment().subtract(10, "years").format("YYYY-MM-DD"),
  YESTERDAY: moment().subtract(1, "day").format("YYYY-MM-DD"),
  TODAY: moment().startOf("day").format("YYYY-MM-DD"),
  "1_W": moment().subtract(1, "week").format("YYYY-MM-DD"),
  "1_M": moment().subtract(1, "month").format("YYYY-MM-DD"),
  "3_M": moment().subtract(3, "month").format("YYYY-MM-DD"),
  "1_Y": moment().subtract(1, "years").format("YYYY-MM-DD"),
};

const Orders = () => {
  const classes = useStyles();
  const [selectedOrder, setSelectedOrder] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    limit: 10,
    filterName: "",
    filterStatus: "ALL",
    selectedDate: "ALL_TIME"
  })

  const { data } = useQuery(
    ["LIST_ORDERS", { 
      ...searchQuery, 
      filterDate: filterDates[searchQuery?.selectedDate]
    }],
    () => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

      return Queries.listOrders(
        new URLSearchParams({
          ...searchQuery,
          filterDate: filterDates[searchQuery?.selectedDate]
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [], // Placeholder data for `page`
        totalOrders: 0, // Placeholder for `totalOrders`
      },
      keepPreviousData: false,
    }
  );

  // Check if `data` exists and set defaults if not

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const orderStateList = [
    { value: "ALL", label: "Tümü" },
    { value: "PENDING", label: "Bekleyenler" },
    { value: "DECLINED", label: "Reddedilenler" },
    { value: "SHIPPED", label: "Kargoya Verilenler" },
    { value: "DELIVERED", label: "Teslim Edilenler" },
    { value: "SUTOKCARGO", label: "Sutok Kargo" },
    { value: "INTHEREVIEW", label: "İncelemede" },
    { value: "PAYMENTPENDING", label: "Satıcı Ödemesi Bekleniyor" },
    { value: "PAYMENTMADE", label: "Satıcı Ödeme Tamamlandı" },
  ];

  const orderDateList = [
    { value: "ALL_TIME", label: "Tüm Zamanlar" },
    { value: "1_Y", label: "1 Yıllık" },
    { value: "3_M", label: "3 Aylık" },
    { value: "1_M", label: "1 Aylık" },
    { value: "1_W", label: "1 Haftalık" },
    { value: "TODAY", label: "Bugün" },
    { value: "YESTERDAY", label: "Dün" },
  ];

  const [filtreSee, setFiltre] = useState(false);

  return (
    <div className={classes.root}>
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />
        <Typography variant="h6" gutterBottom>
          Siparişler
        </Typography>

        <div className="flex gap-3 my-3 bg-white w-full shadow-sm border p-3 rounded-md ">
          <div className="flex items-center border border-gray-200 rounded-md">
            <IoMdSearch size={20} className="ml-3" />{" "}
            <input
              type="text"
              placeholder="Ara"
              variant="outlined"
              size="small"
              className={classes.searchInput}
              value={searchQuery.filterName}
              onChange={e => {
                setSearchQuery({ ...searchQuery, filterName: e.target.value });
              }}
            />
          </div>
          <button
            onClick={() => {
              setFiltre(!filtreSee);
            }}
            className="flex items-center gap-2 bg-[#6f55ff] text-white p-2 rounded-md"
          >
            <IoFilterOutline />
            Filtrele
          </button>
        </div>

        <div
          className={`${filtreSee
            ? "flex gap-3 w-max border border-gray-200 shadow-md rounded-md p-5 bg-white"
            : "hidden"
            }`}
        >
          <div className="flex flex-col space-y-1">
            <label className="font-semibold">Sipariş Durumu</label>

            <select
              className="p-2 borderGray rounded select-none"
              value={searchQuery.filterStatus}
              onChange={(e) => {
                setSearchQuery({ ...searchQuery, filterStatus: e.target.value, page: 1 })
              }}>
              <option value={""} disabled selected={searchQuery.filterStatus === ""}>Sipariş Durumu</option>
              {orderStateList?.map(item => {
                return (
                  <option key={item.value} value={item.value} selected={searchQuery.filterStatus === item.value}>{item.label}</option>
                )
              })}
            </select>
          </div>

          <div className="flex flex-col space-y-1">
            <label className="font-semibold">Tarih</label>

            <select
              className="p-2 borderGray rounded select-none"
              value={searchQuery.selectedDate}
              onChange={(e) => {
                setSearchQuery({ ...searchQuery, selectedDate: e.target.value, page: 1 })
              }}>
              <option value={""} disabled selected={searchQuery.selectedDate === ""}>Tarih</option>
              {orderDateList?.map(item => {
                return (
                  <option key={item.value} value={item.value} selected={searchQuery.selectedDate === item.value}>{item.label}</option>
                )
              })}
            </select>
          </div>
        </div>

        <div className="  bg-white w-full shadow-sm border p-3 rounded-md ">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className="bg-[#EEF2F6]">
                  <TableCell >Sipariş ID</TableCell>
                  <TableCell>Tarih, Saat</TableCell>
                  <TableCell>Siparişi Veren</TableCell>
                  <TableCell>Sipariş Durumu</TableCell>
                  <TableCell>Ücret</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.page?.length > 0 ? (data?.page?.map(order => {
                  return (
                    <OrderCard
                      key={order?._id}
                      orderData={order}
                    />
                  )
                })) : <></>}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="flex flex-1 mt-3 justify-center items-center">
            <Pagination
              count={Math.ceil((data?.totalOrders || 10) / 10)}
              page={searchQuery.page}
              onChange={(_, value) => setSearchQuery({ ...searchQuery, page: value })}
              variant="outlined"
              shape="rounded"
              color="primary"
            />
          </div>
        </div>
      </main>

      {showDrawer && selectedOrder && (
        <OrderDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          selectedOrder={selectedOrder}
        />
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="İşlem başarılı."
      />
    </div>
  );
};

export default Orders;
