import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useRef } from "react";
import * as Queries from "../../utils/queries";
import * as yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import Select from 'react-select'

const modelSchema = yup.object({
  title: yup.string().required("Lütfen Kategori Adı Giriniz"),
  description: yup.string().required("Lütfen Açıklama Giriniz"),
  cover: yup.string().url().required("Lütfen Resim Seçiniz"),
  parentModel: yup.object().required("Lütfen Üst Model Seçiniz"),
  brand: yup.object().required("Lütfen Marka Seçiniz"),
});

const ModelDialog = ({
  openModelDialog,
  setOpenModelDialog,
  modelsRefetch
}) => {
  const fileInputRef = useRef(null);

  const { handleSubmit, handleChange, handleReset, errors, touched, values, setValues } = useFormik({
    initialValues: {
      title: "",
      description: "",
      cover: "",
      parentModel: "",
      brand: "",
    },
    onSubmit: async () => {
      const response = await Queries.createModel({
        ...values,
        parentModel: values.parentModel.value,
        brand: values.brand.value,
        pageTitle: "",
        pageDescription: ""
      });

      if (response?._id) {
        toast.success("Model başarılı bir şekilde oluşturuldu");
        handleReset();
        modelsRefetch();
        setOpenModelDialog(false)
      } else {
        toast.error("Marka oluşturulurken bir hata meydana geldi. Lütfen tekrar deneyiniz")
      }
    },
    validationSchema: modelSchema
  });

  const handleImagesUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = function (event) {
        const image = new Image();
        image.src = event.target.result;

        image.onload = async function () {
          const width = image.width;
          const height = image.height;

          if (width <= 2000 && height <= 680) {
            const response = await Queries.uploadMedia(file);

            if (response) {
              setValues({ ...values, cover: response?.secure_url })
            }
          } else {
            toast.error(`Lütfen ${2000}x${680} piksel boyutunda veya daha küçük bir resim seçiniz`);
            e.target.value = null; // Dosya seçiciyi temizle
          }
        };
      };

      reader.readAsDataURL(file);
    } else {
      toast.error('Lütfen bir resim seçin');
    }
  };

  const handleImageInput = () => {
    fileInputRef.current.click();
  };

  const { data: brandsData } = useQuery(
    ["LIST_BRANDS"],
    () => Queries.getBrands("page=1&limit=1000"),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: {
        page: [],
      },
    }
  );

  const { data: productModelsData } = useQuery(
    ["PRODUCT_MODELS_Q"],
    () => Queries.listModels("page=1&limit=1000"),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: {
        page: [],
      },
    }
  );

  return (
    <Dialog
      open={openModelDialog}
      onClose={() => setOpenModelDialog(false)}
      fullWidth
    >
      <DialogTitle>{"Model Ekle"}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} className="flex flex-col gap-3">
          {values.cover ?
            <div className="flex flex-1 justify-center items-center">
              <img
                src={values.cover}
                alt="resim"
                className="w-32 h-32 border"
              />
            </div>
            : <></>}

          <div className="flex flex-col space-y-2">
            <label htmlFor="" className="text-[18px]">
              Model Adı
            </label>
            <input
              type="text"
              className="flex flex-1 border border-gray-200 p-2  rounded-md shadow-sm"
              value={values.title}
              onChange={handleChange("title")}
            />
            {errors.title && touched.title && (
              <div className="p-2 rounded bg-red-100 text-red-700">
                {errors.title}
              </div>
            )}
          </div>

          <div className="flex flex-col space-y-2">
            <label htmlFor="" className="text-[18px]">
              Açıklama
            </label>
            <textarea
              type="text"
              className="flex flex-1 border border-gray-200 p-2  rounded-md shadow-sm"
              value={values.description}
              onChange={handleChange("description")}
            />
            {errors.description && touched.description && (
              <div className="p-2 rounded bg-red-100 text-red-700">
                {errors.description}
              </div>
            )}
          </div>

          <div className="flex flex-col space-y-2">
            <label htmlFor="" className="text-[18px]">
              Marka
            </label>
            {brandsData?.page?.length !== 0 && (
              <Select
                isSearchable
                value={values?.brand}
                onChange={(e) =>
                  setValues({ ...values, brand: e })
                }
                options={brandsData?.page?.map((brand) => {
                  return { value: brand._id, label: brand?.title };
                })}
                placeholder="Marka Seçiniz"
              />
            )}
            {errors.brand && touched.brand && (
              <div className="p-2 rounded bg-red-100 text-red-700">
                {errors.brand}
              </div>
            )}
          </div>

          <div className="flex flex-col space-y-2">
            <label htmlFor="" className="text-[18px]">
              Üst Model
            </label>
            {productModelsData?.page?.length !== 0 && (
              <Select
                isSearchable
                value={values?.parentModel}
                onChange={(e) =>
                  setValues({ ...values, parentModel: e })
                }
                options={productModelsData?.page?.map((brand) => {
                  return { value: brand._id, label: brand?.title };
                })}
                placeholder="Üst Model Seçiniz"
              />
            )}
            {errors.parentModel && touched.parentModel && (
              <div className="p-2 rounded bg-red-100 text-red-700">
                {errors.parentModel}
              </div>
            )}
          </div>

          <div className="flex flex-col space-y-2">
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleImagesUpload}
            />
            <button
              type="button"
              onClick={handleImageInput}
              className="p-2 border-solid border-1 border-black flex flex-1 justify-center items-center rounded hover:bg-gray-50">
              Resim Seç
            </button>

            {errors.photo && touched.photo && (
              <div className="p-2 rounded bg-red-100 text-red-700">
                {errors.photo}
              </div>
            )}
          </div>

          <div className="flex flex-row space-x-6">
            <button type="submit" className="w-max px-3 py-2 buttonPurple rounded">
              Oluştur
            </button>

            <button type="button" onClick={() => {
              handleReset();
              setOpenModelDialog(false)
            }} className="w-max px-3 py-2 bg-gray-100 hover:bg-gray-200 rounded">
              Vazgeç
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ModelDialog;