import React, { useState } from "react";
import {
  Toolbar,
  Typography,
  Snackbar,
  TablePagination,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import HomeSectionDialog from "./HomeSectionDialog";
import { useStyles } from "../styles";
import * as Queries from "../../utils/queries";
import CustomDrawer from "../../components/CustomDrawer";
import HomeSectionCard from "./HomeSectionCard";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IoMdSearch } from "react-icons/io";

const HomeSections = () => {
  const classes = useStyles();
  const [openHomeSectionDialog, setOpenHomeSectionDialog] = useState(false);
  const [selectedHomeSection, setSelectedHomeSection] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const queryClient = useQueryClient();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);

  const {
    data,
  } = useQuery(
    ["LIST_HOME_SECTIONS", searchQuery, page],
    () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      return Queries.listHomeSections(
        new URLSearchParams({
          filterName: searchQuery,
          page,
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalHomeSections: 0,
      },
      keepPreviousData: false,
    }
  );

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    swapOrderMutation.mutate({
      originId: data?.page[result.source.index]._id,
      targetId: data?.page[result.destination.index]._id,
    });
  };
  const swapOrderMutation = useMutation(Queries.swapHomeSections, {
    onSuccess: () => {
      queryClient.invalidateQueries(["LIST_HOME_SECTIONS"]);
    },
    onError: (err) => {
      console.log("err", err.response.data);
    },
  });

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className={classes.root}>
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />
        <Typography variant="h6" gutterBottom>
          Anasayfa Düzeni
        </Typography>
        <div className="flex justify-between flex-row items-center bg-white p-3 shadow-sm rounded-md border  ">
          <div className="flex items-center border border-gray-200 rounded-md">
            <IoMdSearch size={20} className="ml-3" />{" "}
            <input
              type="text"
              placeholder="Ara"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearch}
              className={classes.searchInput}
            />
          </div>

          <button
            onClick={() => {
              setSelectedHomeSection(undefined);
              setOpenHomeSectionDialog(true);
            }}
            className="px-4 py-2 buttonPurple flex flex-row space-x-3 items-center justify-center rounded"
          >
            <AddIcon />

            <div>Bölüm Oluştur</div>
          </button>
        </div>

        <div className="flex flex-1 flex-col bg-white p-3 border shadow-sm rounded-md">
          <div className="flex flex-1 flex-row items-center p-3 border-b border-gray-200 bg-[#EEF2F6] rounded-md">
            <div
              className="flex flex-1"
              style={{
                color: "rgba(0, 0, 0, 0.87)",
                fontWeight: 500,
                lineHeight: "1.5rem",
              }}
            >
              Bölüm Adı
            </div>
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"droppable"}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex flex-1 flex-col"
                >
                  {data?.page?.map((homeSection, homeSectionIndex) => (
                    <Draggable
                      key={homeSection._id}
                      draggableId={homeSection._id} // draggableId'yi string olarak ayarlayın
                      index={homeSectionIndex}
                    >
                      {(provided, snapshot) => (
                        <HomeSectionCard
                          key={homeSectionIndex}
                          homeSections={data?.page}
                          homeSection={homeSection}
                          homeSectionIndex={homeSectionIndex}
                          setOpenHomeSectionDialog={setOpenHomeSectionDialog}
                          setSelectedHomeSection={setSelectedHomeSection}
                          provided={provided}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <TablePagination
            count={data?.totalHomeSections}
            page={page - 1}
            rowsPerPageOptions={[{ value: 10 }]}
            rowsPerPage={10}
            onPageChange={(_, newPage) => {
              setPage(newPage + 1);
            }}
          />
        </div>
      </main>

      {openHomeSectionDialog && (
        <HomeSectionDialog
          openHomeSectionDialog={openHomeSectionDialog}
          setOpenHomeSectionDialog={setOpenHomeSectionDialog}
          initialData={selectedHomeSection}
        />
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="İşlem başarılı."
      />
    </div>
  );
};

export default HomeSections;
