import React, { useState } from "react";
import CustomDrawer from "./CustomDrawer";
import { useStyles } from "../screens/styles";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import { Toolbar, Typography } from "@material-ui/core";
import { Dropdown, Form, InputGroup, FormGroup } from "react-bootstrap";
import { NavLink, useSearchParams } from "react-router-dom";
import ReactQuill from "react-quill";
import { IoKeyOutline } from "react-icons/io5";
import SimpleProduct from "./ProductData/SimpleProduct";

const Post = () => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const [productData, setProductData] = useState("Basit Ürün");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [searchParams] = useSearchParams();

  console.log(searchParams.get("id"))

  return (
    <div className={classes.root}>
      <CustomDrawer />

      <main className={classes.content}>
        <Toolbar />
        <div className="grid grid-cols-[85%,15%] gap-2">
          <div className="flex flex-col gap-3">
            <h5 className="p-2 border rounded-md shadow-sm ">
              Goutte De Pluie Bilezik
            </h5>
            <div>
              <p>Ürün Açıklaması</p>
              <ReactQuill theme="snow" value={value} onChange={setValue} />
            </div>
            <div className="bg-white p-3 border border-gray-300 shadow-md">
              <p className="border-b border-gray-300 font-semibold">
                Ürün Görselleri
              </p>
              <div>
                <button onClick={handleShow}>
                  <img
                    src="https://media.cnn.com/api/v1/images/stellar/prod/230515143657-0707.jpg?q=w_2000,c_fill"
                    alt=""
                    className="w-[300px]"
                  />
                </button>
                <p>Düzenlemek ya da güncellemek için görsele tıklayın</p>
              </div>
              <NavLink to="" className="text-[12px]">
                Ürün Resmini Kaldır
              </NavLink>
            </div>

            <div className="bg-white p-3 border border-gray-300 shadow-md">
              <p className="border-b border-gray-300 font-semibold">
                Ürün Galerisi
              </p>
              <div>
                <img
                  src="https://media.cnn.com/api/v1/images/stellar/prod/230515143657-0707.jpg?q=w_2000,c_fill"
                  alt=""
                  className="w-[100px]"
                />
              </div>
              <NavLink to="" className="text-[12px]">
                Ürün Galerisine Görsel Ekle
              </NavLink>
            </div>

            <div className="bg-white p-3 border border-gray-300 shadow-md">
              <div>
                <p className="border-b border-gray-300 font-semibold">
                  Ürün Verisi
                </p>
                <Dropdown className="">
                  <Dropdown.Toggle className="bg-transparent w-[200px] text-black border border-gray-200" id="dropdown-basic">
                    {productData}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setProductData("Basit Ürün")}>
                      Basit Ürün
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setProductData("Gruplandırılmış Ürün")}
                    >
                      Gruplandırılmış Ürün
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setProductData("Harici Ürün")}
                    >
                      Harici / Bağlantılı Ürün
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setProductData("Varyasyonlu Ürün")}
                    >
                      Varyasyonlu Ürün
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div>
                <SimpleProduct />
              </div>
            </div>
          </div>
          <Modal show={show} onHide={handleClose} centered size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Resmi Güncelle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Dosya Seç</Form.Label>
                <Form.Control type="file" />
              </Form.Group>
            </Modal.Body>
          </Modal>
          {/* 
          REVIEW: right side of the page
          
          */}
          <div className="flex flex-col gap-3">
            <div className="bg-white p-3 border border-gray-300 shadow-md">
              <p className="border-b border-gray-300 font-semibold">Yayınla</p>
              <div className="flex flex-col gap-2">
                <div>
                  <p className="text-gray-500 text-[14px] flex items-center gap-2">
                    <IoKeyOutline />
                    Durum{" "}
                    <NavLink to="" className="text-[12px]">
                      Düzenle
                    </NavLink>{" "}
                  </p>
                  <p className="text-[15px]">Yayınlanmış </p>
                </div>
                <div>
                  <p className="text-gray-500 text-[14px] flex items-center gap-2">
                    <MdOutlineRemoveRedEye /> Görünürlük{" "}
                    <NavLink to="" className="text-[12px]">
                      Düzenle
                    </NavLink>{" "}
                  </p>
                  <p className="text-[15px]">Herkese Açık</p>
                </div>

                <div>
                  <p className="text-gray-500 text-[14px] flex items-center gap-2">
                    <FaCalendarAlt /> Yayınlanma Tarihi{" "}
                    <NavLink to="" className="text-[12px]">
                      Düzenle
                    </NavLink>{" "}
                  </p>
                  <p className="text-[15px]">2 Aralık 2023 19:05</p>
                </div>
                <div>
                  <p className="text-gray-500 text-[14px] flex items-center gap-2">
                    Katalog Görünürlüğü{" "}
                    <NavLink to="" className="text-[12px]">
                      Düzenle
                    </NavLink>{" "}
                  </p>
                  <p className="text-[15px]">Mağaza ve Arama Sonuçları</p>
                </div>
              </div>
            </div>
            <div className="bg-white p-3 border border-gray-300 shadow-md">
              <p className="border-b border-gray-300 font-semibold">
                Ürün Kategorileri
              </p>
              <div>
                <Form.Check type="checkbox" label="Bilezik" />
                <Form.Check type="checkbox" label="Altın" />
                <Form.Check type="checkbox" label="Kadın" />
              </div>
              <NavLink to="" className="text-[12px]">
                Yeni Kategori Ekle
              </NavLink>
            </div>
            <div className="bg-white p-3 border border-gray-300 shadow-md">
              <p className="border-b border-gray-300 font-semibold">
                Ürün Etiketleri
              </p>
              <div>
                <InputGroup>
                  <Form.Control
                    placeholder="Etiket Ekle"
                    aria-describedby="basic-addon2"
                  />
                  <InputGroup.Text id="basic-addon2">Ekle</InputGroup.Text>
                </InputGroup>
              </div>
              <NavLink to="" className="text-[12px]">
                Çok Kullanılanlardan Seçin
              </NavLink>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Post;
