import React, { useState } from "react";
import {
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useQuery } from "react-query";
import BrandDialog from "./BrandDialog";
import { useStyles } from "../styles";
import * as Queries from "../../utils/queries";
import CustomDrawer from "../../components/CustomDrawer";
import { IoMdSearch } from "react-icons/io";
import { Pagination } from '@mui/material'
import BrandCard from "./BrandCard";
import { Toaster } from "react-hot-toast";
import { Add as AddIcon } from "@material-ui/icons";

const Brands = () => {
  const classes = useStyles();
  const [openBrandDialog, setOpenBrandDialog] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    filterName: "",
    page: 1,
    limit: 10
  });

  const {
    data,
    refetch: brandsRefetch
  } = useQuery(
    ["LIST_BRANDS", searchQuery],
    () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      return Queries.listBrands(
        new URLSearchParams({
          ...searchQuery
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalBrands: 0,
      },
      keepPreviousData: false,
    }
  );

  const handleSearch = (event) => {
    setSearchQuery({ ...searchQuery, filterName: event.target.value });
  };

  return (
    <div className="flex flex-1 bg-white">
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />
        <Toaster />
        <div className="flex flex-1 flex-row space-x-4 items-center px-3 bg-white p-3 shadow-sm rounded-md border">
          <div className="flex flex-1 text-xl font-semibold">Markalar</div>

          <button onClick={() => setOpenBrandDialog(true)} className="flex flex-row space-x-2 items-center justify-center buttonPurple px-3 py-2 rounded">
            <AddIcon />
            <span>Marka Oluştur</span>
          </button>
        </div>

        <div className="flex justify-between items-center bg-white p-3 shadow-sm rounded-md border ">
          <div className="flex items-center border border-gray-200 rounded-md">
            <IoMdSearch size={20} className="ml-3" />{" "}
            <input
              type="text"
              placeholder="Ara"
              variant="outlined"
              size="small"
              value={searchQuery.filterName}
              onChange={handleSearch}
              className={classes.searchInput}
            />
          </div>
        </div>

        <div className="p-3 bg-white rounded-md border shadow-sm ">
          <TableContainer component={Paper}>
            <Table>
              <TableHead className="bg-[#EEF2F6]">
                <TableRow>
                  <TableCell>Marka Logosu</TableCell>
                  <TableCell>Marka Adı</TableCell>
                  <TableCell>Popülerlik Durumu</TableCell>
                  <TableCell>{" "}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.page?.map(brand => {
                  return (
                    <BrandCard
                      key={brand?._id}
                      brandData={brand}
                      brandsRefetch={brandsRefetch}
                    />
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="flex flex-1 mt-3 justify-center items-center">
            <Pagination
              count={Math.ceil(data?.totalBrands / searchQuery.limit)}
              page={searchQuery.page}
              onChange={(_, value) => setSearchQuery({ ...searchQuery, page: value })}
              shape="rounded"
              variant="outlined"
              color="primary"
            />
          </div>
        </div>
      </main>

      {openBrandDialog && (
        <BrandDialog
          openBrandDialog={openBrandDialog}
          setOpenBrandDialog={setOpenBrandDialog}
          brandsRefetch={brandsRefetch}
        />
      )}
    </div>
  );
};

export default Brands;
