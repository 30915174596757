import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { IoCloseOutline } from "react-icons/io5";

export const General = () => {
  return (
    <div>
      <Form.Group className="mb-3 w-full min-w-[700px] grid grid-cols-2 gap-3">
        <Form.Label>Normal Fiyat</Form.Label>
        <Form.Control type="number" />

        <Form.Label>İndirimli Satış Fiyatı</Form.Label>
        <Form.Control type="number" />

        <Form.Label>Vergi Durumu</Form.Label>
        <Form.Control as="select">
          <option>Vergilendirilebilir</option>
          <option>Sadece Gönderim</option>
          <option>Yok</option>
        </Form.Control>

        <Form.Label>Vergi Sınıfı</Form.Label>
        <Form.Control as="select">
          <option>Standart</option>
          <option>Reduced Rate</option>
          <option>Zero Rate</option>
        </Form.Control>
      </Form.Group>
    </div>
  );
};

export const Stock = () => {
  return (
    <div>
      <Form.Group className="mb-3 w-full min-w-[700px] grid grid-cols-2 gap-3">
        <Form.Label>Stok Kodu</Form.Label>
        <Form.Control type="number" />

        <Form.Label>Stok Yönetimi</Form.Label>
        <Form.Check
          type="checkbox"
          label="Bu ürün için stok miktarını takip edin"
        />

        <Form.Label>Stok Durumu</Form.Label>
        <div>
          <Form.Check type="radio" label="Stokta Var" name="a" />
          <Form.Check type="radio" label="Stokta Yok" name="a" />
          <Form.Check type="radio" label="Yok Satıldı" name="a" />
        </div>

        <Form.Label>Vergi Sınıfı</Form.Label>
        <Form.Control as="select">
          <option>Standart</option>
          <option>Reduced Rate</option>
          <option>Zero Rate</option>
        </Form.Control>
      </Form.Group>
    </div>
  );
};

export const Shipping = () => {
  return (
    <div>
      <Form.Group className="mb-3 w-[700px] grid grid-cols-2 gap-3">
        <Form.Label>Ağırlık</Form.Label>
        <Form.Control type="number" />

        <Form.Label>Boyutlar</Form.Label>
        <div className="flex gap-3">
          <Form.Control type="number" placeholder="uzunluk" />
          <Form.Control type="number" placeholder="Genişlik" />
          <Form.Control type="number" placeholder="Yükseklik" />
        </div>

        <Form.Label>Gönderim Sınıfı</Form.Label>
        <Form.Control as="select">
          <option>Gönderim Sınıfı Yok</option>
        </Form.Control>
      </Form.Group>
    </div>
  );
};

export const LinkedProducts = () => {
  return (
    <div>
      <Form.Group className="mb-3 w-[700px] grid grid-cols-2 gap-3">
        <Form.Label>Yukarı Satışlar</Form.Label>
        <div className="p-2 border border-gray-200">
          <button className="bg-gray-200 text-gray-400 p-1 flex items-center">
            <IoCloseOutline /> Kolye
          </button>
        </div>

        <Form.Label>Çapraz Satışlar</Form.Label>
        <div className="p-2 border border-gray-200">
          <button className="bg-gray-200 text-gray-400 p-1 flex items-center">
            <IoCloseOutline /> Kolye
          </button>
        </div>
      </Form.Group>
    </div>
  );
};

export const Attributes = () => {
  const [add, setAdd] = useState(false);
  return (
    <div>
      <div className="border-l-2 border-gray-500">
        <p className="p-2">
          Müşterilerin bu ürünü aramak için kullanabilecekleri "Materyal" veya
          "Marka" gibi açıklayıcı bilgi parçaları ekleyin.
        </p>
      </div>
      <button className="border border-blue-500 text-blue-500 p-2 rounded-md">
        Yeni Ekle
      </button>
    </div>
  );
};

export const Advanced = () => {
  return <div>Gelişmiş</div>;
};
