import {
  Dialog,
  DialogContent,
  DialogTitle,
  Portal,
  Snackbar,
} from "@material-ui/core"
import React from "react"
import { useForm } from "react-hook-form"
import DialogField from "../../components/DialogField"
import { useMutation, useQueryClient } from "react-query"
import * as Queries from "../../utils/queries"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import CustomDialogActions from "../../components/CustomDialogActions"

const getProductFields = () => {
  return [
    {
      type: "AUTOCOMPLETE",
      label: "Ürünler",
      id: "productGroup",
      renderText: (option) => option.title,
      options: [],
      fetchFn: (sq) => {
        return Queries.listProductGroups(
          new URLSearchParams({
            filterName: sq,
            page: 1,
          }).toString()
        )
      },
    },
  ]
}

const copyVariantSchema = yup.object({
  productGroup: yup.mixed().optional().nullable(),
})

const CopyVariantDialog = ({
  openVariantDialog,
  setOpenVariantDialog,
  selectedProductGroup,
}) => {
  const [message, setMessage] = React.useState()
  const queryClient = useQueryClient()

  const { register, watch, control, handleSubmit } = useForm({
    resolver: yupResolver(copyVariantSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const copyVariantMutation = useMutation(Queries.copyVariant, {
    onSuccess: (res) => {
      console.log("res", res)
      setOpenVariantDialog(false)
      setMessage("Varyant kopyalandı.")
      queryClient.invalidateQueries(["LIST_PRODUCTS"])
    },
    onError: (err) => {
      console.log("err", err)
    },
  })

  const onValid = (data) => {
    console.log("data", data);

    if (!data.productGroup) {
      console.log("inside")

      return setMessage("Please fill all required fields")
    }

    copyVariantMutation.mutate({
        origin: data.productGroup._id,
        target: selectedProductGroup._id
    })
  }

  const onInvalid = (errors) => {
    const firstErr = Object.values(errors)[0]

    if (Array.isArray(firstErr)) {
      setMessage(firstErr[0].message)
    } else {
      setMessage(firstErr.message)
    }
  }

  return (
    <>
      <Dialog
        open={openVariantDialog}
        onClose={() => setOpenVariantDialog(false)}
        fullWidth
      >
        <DialogTitle>{"Varyant Kopyala"}</DialogTitle>
        <DialogContent>
          {getProductFields().map((item) => {
            return (
              <DialogField
                item={item}
                control={control}
                register={register}
                watch={watch}
                disabled={false}
              />
            )
          })}

          <CustomDialogActions onConfirm={handleSubmit(onValid, onInvalid)} />
        </DialogContent>
      </Dialog>

      <Portal>
        <Snackbar
          open={!!message}
          autoHideDuration={3000}
          onClose={() => setMessage(undefined)}
          message={message}
        />
      </Portal>
    </>
  )
}

export default CopyVariantDialog
