import React from 'react'
import { FaTruckLoading } from 'react-icons/fa'
import {
    TableRow,
    TableCell,
} from "@material-ui/core";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FaArrowUpRightFromSquare, FaTruck } from 'react-icons/fa6';
import { CheckIcon, ClockIcon, Cross2Icon, MagnifyingGlassIcon } from '@radix-ui/react-icons';

const OrderCard = ({ orderData }) => {

    const navigate = useNavigate();

    return (
        <TableRow onClick={() => navigate(`/orderDetail?id=${orderData?._id}`)} className="hover:bg-gray-100 cursor-pointer">
            <TableCell>{orderData?._id}</TableCell>
            <TableCell>
                <div>{moment(orderData.createdAt).format('LL')}</div>
                <div className='flex flex-1 flex-row'>
                    {`${(new Date(orderData?.createdAt)).getHours() < 10 ? "0" + (new Date(orderData?.createdAt)).getHours() : (new Date(orderData?.createdAt)).getHours()}`}
                    :
                    {`${(new Date(orderData?.createdAt)).getMinutes() < 10 ? "0" + (new Date(orderData?.createdAt)).getMinutes() : (new Date(orderData?.createdAt)).getMinutes()}`}
                </div>
            </TableCell>
            <TableCell>{orderData?.user.email}</TableCell>
            <TableCell className='w-max'>
                {orderData?.status === "SHIPPED" ?
                    <div className="p-2 border rounded-md bg-blue-600 text-white shadow-md flex items-center gap-3 w-max select-none">
                        <FaTruckLoading /> Kargoda
                    </div>
                    : orderData?.status === "PENDING" ?
                        <div className="p-2 border rounded-md bg-gray-100 text-black shadow-md flex items-center gap-3 w-max select-none">
                            <ClockIcon /> Beklemede
                        </div>
                        : orderData?.status === "DELIVERED" ?
                            <div className="p-2 border rounded-md bg-green-600 text-white shadow-md flex items-center gap-3 w-max select-none">
                                <CheckIcon /> Teslim Edildi
                            </div>
                            : orderData?.status === "DECLINED" ?
                                <div className="p-2 border rounded-md bg-red-600 text-white shadow-md flex items-center gap-3 w-max select-none">
                                    <Cross2Icon /> İptal Edildi
                                </div>
                                : orderData?.status === "SUTOKCARGO" ?
                                    <div className="p-2 border rounded-md bg-gray-100 text-black shadow-md flex items-center gap-3 w-max select-none">
                                        <FaTruck /> Sutok Kargo
                                    </div>
                                    : orderData?.status === "INTHEREVIEW" ?
                                        <div className="p-2 border rounded-md bg-gray-100 text-black shadow-md flex items-center gap-3 w-max select-none">
                                            <MagnifyingGlassIcon /> İncelemede
                                        </div>
                                        : orderData?.status === "PAYMENTPENDING" ?
                                            <div className="p-2 border rounded-md bg-blue-600 text-white shadow-md flex items-center gap-3 w-max select-none">
                                                <ClockIcon /> Satıcı Ödemesi Bekleniyor
                                            </div>
                                            : orderData?.status === "PAYMENTMADE" ?
                                                <div className="p-2 border rounded-md bg-red-600 text-white shadow-md flex items-center gap-3 w-max select-none">
                                                    <CheckIcon /> Satıcı Ödemesi Tamamlandı
                                                </div>
                                                : <></>}
            </TableCell>
            <TableCell>{orderData?.totalAmount} TL</TableCell>{" "}
        </TableRow>
    )
}

export default OrderCard