import { Dialog, DialogContent, DialogTitle, Snackbar } from "@material-ui/core"
import React from "react"
import { useStyles } from "../styles"
import { useForm } from "react-hook-form"
import DialogField from "../../components/DialogField"
import CustomDialogActions from "../../components/CustomDialogActions"
import { yupResolver } from "@hookform/resolvers/yup"
import { useMutation, useQueryClient } from "react-query"
import * as Queries from "../../utils/queries"
import * as yup from "yup"

const getTermFields = (view) => [
  {
    label: "Değer",
    id: "value",
    type: view === "COLOR_PICKER" ? "COLOR_PICKER" : undefined,
  },
]

const termSchema = yup.object({
  value: yup.mixed().required(),
})

const TermDialog = ({
  openTermDialog,
  setOpenTermDialog,
  initialData,
  selectedAttribute,
}) => {
  const classes = useStyles()
  const [message, setMessage] = React.useState()
  const queryClient = useQueryClient()

  const { register, watch, control, handleSubmit } = useForm({
    defaultValues: initialData,
    resolver: yupResolver(termSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const createTermMutation = useMutation(Queries.createTerm, {
    onSuccess: (res) => {
      console.log("res", res)
      setOpenTermDialog(false)
      setMessage("Ürün özellik değeri oluşturuldu.")
      queryClient.invalidateQueries(["LIST_TERMS"])
    },
    onError: (err) => {
      console.log("err", err)
    },
  })

  const updateTermMutation = useMutation(
    ([termId, termDto]) => Queries.updateTerm(termId, termDto),
    {
      onSuccess: (res) => {
        console.log("res", res)
        setOpenTermDialog(false)
        setMessage("Ürün özellik değeri güncellendi.")
        queryClient.invalidateQueries(["LIST_TERMS"])
      },
      onError: (err) => {
        console.log("err", err)
      },
    }
  )

  const deleteTermMutation = useMutation(Queries.deleteTerm, {
    onSuccess: (res) => {
      console.log("res", res)
      setOpenTermDialog(false)
      setMessage("Ürün özellik değeri silindi.")
      queryClient.invalidateQueries(["LIST_TERMS"])
    },
    onError: (err) => {
      console.log("err", err)
    },
  })

  const onValid = (data) => {
    const value =
      selectedAttribute.view === "COLOR_PICKER" ? data.value.hex : data.value

    if (initialData) {
      updateTermMutation.mutate([initialData._id, { value }])
    } else {
      createTermMutation.mutate({
        value,
        attribute: selectedAttribute._id,
      })
    }
  }

  const onInvalid = (errors) => {
    setMessage(Object.values(errors)[0].message)
  }

  return (
    <>
      <Dialog
        open={openTermDialog}
        onClose={() => setOpenTermDialog(false)}
        fullWidth
      >
        <DialogTitle>{"Özellik Değeri Detayı"}</DialogTitle>
        <DialogContent>
          {getTermFields(selectedAttribute.view).map((item) => {
            return (
              <DialogField
                item={item}
                control={control}
                register={register}
                watch={watch}
                disabled={false}
              />
            )
          })}
        </DialogContent>

        <CustomDialogActions
          isUpdate={initialData}
          onDelete={() => {
            const isConfirmed = window.confirm(
              "Ürün özellik değerini silmek istediğinize emin misiniz?"
            )

            if (isConfirmed) {
              deleteTermMutation.mutate(initialData._id)
            }
          }}
          onConfirm={handleSubmit(onValid, onInvalid)}
        />
      </Dialog>

      <Snackbar
        open={!!message}
        autoHideDuration={3000}
        onClose={() => setMessage(undefined)}
        message={message}
      />
    </>
  )
}

export default TermDialog
