import React, { useEffect, useState } from "react";
import {
  Toolbar,
  Typography,
  TextField,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  MenuItem,
} from "@material-ui/core";
import { useStyles } from "../styles";
import CustomDrawer from "../../components/CustomDrawer";
import { IoMdSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaArrowUpRightFromSquare, FaMoneyBill, FaMoneyBill1 } from "react-icons/fa6";
import { CheckIcon, ClockIcon, Cross2Icon, Pencil2Icon } from '@radix-ui/react-icons';
import * as Queries from '../../utils/queries'
import { useQuery } from "react-query";
import { Pagination } from '@mui/material'
import moment from "moment";
import * as Checkbox from '@radix-ui/react-checkbox';
import '../../checkbox.css';
import Select from 'react-select'
import toast from "react-hot-toast";
import { useAtomValue } from "jotai";
import { userAtom } from "../../utils/atoms";

const SellerPayments = () => {
  const classes = useStyles();
  const navigation = useNavigate();
  const user = useAtomValue(userAtom)
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    limit: 10,
    filterName: "",
    status: "ALL"
  });
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [statusForSelectedPayments, setStatusForSelectedPayments] = useState(null)

  const statusList = [
    { value: "ALL", label: "Tümü" },
    { value: "PENDING", label: "Beklemede Olanlar" },
    { value: "PAYMENTMADE", label: "Ödemesi Tamamlananlar" },
    { value: "DECLINED", label: "Reddedilenler" },
  ];

  const {
    data,
    refetch: sellerPaymentsRefetch
  } = useQuery(
    ["listAllSellerPayment", searchQuery],
    () => {
      return Queries.listAllSellerPayment(
        new URLSearchParams({
          ...searchQuery
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalCount: 0,
        totalPage: 0
      },
      keepPreviousData: false,
    }
  );

  const handleUpdatePayments = async () => {
    let count = 0;

    for (let i = 0; i < selectedPayments.length; i++) {
      const response = await Queries.updateSellerPayment(selectedPayments[i]?._id, {
        status: statusForSelectedPayments.value
      });
      if (response?._id) {
        count += 1;
      } else {
        toast.error(`${selectedPayments[i]?._id} id bilgisine sahip ödeme güncellenemedi`)
      }
    }

    if (count > 0) {
      toast.success(`Ödeme güncelleme işlemi ${count} adet ödeme için başarılı oldu`);
    } else {
      toast.error("Ödeme güncelleme işlemi yapılırken bir hata oluştu. Lütfen tekrar deneyiniz")
    }
    sellerPaymentsRefetch();
  }

  useEffect(() => {
    if (user?._id) {
      sellerPaymentsRefetch();
    }
  }, [user])


  return (
    <div className={classes.root}>
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />

        <div className="flex flex-1 flex-row space-x-4 items-center px-3 bg-white p-3 shadow-sm rounded-md border">
          <div className="flex flex-1 text-xl font-semibold">Ödemeler</div>
        </div>

        <div className="bg-white p-3 shadow-sm rounded-md border flex flex-row space-x-3 items-center">
          <div className="w-96 flex items-center border border-gray-200 rounded-md">
            <label
              htmlFor="searchInput"
              className="px-2 py-3 flex items-center justify-center"
            >
              <IoMdSearch size={20} />
            </label>
            <input
              id="searchInput"
              type="text"
              placeholder="Ara"
              variant="outlined"
              size="small"
              className={"flex flex-1 h-full"}
              value={searchQuery.filterName}
              onChange={e => setSearchQuery({ ...searchQuery, filterName: e.target.value })}
            />
          </div>

          {selectedPayments.length > 0 && (
            <div className="flex flex-1 items-center gap-3 border rounded-md">
              <small className="ml-2 text-center">{selectedPayments.length} Adet Ödeme Seçildi</small>
              <div className="flex flex-1 flex-row space-x-2 items-center gap-3 w-max p-2 h-full" style={{ borderLeft: "1px solid #DEE2E6" }}>
                <Select
                  className="w-full"
                  value={statusForSelectedPayments}
                  onChange={value => setStatusForSelectedPayments(value)}
                  options={statusList.filter(item => item.value !== "ALL")}
                />

                <button onClick={() => handleUpdatePayments()} className="px-3 py-2 rounded buttonPurple">
                  Kaydet
                </button>
              </div>
            </div>
          )}

          <div className={selectedPayments?.length > 0 ? "flex" : "flex flex-1 justify-end"}>
            <select value={searchQuery.status} onChange={e => setSearchQuery({ ...searchQuery, status: e.target.value })}
              className="border p-2 rounded">
              {statusList?.map(item => {
                return (
                  <option key={item.value} value={item.value} selected={item.value === searchQuery.status}>
                    {item.label}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        <div className="p-3 border rounded-md bg-white shadow-sm">
          <TableContainer component={Paper}>
            <Table>
              <TableHead className="bg-[#EEF2F6]">
                <TableRow>
                  <TableCell>
                    <Checkbox.Root className="CheckboxRoot" id="c1"
                      checked={selectedPayments?.length === data?.page?.length}
                      onCheckedChange={(value) => {
                        if (value) {
                          setSelectedPayments(data?.page)
                        } else {
                          setSelectedPayments([]);
                        }
                      }}>
                      <Checkbox.Indicator className="CheckboxIndicator">
                        <CheckIcon />
                      </Checkbox.Indicator>
                    </Checkbox.Root>
                  </TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Ad Soyad</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Ödeme Tutarı</TableCell>
                  <TableCell>Durum</TableCell>
                  <TableCell>Sipariş Teslim Edilme Tarihi</TableCell>
                  <TableCell align="right">Detaylar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.page?.map(item => {
                  return (
                    <TableRow>
                      <TableCell>
                        <Checkbox.Root className="CheckboxRoot" id="c1"
                          checked={selectedPayments?.find(payment => payment._id === item?._id) ? true : false}
                          onCheckedChange={(value) => {
                            if (value) {
                              setSelectedPayments([
                                ...selectedPayments,
                                item
                              ])
                            } else {
                              setSelectedPayments(selectedPayments.filter(payment => payment?._id !== item?._id));
                            }
                          }}>
                          <Checkbox.Indicator className="CheckboxIndicator">
                            <CheckIcon />
                          </Checkbox.Indicator>
                        </Checkbox.Root>
                      </TableCell>
                      <TableCell>{item?._id}</TableCell>
                      <TableCell>
                        {((item?.seller?.name && item?.seller?.surname) && `${item?.seller?.name} ${item?.seller?.surname}`) || (item?.seller?.name && `${item?.seller?.name} -`) || (item?.seller?.name && `- ${item?.seller?.surname}`) || "-"}
                      </TableCell>
                      <TableCell>{item?.seller?.email}</TableCell>
                      <TableCell>{item?.amount}</TableCell>
                      <TableCell>
                        {item?.status === "PENDING" && (
                          <div className="p-2 border rounded-md bg-gray-100 text-black shadow-md flex items-center gap-3 w-max select-none">
                            <ClockIcon /> Beklemede
                          </div>
                        ) || item?.status === "DECLINED" && (
                          <div className="p-2 border rounded-md bg-red-600 text-white shadow-md flex items-center gap-3 w-max select-none">
                            <Cross2Icon />  Reddedildi
                          </div>
                        ) || item?.status === "PAYMENTMADE" && (
                          <div className="p-2 border rounded-md bg-green-600 text-white shadow-md flex items-center gap-3 w-max select-none">
                            <CheckIcon />Ödeme Tamamlandı
                          </div>
                        )}
                      </TableCell>
                      <TableCell>{moment(item?.createdAt).format("LL")}</TableCell>
                      <TableCell align="right">
                        {" "}
                        <button onClick={() => navigation(`/paymentDetail?id=${item?._id}`)}>
                          <FaArrowUpRightFromSquare />
                        </button>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="mt-2 flex flex-1 items-center justify-center">
            <Pagination
              page={searchQuery.page}
              onChange={(_, value) => setSearchQuery({ ...searchQuery, page: value })}
              shape="rounded"
              variant="outlined"
              color="primary"
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default SellerPayments;
