import React, { useEffect, useState } from "react";
import {
  Toolbar,
  TextField,
  MenuItem,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useQuery } from "react-query";
import { useStyles } from "../styles";
import * as Queries from "../../utils/queries";
import CustomDrawer from "../../components/CustomDrawer";
import SalesDrawer from "./SalesDrawer";
import SellerCard from "./SellerCard";
import {
  Cross2Icon,
  MixerHorizontalIcon,
} from "@radix-ui/react-icons";
import { IoMdSearch } from "react-icons/io";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import * as Popover from "@radix-ui/react-popover";
import { Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom"
import AddSellerForm from "./AddSellerForm";

const Sellers = () => {
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    filterName: "",
    filterStatus: "ALL",
    page: 1,
    limit: 10,
  });
  const [open, setOpen] = useState(false);
  const [sorted, setSorted] = useState("");
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    data,
  } = useQuery(
    ["LIST_SELLERS", searchQuery],
    () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      return Queries.listSellers(new URLSearchParams(searchQuery).toString());
    },
    {
      placeholderData: {
        page: [],
        totalSellers: 0,
      },
      keepPreviousData: false,
    }
  );

  const handleSearch = (event) => {
    setSearchQuery({ ...searchQuery, filterName: event.target.value });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="flex flex-1">
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />
        <div className="flex flex-1 flex-row p-3 border bg-white shadow-sm items-center">
          <p className="flex flex-1 text-xl font-semibold pt-2">Satıcılar</p>

          <div className="flex flex-row space-x-3">
            <button
              className="rounded buttonPurple px-3 py-2"
              onClick={handleOpen}
            >
              Satıcı Ekle
            </button>
          </div>
        </div>
        <Modal show={open} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Satıcı Ekle</Modal.Title>
          </Modal.Header>
          <Modal.Body className="z-[50]">
            <AddSellerForm />
          </Modal.Body>
        </Modal>

        <div className="flex border shadow-sm rounded-md bg-white p-3 items-center gap-3 ">
          <div className="flex flex-1 flex-row space-x-3 items-center">
            <div className="flex items-center border border-gray-200 rounded-md">
              <IoMdSearch size={20} className="ml-3" />{" "}
              <input
                type="text"
                placeholder="Ara"
                variant="outlined"
                size="small"
                value={searchQuery.filterName}
                onChange={handleSearch}
                className={classes.searchInput}
              />
            </div>
            <TextField
              select
              label="Filtre"
              variant="outlined"
              size="small"
              value={searchQuery.filterStatus}
              style={{
                minWidth: 300,
                marginTop: 3
              }}
              className={classes.searchTypeSelect}
              onChange={(e) => {
                setSearchQuery({
                  ...searchQuery,
                  filterStatus: e.target.value,
                });
              }}
            >
              <MenuItem value="ALL">Tümü</MenuItem>
              <MenuItem value="PENDING">Ödeme Bekleyenler</MenuItem>
              <MenuItem value="PAID">Ödemesi Yapılanlar</MenuItem>
            </TextField>
          </div>
          <Popover.Root>
            <Popover.Trigger asChild>
              <button className="w-12 h-12 borderGray rounded flex flex-row space-x-2 items-center justify-center">
                <MixerHorizontalIcon />
              </button>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content className="PopoverContent mr-4" sideOffset={5}>
                <div className="flex flex-col space-y-3">
                  <p className="font-semibold">Sıralama</p>

                  <select
                    value={sorted}
                    onChange={(e) => setSorted(e.target.value)}
                    className="borderGray p-2 rounded"
                  >
                    <option disabled value={""} selected={sorted === ""}>
                      Seçiniz
                    </option>
                    <option
                      value={"totalsold_asc"}
                      selected={sorted === "totalsold_asc"}
                    >
                      Artan Satış Sayısı
                    </option>
                    <option
                      value={"totalsold_desc"}
                      selected={sorted === "totalsold_desc"}
                    >
                      Azalan Satış Sayısı
                    </option>
                    <option
                      value={"stockCount_asc"}
                      selected={sorted === "stockCount_asc"}
                    >
                      Artan Stok Sayısı
                    </option>
                    <option
                      value={"stockCount_desc"}
                      selected={sorted === "stockCount_desc"}
                    >
                      Azalan Stok Sayısı
                    </option>
                  </select>

                  <div className="flex flex-1 flex-row space-x-4">
                    <button
                      onClick={() => {
                        setSorted("");
                        setSearchQuery({ ...searchQuery, sorted: "" });
                      }}
                      className="px-4 py-2 border border-gray-100 hover:bg-gray-100 rounded"
                    >
                      Sıfırla
                    </button>

                    <button
                      onClick={() =>
                        setSearchQuery({ ...searchQuery, sorted: sorted })
                      }
                      className="px-4 py-2 buttonPurple rounded"
                    >
                      Uygula
                    </button>
                  </div>
                </div>
                <Popover.Close className="PopoverClose" aria-label="Close">
                  <Cross2Icon />
                </Popover.Close>
                <Popover.Arrow className="PopoverArrow" />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        </div>

        <div className="bg-white border shadow-sm  rounded-md p-3">


          <TableContainer component={Paper}>
            <Table>
              <TableHead className="bg-[#EEF2F6]">
                <TableRow>
                  <TableCell> ID</TableCell>
                  <TableCell>Adı</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Kayıt Tarihi</TableCell>
                  <TableCell>Total Satış Sayısı</TableCell>
                  <TableCell>Bekleyen Ödeme</TableCell>
                  <TableCell>Eklediği Stok Sayısı</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.page?.map((user) => (
                  <SellerCard
                    user={user}
                    key={user._id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className="p-2 flex flex-1 justify-center">
          <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            count={Math.ceil(data?.totalSellers / 10)}
            page={searchQuery.page}
            onChange={(_, value) => {
              setSearchQuery({ ...searchQuery, page: value });
            }}
          />
        </div>
      </main>

      {showDrawer && selectedSeller && (
        <SalesDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          selectedSeller={selectedSeller}
        />
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="İşlem başarılı."
      />
    </div>
  );
};

export default Sellers;
