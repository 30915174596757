import React, { useEffect, useState } from 'react'
import { ChromePicker } from 'react-color'
import { useQuery } from 'react-query';
import * as Queries from '../../utils/queries'
import { GridCloseIcon } from '@mui/x-data-grid';
import toast from 'react-hot-toast';

const EditProductAttributes = ({ selectedAttribute, setSelectedAttribute, productGroup, handleUpdateProductGroup }) => {

    const [selectedTerms, setSelectedTerms] = useState([]);
    const [formVisible, setFormVisible] = useState(false);
    const [formValues, setFormValues] = useState({
        value: "",
        colorCode: ""
    })

    const { data: productattributetermsData, refetch: productattributetermsRefetch } = useQuery(
        ["listAllProductattributeterms", selectedAttribute?._id],
        () => Queries.listAllProductattributeterms(selectedAttribute?._id),
        {
            onError: (err) => {
                console.log("err", err)
            },
            placeholderData: null,
        }
    );

    const changeSelectedTerms = async () => {
        const list = [];
        for (let i = 0; i < productattributetermsData.length; i++) {
            const attribute = productGroup?.productattributeterms?.find(item => item.value == productattributetermsData[i]?._id);
            if (attribute) {
                list.push(attribute)
            }
        }
        setSelectedTerms(list);
    }

    const handleCreateProductattributeterms = async () => {
        if (selectedAttribute?.view === "COLOR_PICKER") {
            if (formValues.colorCode) {
                const response = await Queries.createTerm({
                    attribute: selectedAttribute?._id,
                    value: formValues.value,
                    colorCode: formValues.colorCode
                });
                if (response?._id) {
                    toast.success("Değer başarılı bir şekilde oluşturuldu");
                    setFormValues({
                        termType: { value: "", label: "", view: "" },
                        colorCode: "",
                        value: ""
                    })
                    setFormVisible(false)
                    productattributetermsRefetch();
                } else {
                    toast.error("Değer oluşturulurken bir hata meydana geldi. Lütfen tekrar deneyiniz")
                }
            } else {
                toast.error("Lütfen Renk Seçiniz")
            }
        } else {
            const response = await Queries.createTerm({
                attribute: selectedAttribute?._id,
                value: formValues.value
            });

            if (response?._id) {
                toast.success("Değer başarılı bir şekilde oluşturuldu");
                setFormValues({
                    termType: { value: "", label: "", view: "" },
                    colorCode: "",
                    value: ""
                })
                setFormVisible(false)
                productattributetermsRefetch();
            } else {
                toast.error("Değer oluşturulurken bir hata meydana geldi. Lütfen tekrar deneyiniz")
            }
        }
    }

    useEffect(() => {
        if (productGroup?.productattributeterms?.length !== 0) {
            changeSelectedTerms();
        }
    }, [productattributetermsData])

    return (
        <div className='flex flex-1 flex-col space-y-2 border px-2 py-3 rounded'>
            {!formVisible ?
                <>
                    <div className='flex flex-1 flex-row'>
                        <div className='flex flex-1 font-semibold'>
                            Değerler
                        </div>

                        <button onClick={() => setFormVisible(!formVisible)} className='text-sm textPurple'>
                            Değer Oluştur
                        </button>
                    </div>

                    <div className={`${!formVisible ? "" : "hidden"}`}>
                        {productattributetermsData?.map(item => {
                            return (
                                <>
                                    {selectedAttribute?.view === "COLOR_PICKER" && (
                                        <div onClick={() => {
                                            if (selectedTerms?.find(term => term.value === item._id)) {
                                                setSelectedTerms(selectedTerms.filter(term => term.value !== item._id))
                                            } else {
                                                setSelectedTerms([
                                                    ...selectedTerms,
                                                    { value: item._id, label: item.value, attribute: item.attribute, colorCode: item.colorCode }
                                                ])
                                            }
                                        }} key={item._id} className='w-[30px] h-[30px] flex items-center justify-center m-1 rounded cursor-pointer border float-left relative hoverMain' style={{ backgroundColor: item.colorCode }}>
                                            <p className="p-3 bg-slate-800 text-white absolute bottom-[50%] hoverMainText rounded-md shadow-sm">
                                                {item.value}
                                            </p>

                                            {(selectedTerms?.find(term => term.value === item._id)) ?
                                                <span>
                                                    <GridCloseIcon style={{ color: "#dedede" }} />
                                                </span> : <></>}
                                        </div>
                                    ) || selectedAttribute?.view === "LIST" && (
                                        <div onClick={() => {
                                            if (selectedTerms?.find(term => term.value === item._id)) {
                                                setSelectedTerms(selectedTerms.filter(term => term.value !== item._id))
                                            } else {
                                                setSelectedTerms([
                                                    ...selectedTerms,
                                                    { value: item._id, label: item.value, attribute: item.attribute }
                                                ])
                                            }
                                        }} key={item._id} className='flex flex-row space-x-2 items-center justify-center px-2 py-1 m-1 rounded cursor-pointer border float-left'>
                                            {(selectedTerms?.find(term => term.value === item._id)) ?
                                                <span>
                                                    <GridCloseIcon style={{ color: "#dedede" }} />
                                                </span> : <></>}

                                            <span className=''>
                                                {item.value}
                                            </span>
                                        </div>
                                    )}
                                </>
                            )
                        })}
                    </div>

                    <div className='flex flex-1 flex-row space-x-6 mb-3'>
                        <button onClick={() => setSelectedTerms(productattributetermsData.map(item => { return { value: item._id, label: item.value, attribute: item.attribute, colorCode: item.colorCode } }))} className='textPurple'>
                            Tümünü Seç
                        </button>

                        <button onClick={() => setSelectedTerms([])} className='textPurple'>
                            Hiçbirini Seçme
                        </button>
                    </div>

                    <div className='flex flex-1 flex-row space-x-6'>
                        <button onClick={() => {
                            const list = productGroup?.productattributeterms?.filter(item => item.attribute !== selectedAttribute._id);
                            handleUpdateProductGroup({
                                productattributeterms: [
                                    ...list,
                                    ...selectedTerms
                                ]
                            })
                        }} className='px-3 py-2 rounded buttonPurple'>
                            Kaydet
                        </button>

                        <button onClick={() => setSelectedAttribute(false)} className='px-3 py-2 rounded bg-gray-100 hover:bg-gray-200'>
                            Vazgeç
                        </button>
                    </div>
                </>
                :
                <div className='flex flex-1 flex-col items-center justify-center'>
                    <h6>Değer Oluştur</h6>

                    <div className='flex flex-col space-y-1'>
                        <div>Değer Başlığı</div>
                        <input
                            className='p-2 rounded flex flex-1 borderGray'
                            placeholder="Değer Başlığı"
                            value={formValues.value}
                            onChange={e => setFormValues({ ...formValues, value: e.target.value })}
                        />
                    </div>

                    {selectedAttribute?.view === "COLOR_PICKER" ?
                        <div className='flex flex-col space-y-1'>
                            <div>Renk</div>

                            <ChromePicker
                                color={formValues.colorCode || "#000"}
                                onChange={e => {
                                    setFormValues({ ...formValues, colorCode: e.hex });
                                }}
                            />
                        </div> : <></>}

                    <div className='flex flex-1 flex-row space-x-6 mt-3'>
                        <button
                            disabled={!formValues.value}
                            onClick={() => handleCreateProductattributeterms()}
                            className='w-max px-3 py-2 rounded buttonPurple disabled:bg-purple-100 disabled:text-purple-700 disabled:cursor-not-allowed'>
                            Oluştur
                        </button>

                        <button onClick={() => setFormVisible(false)} className='w-max px-3 py-2 rounded bg-gray-100 hover:bg-gray-200'>
                            Vazgeç
                        </button>
                    </div>
                </div>}
        </div>
    )
}

export default EditProductAttributes