import { Button, MenuItem, TextField, Box } from "@material-ui/core"
import React from "react"
import { Controller } from "react-hook-form"
import { DateField } from "@mui/x-date-pickers/DateField"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment from "moment"
import ImageGrid from "./ImageGrid"
import PhotoField from "./PhotoField"
import SliderField from "./SliderField"
import ColorPickerField from "./ColorPickerField"
import AutocompleteField from "./AutocompleteField"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css';

const DialogField = ({ item, control, disabled, register, watch, descriptionContent, setDescriptionContent }) => {
  const value = watch(item.id);
  const categoryValue = watch("category");
  const brandsValue = watch("brands");

  const handleContentChange = (value) => {
    setDescriptionContent(value);
  };

  if (item.type === "COLOR_PICKER") {
    return (
      <ColorPickerField
        item={item}
        control={control}
        id={item.id}
        value={value}
      />
    )
  }

  if (item.type === "IMAGE_GRID") {
    return (
      <div style={{ maxWidth: 500,  }}>
        <ImageGrid key={item.id} control={control} id={item.id} value={value} />
      </div>
    )
  }

  if (item.type === "IMAGE")
    return (
      <PhotoField
        item={item}
        control={control}
        value={value}
        disabled={disabled}
      />
    )

  if (item.type === "SLIDER") {
    return (
      <SliderField
        item={item}
        control={control}
        value={value}
        disabled={disabled}
      />
    )
  }

  if (item.type === "AUTOCOMPLETE") {
    return (
      <AutocompleteField
        item={item}
        value={value}
        disabled={disabled}
        control={control}
      />
    )
  }

  if (item.type === "SELECT") {
    return (
      <TextField
        select
        label={item.label}
        value={value}
        variant="outlined"
        size="small"
        disabled={disabled}
        fullWidth
        margin="normal"
        {...register(item.id)}
        SelectProps={
          item.multiple && {
            multiple: item.multiple,
            value: Array.isArray(value) ? value : [],
            MenuProps: {
              variant: "menu",
            },
            ...register(item.id),
          }
        }
      >
        {(item?.label === "Marka" && item?.options?.length !== 0) && (
          item?.options?.filter(item => item.category._id == categoryValue).map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))
        ) || (item?.label === "Modeller" && item?.options?.length !== 0) && (
          item?.options?.filter(item => item.brand && (item.brand._id == (brandsValue && brandsValue[0] || "aa"))).map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))
        ) || (item?.options?.length !== 0 &&
          item.options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))
          )}
      </TextField>
    )
  }

  if (item.type === "DATE") {
    return (
      <Controller
        key={item.id}
        name={item.id}
        control={control}
        
        render={({ field }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateField
                label={item.label}
                format="DD/MM/YYYY"
                variant="outlined"
                size="small"
                disabled={disabled}
                InputProps={{
                  readOnly: item.readOnly,
                  disabled: item.readOnly,
                }}
                fullWidth
                margin="normal"
                {...item.componentProps}
                {...field}
                value={moment(field.value)}
              />
            </LocalizationProvider>
          )
        }}
      />
    )
  }

  if (item.type === "PRODUCT_DESCRIPTION") {
    return (
      <>
        <ReactQuill
          key={item.id}
          id={item.id}
          value={descriptionContent}
          onChange={handleContentChange}
          modules={{
            toolbar: [
              ['link', 'bold', 'italic'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' },
              { 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'align': 'left' }, { 'align': 'center' }, { 'align': 'right' }],
              ['clean']
            ],
          }}
          formats={[
            'link', 'bold', 'italic',
            'list', 'bullet', 'indent',
            'align'  // Hizalama formatı ekleniyor
          ]}
          placeholder="Ürün Açıklaması"
          style={{
            borderRadius: '5px',
            marginBottom: '10px',
          }}
        />
      </>
    )
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        
      }}
    >
      <TextField
        key={item.id}
        label={item.label}
        defaultValue={item.defaultValue}
        value={value}
        variant="outlined"
        size="lg"
        disabled={disabled}
        InputProps={{
          readOnly: item.readOnly,
          disabled: item.readOnly,
        }}
        fullWidth
        margin={"normal"}
        {...item.componentProps}
        {...register(item.id)}
      />

      {item.onDeletePress && (
        <Button
          variant="contained"
          style={{ backgroundColor: "#000000", color: "white", marginLeft:"20px" }}
          size="small"
          onClick={item.onDeletePress}
        >
          Sil
        </Button>
      )}
    </Box>
  )
}

export default DialogField
