import {
    TableCell,
    TableRow,
} from "@material-ui/core"
import { useStyles } from "../styles"
import * as Queries from '../../utils/queries'
import { useEffect, useState } from "react"

const OrderDrawerCard = ({ basketItem }) => {
    const classes = useStyles()
    const [brandData, setBrandData] = useState({});
    const [sizeData, setSizeData] = useState({});

    const getBrandData = async () => {
        const response = await Queries.getBrandDetail(basketItem.advert.productGroup.brands[0]);
        console.log(response)
        setBrandData(response)
    }

    const getSizeData = async () => {
        const response = await Queries.getSize(basketItem.advert.size);
        setSizeData(response)
    }

    useEffect(() => {
        getBrandData();
        getSizeData();
    }, [basketItem])


    return (
        <TableRow>
            <TableCell component="th" scope="row">
                <img
                    src={basketItem.advert.productGroup.photos[0]}
                    alt="Fotoğraf"
                    className={classes.userPhoto}
                />
            </TableCell>
            <TableCell>{brandData.title}</TableCell>
            <TableCell>{basketItem.advert.productGroup.title}</TableCell>
            <TableCell>{basketItem.advert.price} TL</TableCell>
            <TableCell>{sizeData.value}</TableCell>
            <TableCell>{basketItem.quantity}</TableCell>
        </TableRow>
    )
}

export default OrderDrawerCard