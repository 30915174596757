import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import Select from 'react-select'

const AddVariantSelectBox = ({ id, productGroup, productattributesData, newVariantTerms, setNewVariantTerms, newVariantClicked, setNewVariantClicked }) => {

    const [productattribute, setProductattribute] = useState(null);
    const [productattributeterms, setProductattributeterms] = useState({ value: [] });
    const [selectedTerm, setSelectedTerm] = useState(null)

    const getProductattribute = async () => {
        const data = productattributesData?.find(item => item?._id === id);
        setProductattribute(data)
    }

    useEffect(() => {
        if (productGroup?.productattributeterms) {
            const terms = productGroup?.productattributeterms?.filter(item => item.attribute === id);
            setProductattributeterms(terms)
        }
    }, [productGroup])

    useEffect(() => {
        getProductattribute();
    }, [productattributesData]);

    /*useEffect(() => {
        if (newVariantClicked) {
            if (selectedTerm) {
                const item = newVariantTerms?.find(item => item.value === selectedTerm.value);
                if (!item) {
                    setNewVariantTerms([...newVariantTerms, selectedTerm]);
                }
            } else {
                toast.error(`Lütfen ${productattribute.title} Seçiniz`);
                setNewVariantClicked(false)
            }
        }
    }, [newVariantClicked])*/

    if (productattribute && productattributeterms && productattributeterms?.length !== 0) {
        return (
            <div className='flex flex-1 relative'>
                <Select
                    className='w-full'
                    isSearchable
                    placeholder={`${productattribute.title} Seçiniz`}
                    value={(productattribute.title === "Beden" && newVariantTerms?.size) || (productattribute.title === "Renk" && newVariantTerms?.color) || selectedTerm}
                    onChange={(value) => {
                        setNewVariantTerms(
                            productattribute.title === "Beden" && { ...newVariantTerms, size: value }
                            || productattribute.view === "COLOR_PICKER" && { ...newVariantTerms, color: value }
                            || { ...newVariantTerms, otherTerms: newVariantTerms?.otherTerms?.length !== 0 ? [...newVariantTerms?.otherTerms, value] : [value] }
                        );
                        setSelectedTerm(value)
                    }}
                    options={productattributeterms}
                />
            </div>
        )
    }
}

export default AddVariantSelectBox