import { ImageList } from "@material-ui/core"
import React from "react"
import { useFieldArray } from "react-hook-form"
import ImageGridItem from "./ImageGridItem"

const ImageGrid = ({ control, id, value }) => {
  const { append, prepend, remove, swap, move, insert, update } = useFieldArray(
    {
      control,
      name: id,
    }
  )

  return (
    <ImageList cols={3}>
      {value.map((item, itemIndx) => (
        <ImageGridItem
          item={item}
          onPrevPress={() => {
            if (itemIndx > 0) {
              swap(itemIndx, itemIndx - 1)
            }
          }}
          onNextPress={() => {
            if (itemIndx < 5) {
              swap(itemIndx, itemIndx + 1)
            }
          }}
          onUpload={(uploadedUrl) => {
            update(itemIndx, uploadedUrl)
          }}
        />
      ))}
    </ImageList>
  )
}

export default ImageGrid
