import React, { useState } from "react"
import {
  Toolbar,
  Typography,
  TextField,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@material-ui/core"
import { Info as InfoIcon, Add as AddIcon } from "@material-ui/icons"
import { useQuery } from "react-query"
import SliderDialog from "./SliderDialog"
import { useStyles } from "../styles"
import * as Queries from "../../utils/queries"
import CustomDrawer from "../../components/CustomDrawer"

const Sliders = () => {
  const classes = useStyles()
  const [openSliderDialog, setOpenSliderDialog] = useState(false)
  const [selectedSlider, setSelectedSlider] = useState()
  const [message, setMessage] = useState(false)

  const [searchQuery, setSearchQuery] = useState("")
  const [page, setPage] = useState(1)

  const { data: sliders } = useQuery(
    ["LIST_SLIDERS", searchQuery, page],
    () => {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0

      return Queries.listSliders(
        new URLSearchParams({
          filterName: searchQuery,
          page,
        }).toString()
      )
    },
    {
      placeholderData: [],
      keepPreviousData: false,
    }
  )

  const handleSearch = (event) => {
    setSearchQuery(event.target.value)
  }

  return (
    <div className={classes.root}>
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />

        <div className={classes.searchContainer}>
          <Typography variant="h6">Kapak Fotoğrafları</Typography>
          <div style={{ flex: 1 }} />
          <Button
            variant="contained"
                        style={{backgroundColor: "#000000", color: "white"}}

            startIcon={<AddIcon />}
            onClick={() => {
              if (false) {
                return alert(
                  "Bu işlemi yapabilmek için gerekli izinlere sahip değilsiniz."
                )
              }

              setSelectedSlider(undefined)
              setOpenSliderDialog(true)
            }}
          >
            Kapak Fotoğrafı Oluştur
          </Button>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fotoğraf</TableCell>
                <TableCell align="right">Detaylar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sliders.map((slider) => (
                <TableRow key={slider.id}>
                  <TableCell component="th" scope="row">
                    <img src={slider.url} className={classes.sliderImg} />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => {
                        if (false) {
                          return alert(
                            "Bu işlemi yapabilmek için gerekli izinlere sahip değilsiniz."
                          )
                        }

                        setSelectedSlider(slider)
                        setOpenSliderDialog(true)
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </main>

      {openSliderDialog && (
        <SliderDialog
          openSliderDialog={openSliderDialog}
          setOpenSliderDialog={setOpenSliderDialog}
          initialData={selectedSlider}
        />
      )}

      <Snackbar
        open={message}
        autoHideDuration={3000}
        onClose={() => setMessage(undefined)}
        message={message}
      />
    </div>
  )
}

export default Sliders
