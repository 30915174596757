import { Pagination } from '@mui/material'
import React from 'react'
import SaleCard from './SaleCard'

const Sales = ({ salesData, salesFilterQuery, setSalesFilterQuery }) => {
    return (
        <div className='flex flex-col space-y-3 bg-white rounded border-1 border-gray-100 py-3'>
            <div className='px-3 flex flex-row'>
                <div className='flex flex-1'>
                    <span className='pb-1 border-b borderPurple textPurple'>
                        {" "}
                    </span>
                </div>

                {(salesData?.totalCount !== 0) ?
                    <div className='flex flex-1 justify-end text-gray-600 text-sm'>
                        {`${salesFilterQuery.page * salesFilterQuery.limit - (salesFilterQuery.limit - 1)} - ${(salesData?.totalCount > salesFilterQuery.limit ? (
                            salesFilterQuery.page !== salesData?.totalPage ? salesFilterQuery.page * salesFilterQuery.limit
                                : salesData?.totalCount
                        ) : salesData?.totalCount)
                            } / ${salesData?.totalCount} adet`}
                    </div> : <></>}
            </div>

            {(salesData?.totalCount !== 0) ?
                <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead class="text-xs text-gray-700 bg-gray-100">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                ID
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Ürün
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Brüt Fiyat
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Stoka Kalan
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Satıcıya Kalan
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesData?.sales?.map((sale) => (
                            <SaleCard
                                key={sale._id}
                                sale={sale}
                            />
                        ))}
                    </tbody>
                </table>
                :
                <div className='w-max p-2 mx-3 rounded bg-blue-100 text-blue-700'>
                    Satıcıya ait henüz bir satış yok
                </div>}

            {(salesData?.totalCount !== 0 && salesData?.totalPage > 1) ?
                <div className='flex flex-1 px-3 justify-center'>
                    <Pagination
                        count={salesData?.totalPage}
                        page={salesFilterQuery.page}
                        onChange={(_, value) => setSalesFilterQuery({ ...salesFilterQuery, page: value })}
                        variant='outlined'
                        color='primary'
                        shape='rounded'
                    />
                </div> : <></>}
        </div>
    )
}

export default Sales