import { ImageListItem } from "@material-ui/core"
import React from "react"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import PhotoIcon from "@material-ui/icons/Photo"
import { useMutation } from "react-query"
import * as Queries from "../utils/queries"
import CircularProgress from "@mui/material/CircularProgress"

const ImageGridItem = ({ item, onUpload, onPrevPress, onNextPress }) => {
  const uploadImageMutation = useMutation(Queries.uploadMedia, {
    onSuccess: (res) => {
      onUpload(res.secure_url)
      console.log("res", res)
    },
    onError: (err) => {
      console.log("err", err)
    },
  })

  const onFileChange = (e) => {
    uploadImageMutation.mutate(e.target.files[0])
  }

  return (
    <ImageListItem
      key={item}
      style={{
        width: "33.3333%",
        aspectRatio: 1,
        padding: 2,
      }}
    >
      <div
        style={{
          flex: 1,
          aspectRatio: 1,
          background: "#CDCDCD",
        }}
      >
        {uploadImageMutation.isLoading ? (
          <div
            style={{
              width: "100%",
              aspectRatio: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: 15,
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <img
            src={item.startsWith("http") ? item : null}
            srcSet={item.startsWith("http") ? item : null}
            alt={""}
            loading="lazy"
            style={{
              objectFit: "contain",
              width: "100%",
              aspectRatio: 1,
            }}
          />
        )}
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            padding: 5,
            display: uploadImageMutation.isLoading ? "none" : "flex",
            alignItems: "center",
            justifyContent: "space-around",
            background: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <ChevronLeftIcon
            htmlColor="#FFFFFF"
            style={{ cursor: "pointer" }}
            onClick={onPrevPress}
          />
          <label>
            <input type="file" onChange={onFileChange} />
            <PhotoIcon htmlColor="#FFFFFF" style={{ cursor: "pointer" }} />
          </label>
          <ChevronRightIcon
            htmlColor="#FFFFFF"
            style={{ cursor: "pointer" }}
            onClick={onNextPress}
          />
        </div>
      </div>
    </ImageListItem>
  )
}

export default ImageGridItem
