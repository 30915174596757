import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
} from "@material-ui/core"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import DialogField from "../../components/DialogField"
import { useMutation, useQuery, useQueryClient } from "react-query"
import * as Queries from "../../utils/queries"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import CustomDialogActions from "../../components/CustomDialogActions"
import toast from "react-hot-toast"



const productGroupSchema = yup.object({
  photos: yup.array().of(yup.string()).required(),
  title: yup.string().required(),
  price: yup.number().required(),
  subtitle: yup.string().required(),
  brands: yup.array().of(yup.string()).required(),
  category: yup.string().required(),
  gender: yup.string(),
  attributes: yup.array().of(yup.string()).required(),
})

const ProductGroupDialog = ({
  openProductGroupDialog,
  setOpenProductGroupDialog,
  initialData,
  setShowDrawer,
  setSelectedProductGroup
}) => {
  const [finalFields, setFinalFields] = React.useState([])
  const [message, setMessage] = React.useState()
  const [descriptionContent, setDescriptionContent] = React.useState(initialData ? (initialData.description && initialData.description || "") : "");
  const queryClient = useQueryClient();

  const getProductGroupFields = ({
    brands,
    categories,
    attributes,
    productModels,
  }) => [
      {
        label: "Ürün Fotoğrafları",
        id: "photos",
        type: "IMAGE_GRID",
      },
      {
        label: "Ürün Adı",
        id: "title",
      },
      {
        label: "Ürün Kısa Adı",
        id: "subtitle",
      },
      {
        label: "Fiyatı",
        id: "price",
      },
      {
        label: "Kategori",
        id: "category",
        type: "SELECT",
        options: categories.map((item) => ({ label: item.title, id: item._id })),
      },
      {
        label: "Marka",
        id: "brands",
        type: "SELECT",
        multiple: true,
        options: brands.map((item) => ({ category: item.category, label: item.title, id: item._id })),
      },
      {
        label: "Modeller",
        id: "models",
        type: "SELECT",
        multiple: true,
        options: productModels.map((item) => ({ brand: item.brand, label: item.title, id: item._id })),
      },
      {
        label: "Cinsiyet",
        id: "gender",
        type: "SELECT",
        options: [
          {
            label: "Unisex",
            id: "UNISEX",
          },
          {
            label: "Erkek",
            id: "MALE",
          },
          {
            label: "Kadın",
            id: "FEMALE",
          },
        ],
      },
      {
        label: "Ürün Özellikleri",
        id: "attributes",
        type: "SELECT",
        multiple: true,
        options: attributes.map((item) => ({ label: item.title, id: item._id })),
      },
      {
        label: "Ürün Açıklaması",
        id: "description",
        type: "PRODUCT_DESCRIPTION",
      },
    ]

  const { register, watch, control, handleSubmit } = useForm({
    resolver: yupResolver(productGroupSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: initialData
      ? {
        ...initialData,
        brands: initialData.brands.map((item) => item._id),
        models: initialData.models.map((item) => item._id),
        photos: Array.from({ length: 6 }).map(
          (_, itemIndex) => initialData.photos[itemIndex] ?? "NONE"
        ),
        ...(initialData?.metaTags || {}),
      }
      : {
        photos: Array.from({ length: 6 }).fill("NONE"),
      },
  })

  const updateGroupMutation = useMutation(
    ([groupId, dto]) => Queries.updateGroup(groupId, dto),
    {
      onSuccess: (res) => {
        console.log("res", res)
        setOpenProductGroupDialog(false)
        setMessage("Ürün güncellendi.")
        queryClient.invalidateQueries(["LIST_PRODUCT_GROUPS"])
      },
      onError: (err) => {
        console.log("err", err)
      },
    }
  )

  const createGroupMutation = useMutation(Queries.createGroup, {
    onSuccess: (res) => {
      console.log("res", res)
      if (res?._id) {
        toast.success("Ürün oluşturuldu. Lütfen Ürün İçin En az Bir Varyant Oluşturun");
        setOpenProductGroupDialog(false);
        setShowDrawer(true);
        setSelectedProductGroup(res);
      }
      else if (res?.message) {
        setMessage(res.message)
      }
      queryClient.invalidateQueries(["LIST_PRODUCT_GROUPS"])
    },
    onError: (err) => {
      console.log("err", err)
    },
  })

  const deleteGroupMutation = useMutation(Queries.deleteGroup, {
    onSuccess: (res) => {
      console.log("res", res)
      setOpenProductGroupDialog(false)
      setMessage("Ürün silindi.")
      queryClient.invalidateQueries(["LIST_PRODUCT_GROUPS"])
    },
    onError: (err) => {
      console.log("err", err)
    },
  })

  const { data: brandsData } = useQuery(
    ["LIST_BRANDS"],
    () => Queries.getBrands("page=1&limit=1000"),
    {
      onError: (err) => {
        console.log("err", err)
      },
      placeholderData: {
        page: [],
      },
    }
  )

  const { data: productModelsData } = useQuery(
    ["PRODUCT_MODELS_Q"],
    () => Queries.listModels("page=1&limit=1000"),
    {
      onError: (err) => {
        console.log("err", err)
      },
      placeholderData: {
        page: [],
      },
    }
  )

  const { data: categoriesData } = useQuery(
    ["LIST_CATEGORIES"],
    () => Queries.listCategories("page=1&limit=1000"),
    {
      onError: (err) => {
        console.log("err", err)
      },
      placeholderData: {
        page: [],
      },
    }
  )

  const getAttributesQuery = useQuery(
    ["LIST_ATTRIBUTES"],
    () => Queries.getAttributes("page=1&limit=1000"),
    {
      onError: (err) => {
        console.log("err", err)
      },
      placeholderData: {
        page: [],
      },
    }
  )

  React.useEffect(() => {
    if (
      !finalFields.length &&
      brandsData?.page?.length !== 0 &&
      categoriesData?.page?.length !== 0 &&
      getAttributesQuery.isSuccess &&
      productModelsData?.page?.length !== 0
    ) {
      setFinalFields([
        ...getProductGroupFields({
          brands: brandsData.page,
          categories: categoriesData.page,
          attributes: getAttributesQuery.data.page,
          productModels: productModelsData.page,
        }),
        ...Object.entries(initialData?.metaTags || {}).map(([k, v]) => ({
          label: `Meta Tag: ${k.replace("tag_", "")}`,
          id: k,
          onDeletePress: () => {
            setFinalFields((yep) => yep.filter((y) => y.id !== k))
          },
        })),
      ])
    }
  }, [
    brandsData,
    categoriesData,
    getAttributesQuery,
    productModelsData,
    initialData,
  ])

  const onValid = (data) => {
    const metaTags = finalFields.reduce((prev, cur) => {
      if (cur.id.startsWith("tag_")) {
        return {
          ...prev,
          [cur.id]: data[cur.id],
        }
      }

      return prev
    }, {})

    const dto = {
      ...data,
      photos: data.photos.filter((item) => item !== "NONE"),
      description: descriptionContent,
      metaTags,
    }

    if (initialData) {
      updateGroupMutation.mutate([initialData._id, dto])
    } else {
      createGroupMutation.mutate(dto)
    }
  }

  const onInvalid = (errors) => {
    const firstErr = Object.values(errors)[0]

    if (Array.isArray(firstErr)) {
      setMessage(firstErr[0].message)
    } else {
      setMessage(firstErr.message)
    }
  }

  return (
    <>
      <Dialog
        open={openProductGroupDialog}
        onClose={() => setOpenProductGroupDialog(false)}
        fullWidth
      >
        <DialogTitle>{"Ürün Detayı"}</DialogTitle>
        <DialogContent>
          {finalFields.map((item) => {
            return (
              <DialogField
                item={item}
                control={control}
                register={register}
                watch={watch}
                disabled={false}
                descriptionContent={descriptionContent}
                setDescriptionContent={setDescriptionContent}
              />
            )
          })}

          <Button
           style={{backgroundColor: "#000000", color: "white"}}

            fullWidth
            size="large"
            variant="contained"
            style={{
              marginTop: 20,
              marginBottom: 10,
            }}
            onClick={() => {
              const name = prompt("Meta tag ismini girin.")

              if (name) {
                setFinalFields((prev) => [
                  ...prev,
                  {
                    label: `Meta Tag: ${name}`,
                    id: `tag_${name}`,
                    onDeletePress: () => {
                      setFinalFields((yep) =>
                        yep.filter((y) => y.id !== `tag_${name}`)
                      )
                    },
                  },
                ])
              }
            }}
          >
            Meta Tag Ekle
          </Button>

          <CustomDialogActions
            isUpdate={initialData}
            onDelete={() => {
              const isConfirmed = window.confirm(
                `${initialData.title} isimli ürünü silmek istediğinize emin misiniz?`
              )

              if (isConfirmed) {
                deleteGroupMutation.mutate(initialData._id)
              }
            }}
            onConfirm={handleSubmit(onValid, onInvalid)}
          />
        </DialogContent>
      </Dialog>

      <Snackbar
        open={!!message}
        autoHideDuration={3000}
        onClose={() => setMessage(undefined)}
        message={message}
      />
    </>
  )
}

export default ProductGroupDialog
