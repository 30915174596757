import React, { useState } from "react";
import {
  makeStyles,
  Container,
  Typography,
  TextField,
  Button,
  Snackbar,
} from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSetAtom } from "jotai";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { userAtom } from "../../utils/atoms";
import * as Queries from "../../utils/queries";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  logo: {
    width: "300px",
    marginBottom: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "400px",
    marginBottom: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: theme.spacing(4),
    outline: "none",
    borderRadius: theme.spacing(1),
  },
  otpDescription: {
    textAlign: "center",
  },
  otpForm: {
    display: "flex",
    flexDirection: "column",
    width: "400px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  modalButton: {
    marginTop: theme.spacing(2),
  },
}));

const loginDto = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const Login = () => {
  const classes = useStyles();
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const { handleSubmit, register } = useForm({
    resolver: yupResolver(loginDto),
  });
  const setUser = useSetAtom(userAtom);

  const loginMutation = useMutation(Queries.login, {
    onSuccess: (res) => {
      if (!["ADMIN", "SELLER"].includes(res.user.role)) {
        return setSnackbarMessage("Lütfen giriş bilgilerinizi kontrol ediniz.");
      }

      localStorage.setItem("accessToken", res.accessToken);
      setUser(res.user);
    },
    onError: (err) => {
      setSnackbarMessage("Lütfen giriş bilgilerinizi kontrol ediniz.");
    },
  });

  const onSubmit = (data) => {
    loginMutation.mutate(data);
  };

  const onInvalid = (errors) => {
    setSnackbarMessage(Object.values(errors)[0].message);
  };

  return (
    <Container className={classes.container}>
      <img src={"/logo.png"} alt="Logo" className={classes.logo} />
      <Typography variant="h4" className={classes.title}>
        Giriş Yap
      </Typography>
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit, onInvalid)}
      >
        <TextField
          type="email"
          label="Email"
          size="medium"
          variant="outlined"
          className={classes.input}
          required
          {...register("email")}
        />
        <TextField
          type="password"
          label="Şifre"
          size="medium"
          variant="outlined"
          className={classes.input}
          required
          {...register("password")}
        />
        <Button
          type="submit"
          variant="contained"
                      style={{backgroundColor: "#000000", color: "white"}}

          className={classes.button}
        >
          Giriş Yap
        </Button>
      </form>

      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={3000}
        onClose={() => setSnackbarMessage(null)}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default Login;
