import React, { useEffect, useState } from "react";
import CustomDrawer from "../CustomDrawer";
import { useStyles } from "../../screens/styles";
import { FaCloudDownloadAlt } from "react-icons/fa";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Dropdown, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import * as Queries from "../../utils/queries";
import { useQuery } from "react-query";
import CustomLoading from "../CustomLoading";
import SutokProductCard from "./SutokProductCard";
import { Pagination } from "@mui/material";
import {
  Toolbar,
  Typography,
  TextField,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  MenuItem,
} from "@material-ui/core";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const SutokProducts = () => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState({
    productState: "allProducts",
    page: 1,
    limit: 10,
  });
  const [columnStates, setColumnStates] = useState({
    sku: true,
    state: true,
    stock: true,
  });

  const createHandleMenuClick = (menuItem) => {
    return () => {
      console.log(`Clicked on ${menuItem}`);
    };
  };

  const { data: sutokProductsData, isLoading: sutokProductsIsLoading } =
    useQuery(
      ["getSutokProducts", searchQuery],
      () => {
        return Queries.getSutokProducts(
          new URLSearchParams(searchQuery).toString()
        );
      },
      {
        placeholderData: {
          pageData: [],
          totalCount: 0,
          totalPage: 0
        },
        keepPreviousData: false,
      }
    );

  const { data: sutokProductsCountsData } = useQuery(
    ["getSutokProductsCounts"],
    () => {
      return Queries.getSutokProductsCounts();
    },
    {
      placeholderData: {
        totalCount: 0,
        noStockCount: 0,
        stockTotalCount: 0,
        lowStockCount: 0,
        soldOutStockCount: 0,
      },
      keepPreviousData: false,
    }
  );

  const handleChangePage = async (event, value) => {
    setSearchQuery({
      ...searchQuery,
      page: value,
    });
  };

  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(sutokProductsData?.pageData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `Sutokurunlersayfa${searchQuery.page}`);
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    saveAs(dataBlob, `Sutokurunlersayfa${searchQuery.page}.xlsx`);
  };

  useEffect(() => {
    setSearchQuery({
      ...searchQuery,
      page: 1,
    });
  }, [searchQuery.productState]);

  return (
    <div className={classes.root}>
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />

        <div className="flex justify-between items-center border rounded-md shadow-sm bg-white p-3">
          <FormControl className="w-[250px]">
            <InputLabel id="demo-simple-select-label">Ürün</InputLabel>
            <Select
              value={searchQuery.productState}
              onChange={(e) =>
                setSearchQuery({ ...searchQuery, productState: e.target.value })
              }
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Age"
            >
              <MenuItem
                value={"allProducts"}
                selected={searchQuery.productState === "allProducts"}
              >
                Tüm Ürünler
              </MenuItem>
              <MenuItem
                value={"haveStock"}
                selected={searchQuery.productState === "haveStock"}
              >
                Stokta Var
              </MenuItem>
              <MenuItem
                value={"noStock"}
                selected={searchQuery.productState === "noStock"}
              >
                Stokta Yok
              </MenuItem>
              <MenuItem
                value={"lowStock"}
                selected={searchQuery.productState === "lowStock"}
              >
                Düşük Stok
              </MenuItem>
              <MenuItem
                value={"soldOut"}
                selected={searchQuery.productState === "soldOut"}
              >
                Yok Satıldı
              </MenuItem>
            </Select>
          </FormControl>
          <div className="flex items-center gap-2">
            {sutokProductsData?.pageData?.length !== 0 ?
              <div onClick={() => exportToExcel()} className="flex gap-2 pt-2 cursor-pointer">
                <h6>İndirme</h6>{" "}
                <span>
                  <FaCloudDownloadAlt />
                </span>
              </div> : <></>}
            <div>
              <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  Sütunlar
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      setColumnStates({
                        ...columnStates,
                        sku: !columnStates.sku,
                      })
                    }
                  >
                    <Form.Check
                      checked={columnStates.sku}
                      type="switch"
                      label="Stok Kodu (SKU)"
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      setColumnStates({
                        ...columnStates,
                        state: !columnStates.state,
                      })
                    }
                  >
                    <Form.Check
                      checked={columnStates.state}
                      type="switch"
                      label="Durum"
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      setColumnStates({
                        ...columnStates,
                        stock: !columnStates.stock,
                      })
                    }
                  >
                    <Form.Check
                      checked={columnStates.stock}
                      type="switch"
                      label="Stok"
                    />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        {/* 
        
        //REVIEW:: FORM
        
        
        */}
        <div className="p-3 border rounded-md shadow-sm bg-white">
          <TableContainer component={Paper}>
            <Table>


              <TableHead className="bg-[#EEF2F6]">
                <TableRow>
                  <TableCell>Ürün / Varyasyon</TableCell>
                  {columnStates.sku && <TableCell>Stok Kodu(SKU)</TableCell>}
                  {columnStates.state && <TableCell>Durum</TableCell>}
                  {columnStates.stock && <TableCell>Stok</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody className="text-center">
                {sutokProductsData?.pageData &&
                  sutokProductsData?.pageData?.length !== 0 &&
                  sutokProductsData?.pageData.map((product) => {
                    return (
                      <SutokProductCard
                        product={product}
                        columnStates={columnStates}
                      />
                    );
                  })}
              </TableBody>

            </Table>
          </TableContainer>
        </div>
        <div className="p-3 flex gap-4 items-center justify-center mt-3 bg-white border shadow-sm rounded-md">
          <p>
            {sutokProductsCountsData?.totalCount}{" "}
            <span className="text-gray-500">Products</span>
          </p>
          <p>
            {sutokProductsCountsData?.noStockCount}{" "}
            <span className="text-gray-500">Stokta Yok</span>
          </p>
          <p>
            {sutokProductsCountsData?.lowStockCount}{" "}
            <span className="text-gray-500">Düşük Stok</span>
          </p>
          <p>
            {sutokProductsCountsData?.soldOutStockCount}{" "}
            <span className="text-gray-500">Yok Satıldı</span>
          </p>
          <p>
            {sutokProductsCountsData?.stockTotalCount}{" "}
            <span className="text-gray-500">Stokta</span>
          </p>
        </div>
        {sutokProductsData?.totalPage > 1 ?
          <div className="flex flex-1 justify-center">
            <Pagination
              page={searchQuery?.page}
              count={sutokProductsData?.totalPage}
              variant="outlined"
              shape="rounded"
              color="primary"
              onChange={handleChangePage}
            />
          </div> : <></>}
      </main>
    </div>
  );
};

export default SutokProducts;
