import { Dialog, DialogContent, DialogTitle } from "@material-ui/core"
import * as Queries from "../../utils/queries"
import * as yup from "yup"
import { useFormik } from "formik"
import toast from "react-hot-toast"
import { Form } from "react-bootstrap"
import { useEffect } from "react"

const discountCouponSchema = yup.object({
  couponCode: yup.string().required("Lütfen kupon kodu giriniz"),
  couponType: yup.string().required("Lütfen kupon türünü seçiniz"),
  discountRate: yup.number().required("Lütfen indirim giriniz").min(1, "İndirim en az 1(₺ veya %) olabilir")/*.max(100, "İndirim oranı en fazla 100 olabilir")*/,
});

const AddDiscountCouponDialog = ({ openDialog, setOpenDialog, discountCouponsRefetch }) => {
  const { handleSubmit, handleChange, handleReset, values, touched, errors, setValues } = useFormik({
    initialValues: {
      couponCode: "",
      couponType: "",
      discountRate: "",
    },
    onSubmit: async (val) => {
      const response = await Queries.createDiscountCoupon({
        ...values,
        couponCode: `SUT-${values.couponCode}`
      });
      if (response?._id) {
        toast.success("İndirim Kuponu başarılı bir şekilde oluşturuldu");
        handleReset();
        discountCouponsRefetch();
        setOpenDialog(false);
      } else {
        if (response?.isThereDiscountCoupon) {
          toast.error("Girmiş olduğunuz kupon koduna ait bir indirim kuponu hali hazırda bulunmakta. Lütfen başka bir kod deneyiniz");
        } else {
          toast.error("İndirim Kuponu oluşturulurken bir hata meydana geldi. Lürfen tekrar deneyiniz");
        }
      }

    },
    validationSchema: discountCouponSchema
  });
  useEffect(() => {
    console.log(values)
  }, [values])

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      fullWidth
    >
      <DialogTitle>{"İndirim Kuponu Oluştur"}</DialogTitle>
      <DialogContent>

        <form onSubmit={handleSubmit} className="flex flex-1 flex-col space-y-3">

          <div className="flex flex-1 flex-col space-y-1">
            <div className="font-semibold">Kupon Türü</div>

            <select
              className="border p-2 rounded"
              value={values.couponType}
              onChange={handleChange("couponType")}>
              <option disabled selected={values.couponType === ""} value={""}>Lütfen Kupon Türü Seçiniz</option>
              <option selected={values.couponType === "Yüzdelik"} value={"Yüzdelik"}>Yüzdelik</option>
              <option selected={values.couponType === "Tutar"} value={"Tutar"}>Tutar</option>
            </select>
            {errors.couponType && touched.couponType && (
              <div className="flex flex-1 p-2 rounded bg-red-100 text-red-700">
                {errors.couponType}
              </div>
            )}
          </div>

          <div className="flex flex-1 flex-col space-y-1">
            <div className="font-semibold">Kupon Kodu</div>

            <div className="flex flex-1 flex-row space-x-3 items-center">
              <div className="font-semibold text-sm">
                SUT
              </div>
              <div className="font-semibold text-sm">
                -
              </div>
              <Form.Control
                type="text"
                value={values.couponCode}
                onChange={handleChange("couponCode")}
              />
            </div>
            {errors.couponCode && touched.couponCode && (
              <div className="flex flex-1 p-2 rounded bg-red-100 text-red-700">
                {errors.couponCode}
              </div>
            )}
          </div>

          <div className="flex flex-1 flex-col space-y-1">
            <div className="font-semibold">
              {values.couponType === "Yüzdelik" ? "İndirim Oranı (%)" : "İndirim Tutarı (₺)"}
            </div>

            {values?.couponType === "Yüzdelik" ?
              <Form.Control
                type="number"
                value={values.discountRate}
                onChange={handleChange("discountRate")}
                min={1}
                max={100}
              /> : <Form.Control
                type="number"
                value={values.discountRate}
                onChange={handleChange("discountRate")}
              />}
            {errors.discountRate && touched.discountRate && (
              <div className="flex flex-1 p-2 rounded bg-red-100 text-red-700">
                {errors.discountRate}
              </div>
            )}
          </div>

          <div className="flex flex-1 flex-row space-x-3">
            <button type="submit" className="px-3 py-2 rounded buttonPurple">
              Oluştur
            </button>

            <button type="button" onClick={() => setOpenDialog(false)} className="px-3 py-2 rounded bg-gray-100 hover:bg-gray-200">
              Vazgeç
            </button>
          </div>
        </form>

      </DialogContent>
    </Dialog>
  )
}

export default AddDiscountCouponDialog