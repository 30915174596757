import React, { useEffect, useState } from 'react'
import { Accordion, Form } from 'react-bootstrap'
import { CgClose } from 'react-icons/cg'
import { useQuery } from 'react-query';
import * as Queries from '../../utils/queries'
import Select from 'react-select'
import toast from 'react-hot-toast';
import { ChromePicker } from "react-color"
import { TableCell, TableRow } from '@mui/material';
import { BiPencil } from 'react-icons/bi';
import { GridCloseIcon } from '@mui/x-data-grid';
import { TrashIcon } from '@radix-ui/react-icons';
import { FaTrash } from 'react-icons/fa6';

const AttributeCard = ({ attributeData, attributesRefetch, selectedAttribute, setSelectedAttribute }) => {

    const { data: productattributetermsData, refetch: productattributetermsRefetch } = useQuery(
        ["listAllProductattributeterms", attributeData?._id],
        () => Queries.listAllProductattributeterms(attributeData?._id),
        {
            onError: (err) => {
                console.log("err", err)
            },
            placeholderData: null,
        }
    );

    const handleDeleteAttribute = async () => {
        const response = await Queries.deleteAttribute(attributeData?._id);
        if (response?._id) {
            toast.success("Nitelik başarılı bir şekilde silindi");
        } else {
            toast.error("Nitelik güncellenirken bir hata oluştu. Lütfen tekrar deneyiniz");
        }
        attributesRefetch();
    }

    if (attributeData && productattributetermsData) {
        return (
            <TableRow>
                <TableCell>{attributeData?.title}</TableCell>

                <TableCell>
                    <div className="flex gap-2 flex-wrap">
                        {productattributetermsData?.map(item => {
                            return (
                                <>
                                    {attributeData?.view === "COLOR_PICKER" && (
                                        <div key={item._id} className='w-[30px] h-[30px] flex items-center justify-center rounded border float-left relative hoverMain' style={{ backgroundColor: item.colorCode }}>
                                            <p className="p-3 bg-slate-800 text-white absolute bottom-[50%] hoverMainText rounded-md shadow-sm">
                                                {item.value}
                                            </p>
                                        </div>
                                    ) || attributeData?.view === "LIST" && (
                                        <div key={item._id} className='flex flex-row space-x-2 items-center justify-center px-2 py-1 rounded border float-left'>
                                            <span className=''>
                                                {item.value}
                                            </span>
                                        </div>
                                    )}
                                </>
                            )
                        })}
                    </div>
                </TableCell>
                {<TableCell className='flex flex-row space-x-2' align="center">
                    <button
                        onClick={() => {
                            setSelectedAttribute(attributeData);
                            if (selectedAttribute._id === attributeData?._id) {
                                setSelectedAttribute(false);
                            }
                        }}
                    >
                        <BiPencil size={20} />
                    </button>

                    <button onClick={() => handleDeleteAttribute()}>
                        <FaTrash size={20} color='red' />
                    </button>
                </TableCell>
                }
            </TableRow>
        )
    }
}

export default AttributeCard