import { CheckIcon, ClockIcon, Cross2Icon } from "@radix-ui/react-icons";
import moment from "moment"
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { useNavigate } from "react-router-dom"

const SellerPaymentCard = ({ sellerPayment }) => {

  const navigate = useNavigate();

  return (
    <tr className="border-b hover:bg-gray-100 text-sm">
      <th scope="row" class="px-6 py-3 font-medium textPurple">
        {sellerPayment._id}
      </th>
      <td class="px-6 py-3 text-black">
        <div>{sellerPayment.amount}</div>
      </td>
      <td class="px-6 py-3 text-black">
        {sellerPayment?.status === "PENDING" && (
          <div className="p-2 border rounded-md bg-gray-100 text-black shadow-md flex items-center gap-3 w-[150px] select-none">
            <ClockIcon /> Beklemede
          </div>
        ) || sellerPayment?.status === "DECLINED" && (
          <div className="p-2 border rounded-md bg-red-600 text-white shadow-md flex items-center gap-3 w-[150px] select-none">
            <Cross2Icon />  Reddedildi
          </div>
        ) || sellerPayment?.status === "PAYMENTMADE" && (
          <div className="p-2 border rounded-md bg-green-600 text-white shadow-md flex items-center gap-3 w-[150px] select-none">
            <CheckIcon /> Ödeme Yapıldı
          </div>
        )}
      </td>
      <td class="px-6 py-3 text-black">
        {moment(sellerPayment.updatedAt).format("LL")}
      </td>
      <td class="px-6 py-3 text-black">
        <button onClick={() => navigate(`/paymentDetail?id=${sellerPayment?._id}`)}>
          <FaArrowUpRightFromSquare />
        </button>
      </td>
    </tr>
  )
}

export default SellerPaymentCard