import axios from 'axios';
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const OrderCard = ({ order }) => {

    const [orderStatus, setOrderStatus] = useState({
        id: "", label: ""
    });
    const navigate = useNavigate();

    const formatOrderStatus = async (apiTrackingUrl) => {
        const response = await axios.get(apiTrackingUrl);
        const trackingStatus = response?.data?.data?.trackingStatus;
        const trackingSubStatus = response?.data?.data?.trackingSubStatus;

        if (trackingStatus === "PRE_TRANSIT") {
            if (trackingSubStatus === "information_received") {
                setOrderStatus({ id: "information_received", label: "Kargo sistemde oluşturuldu", type: "PRE_TRANSIT" })
            }
        } else if (trackingStatus === "TRANSIT") {
            if (trackingSubStatus === "package_accepted") {
                setOrderStatus({ id: "package_accepted", label: "Kargo, Gönderici şube tarafından teslim alındı.", type: "TRANSIT" })
            } else if (trackingSubStatus === "package_departed") {
                setOrderStatus({ id: "package_departed", label: "Kargo, aktarma merkezinden ayrıldı", type: "TRANSIT" })
            } else if (trackingSubStatus === "package_processing") {
                setOrderStatus({ id: "package_processing", label: "Kargo aktarma merkezinde", type: "TRANSIT" })
            } else if (trackingSubStatus === "delivery_scheduled") {
                setOrderStatus({ id: "delivery_scheduled", label: "Kargo alıcı şubede", type: "TRANSIT" })
            } else if (trackingSubStatus === "out_for_delivery") {
                setOrderStatus({ id: "out_for_delivery", label: "Kargo dağıtımda", type: "TRANSIT" })
            } else if (trackingSubStatus === "package_damaged") {
                setOrderStatus({ id: "package_damaged", label: "Paket hasarlı", type: "TRANSIT" })
            } else if (trackingSubStatus === "package_forwarded_to_another_carrier") {
                setOrderStatus({ id: "package_forwarded_to_another_carrier", label: "Kargo aracı firmaya verildi", type: "TRANSIT" })
            } else if (trackingSubStatus === "delivery_rescheduled") {
                setOrderStatus({ id: "delivery_rescheduled", label: "Kargo dağıtım zamanı değişti (yanlış şube vs)", type: "TRANSIT" })
            }
        } else if (trackingStatus === "DELIVERED") {
            if (trackingSubStatus === "delivered") {
                setOrderStatus({ id: "delivered", label: "Kargo alıcıya teslim edildi", type: "DELIVERED" })
            }
        } else if (trackingStatus === "FAILURE") {
            if (trackingSubStatus === "package_lost") {
                setOrderStatus({ id: "package_lost", label: "Paket kayboldu", type: "FAILURE" })
            } else if (trackingSubStatus === "package_undeliverable") {
                setOrderStatus({ id: "package_undeliverable", label: "Kargo dağıtılamıyor", type: "FAILURE" })
            }
        } else if (trackingStatus === "RETURNED") {
            if (trackingSubStatus === "return_to_sender") {
                setOrderStatus({ id: "return_to_sender", label: "Kargo iade edildi", type: "RETURNED" })
            }
        } else if (trackingStatus === "UNKNOWN") {
            if (trackingSubStatus === "other") {
                setOrderStatus({ id: "other", label: "Bilinmeyen Durum", type: "UNKNOWN" })
            }
        }
    }

    useEffect(() => {
        if (order.apiTrackingUrl) {
            formatOrderStatus(order.apiTrackingUrl)
        }
    }, [order])

    return (
        <tr onClick={() => {
            navigate(`/orderDetail?id=${order._id}`);
        }} className="border-b hover:bg-gray-100 cursor-pointer">
            <th scope="row" class="px-6 py-3 font-medium textPurple">
                {order._id.toUpperCase()}
            </th>
            <td class="px-6 py-3 text-black">
                <div>₺ {order.totalAmount}</div>
                <div>{order.basket.length} ürün</div>
            </td>
            <td class="px-6 py-3 ">
                <div className='text-black'>{moment(order.createdAt).format("LL")}</div>
                <div>{`${(new Date(order.createdAt)).getHours() < 10 ? "0" + (new Date(order.createdAt)).getHours() : (new Date(order.createdAt)).getHours()}:${moment(order.createdAt).format("mm")}`}</div>
            </td>
            <td class="px-6 py-3">
                <div className={`w-max p-1 rounded text-center ${orderStatus?.id && (
                    order.type === "FAILURE" && "bg-red-100 text-red-600 border border-red-500"
                    || order.type === "DELIVERED" && "bg-green-100 text-green-600 border border-green-500"
                    || "bg-gray-100 text-black border"
                ) || "bg-gray-100 text-black border"}`}>
                    {orderStatus?.label || "Siparişiniz Alındı"}
                </div>
            </td>
        </tr>
    )
}

export default OrderCard