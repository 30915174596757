import React, { useEffect, useRef, useState } from 'react'
import CustomDrawer from "../../components/CustomDrawer"
import { useStyles } from '../styles'
import { Toolbar } from '@material-ui/core';
import * as yup from "yup"
import { useQuery } from 'react-query';
import toast, { Toaster } from 'react-hot-toast';
import * as Queries from '../../utils/queries'
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Form, InputGroup } from "react-bootstrap";
import Select from 'react-select'
import { ImFolderPlus } from 'react-icons/im';
import { FaRegTrashCan } from 'react-icons/fa6';
import ReactQuill from 'react-quill';
import { useAtom } from 'jotai';
import { userAtom } from '../../utils/atoms';

const productGroupSchema = yup.object({
    photos: yup.array().of(yup.string()).required("Lütfen resim seçiniz"),
    title: yup.string().required("Lütfen ürün adını giriniz"),
    price: yup.number().required("Lütfen fiyat giriniz"),
    season: yup.string().required("Lütfen sezon giriniz"),
    subtitle: yup.string(),
    brands: yup.array().of(yup.object()).required("Lütfen marka seçiniz"),
    models: yup.array().of(yup.object()).required("Lütfen model seçiniz"),
    category: yup.object().required("Lütfen kategori seçiniz"),
    gender: yup.object().required("Lütfen cinsiyet seçiniz"),
    attributes: yup.array().of(yup.object()).required("Lütfen nitelik seçiniz"),
    description: yup.string().required("Lütfen açıklama giriniz"),
    sku: yup.string(),
    slug: yup.string(),
    pageTitle: yup.string(),
    pageDescription: yup.string()
})

const AddProduct = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [clicked, setClicked] = useState(false);
    const [user, setUser] = useAtom(userAtom);

    const { handleSubmit, handleReset, values, errors, setValues } = useFormik({
        initialValues: {
            photos: [],
            title: "",
            description: "",
            price: "",
            season: "",
            subtitle: "",
            brands: [],
            models: [],
            category: "",
            gender: "",
            attributes: [],
            sku: "",
            slug: "",
            pageTitle: "",
            pageDecription: "",
        },
        onSubmit: async (val) => {
            if (val?.photos?.length !== 0 && val?.brands?.length !== 0
                && val?.models?.length !== 0 && val?.attributes?.length !== 0
                && val?.category?.value && val?.gender?.value
            ) {
                const data = {
                    ...val,
                    attributes: val.attributes.map(item => { return item.value }),
                    brands: val.brands.map(item => { return item.value }),
                    models: val.models.map(item => { return item.value }),
                    gender: val.gender.value,
                    category: val.category.value,
                }

                if (user?.role === "ADMIN") {
                    const response = await Queries.createGroup(data);
                    if (response?._id) {
                        toast.success("Ürün başarılı bir şekilde oluşturuldu. Lütfen Ürün İçin Varyant Oluşturun");
                        handleReset();
                        setTimeout(() => {
                            navigate(`/product/detail?id=${response?._id}`)
                        }, 1000);
                    } else {
                        toast.error("Ürün oluşturulurken bir hata oluştu. Lütfen tekrar deneyiniz")
                    }
                } else {
                    const response = await Queries.createProductsPendingApproval({
                        advert: null,
                        productGroup: data,
                        stock: null,
                        price: null,
                        type: "productGroup",
                    });

                    if (response?._id) {
                        toast.success("Ürün ekleme isteğiniz oluşturuldu.");
                        handleReset();
                        setTimeout(() => {
                            navigate(`/products`)
                        }, 1000);
                    } else {
                        toast.error("Ürün ekleme isteğiniz oluşturulurken bir hata oluştu. Lütfen tekrar deneyiniz")
                    }
                }
            }
        },
        validationSchema: productGroupSchema
    })

    const scrollToX = (id) => {
        const niteliklerElement = document.getElementById(id);
        if (niteliklerElement) {
            niteliklerElement.classList.add("scrollBelirt");
            niteliklerElement.classList.remove("border");
            niteliklerElement.classList.remove("shadow-sm");
            niteliklerElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });

            setTimeout(() => {
                window.scrollBy(0, -67);
                niteliklerElement.classList.remove("scrollBelirt");
                niteliklerElement.classList.add("border");
                niteliklerElement.classList.add("shadow-sm");
            }, 650);
        }
    };

    const genders = [
        {
            value: "UNISEX",
            label: "Unisex",
        },
        {
            value: "MALE",
            label: "Erkek",
        },
        {
            value: "FEMALE",
            label: "Kadın",
        },
    ];

    const { data: categoriesData } = useQuery(
        ["LIST_CATEGORIES"],
        () => Queries.listCategories("page=1&limit=1000"),
        {
            onError: (err) => {
                console.log("err", err);
            },
            placeholderData: {
                page: [],
            },
        }
    );

    const { data: brandsData } = useQuery(
        ["LIST_BRANDS"],
        () => Queries.getBrands("page=1&limit=1000"),
        {
            onError: (err) => {
                console.log("err", err);
            },
            placeholderData: {
                page: [],
            },
        }
    );

    const { data: productModelsData } = useQuery(
        ["PRODUCT_MODELS_Q"],
        () => Queries.listModels("page=1&limit=1000"),
        {
            onError: (err) => {
                console.log("err", err);
            },
            placeholderData: {
                page: [],
            },
        }
    );

    const { data: productattributesData, refetch: productattributesRefetch } =
        useQuery(
            ["listAllProductattributes"],
            () => Queries.listAllProductattributes(),
            {
                onError: (err) => {
                    console.log("err", err);
                },
                placeholderData: null,
            }
        );

    const handleImagesUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const response = await Queries.uploadMedia(file);
            setValues({
                ...values,
                photos: [...values?.photos, response?.secure_url]
            })
        } else {
            toast.error('Fotoğraf Seçilirken Bir Hata Oluştu. Lütfen Tekrar Deneyiniz');
            e.target.value = null;
        }
    };

    const handleImageInput = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        console.log(errors)
    }, [errors])


    return (
        <div className="flex">
            <CustomDrawer />
            <Toaster />
            <div className={classes.content}>
                <Toolbar />

                <div className="flex flex-col gap-3">
                    <div className="flex items-center gap-3 bg-white shadow-sm border rounded-md p-3">
                        <button
                            className=" border-b border-purple-800 text-purple-800"
                            onClick={() => scrollToX("temelBilgi")}
                        >
                            Temel Bilgi
                        </button>
                        <button
                            className="text-gray-600"
                            onClick={() => scrollToX("urunDetay")}
                        >
                            Ürün Detayı
                        </button>
                        <button
                            className="text-gray-600"
                            onClick={() => scrollToX("kategori")}
                        >
                            Kategori
                        </button>
                        <button
                            className="text-gray-600 no-underline"
                            onClick={() => scrollToX("nitelik")}
                        >
                            Nitelik
                        </button>
                        <button
                            className="text-gray-600 no-underline"
                            onClick={() => scrollToX("seo")}
                        >
                            Seo
                        </button>
                    </div>

                    <div
                        className="border shadow-sm rounded-md p-3 bg-white flex flex-col gap-4"
                        id="temelBilgi"
                    >
                        <h6>Temel Bilgi</h6>
                        <div className='flex flex-1 flex-col space-y-1'>
                            <p>Ürün Adı</p>
                            <Form.Control
                                type="text"
                                placeholder="Ürün Adı"
                                value={values?.title}
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        title: e.target.value,
                                    })
                                }
                            />
                            {errors.title && clicked && (
                                <div className='p-2 rounded bg-red-100 text-red-700'>
                                    {errors.title}
                                </div>
                            )}
                        </div>

                        <div className='flex flex-1 flex-col space-y-1'>
                            <p>Ürün Alt Adı</p>
                            <Form.Control
                                type="text"
                                placeholder="Ürün Alt Adı"
                                value={values?.subtitle}
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        subtitle: e.target.value,
                                    })
                                }
                            />
                            {errors.subtitle && clicked && (
                                <div className='p-2 rounded bg-red-100 text-red-700'>
                                    {errors.subtitle}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Ürün Detay */}
                    <div
                        className="bg-white p-3 shadow-sm border rounded-md"
                        id="urunDetay"
                    >
                        <div className=" flex justify-between items-center">
                            <h6>Ürün Detay</h6>
                        </div>
                        <Form.Group className="flex flex-1 flex-col gap-3">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-3">
                                <div className='flex flex-1 flex-col space-y-1'>
                                    <Form.Label>Marka</Form.Label>
                                    {brandsData?.page?.length !== 0 && (
                                        <Select
                                            isMulti
                                            isSearchable
                                            value={values?.brands}
                                            onChange={(e) =>
                                                setValues({ ...values, brands: e })
                                            }
                                            options={brandsData?.page?.map((brand) => {
                                                return { value: brand._id, label: brand?.title };
                                            })}
                                            placeholder="Marka Seçiniz"
                                        />
                                    )}
                                    {values.brands.length === 0 && clicked && (
                                        <div className='p-2 rounded bg-red-100 text-red-700'>
                                            Lütfen Marka Seçiniz
                                        </div>
                                    )}
                                </div>
                                <div className='flex flex-1 flex-col space-y-1'>
                                    <Form.Label>SKU Numarası</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="SKU Numarası"
                                        value={values?.sku}
                                        onChange={(e) =>
                                            setValues({
                                                ...values,
                                                sku: e.target.value,
                                            })
                                        }
                                    />
                                    {errors.sku && clicked && (
                                        <div className='p-2 rounded bg-red-100 text-red-700'>
                                            Lütfen SKU Numarası Giriniz
                                        </div>
                                    )}
                                </div>
                                <div className='flex flex-1 flex-col space-y-1'>
                                    <Form.Label>Model</Form.Label>
                                    <Select
                                        isMulti
                                        isSearchable
                                        value={values?.models}
                                        onChange={(e) =>
                                            setValues({ ...values, models: e })
                                        }
                                        options={productModelsData?.page?.map((model) => {
                                            return { value: model._id, label: model?.title };
                                        })}
                                        placeholder="Model Seçiniz"
                                    />
                                    {values.models.length === 0 && clicked && (
                                        <div className='p-2 rounded bg-red-100 text-red-700'>
                                            Lütfen Model Seçiniz
                                        </div>
                                    )}
                                </div>
                                <div className='flex flex-1 flex-col space-y-1'>
                                    <Form.Label>Cinsiyet</Form.Label>
                                    <Select
                                        placeholder="Cinsiyet Seçiniz"
                                        value={values?.gender}
                                        onChange={(e) =>
                                            setValues({ ...values, gender: e })
                                        }
                                        options={genders}
                                    />
                                    {!values?.gender && clicked && (
                                        <div className='p-2 rounded bg-red-100 text-red-700'>
                                            Lütfen Cinsiyet Seçiniz
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                                <div className='flex flex-1 flex-col space-y-1'>
                                    <Form.Label>Fiyat</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Fiyat"
                                        value={values?.price}
                                        onChange={(e) =>
                                            setValues({
                                                ...values,
                                                price: e.target.value,
                                            })
                                        }
                                    />
                                    {errors.price && clicked && (
                                        <div className='p-2 rounded bg-red-100 text-red-700'>
                                            {errors.price}
                                        </div>
                                    )}
                                </div>

                                <div className='flex flex-1 flex-col space-y-1'>
                                    <Form.Label>Sezon</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Sezon"
                                        value={values?.season}
                                        onChange={(e) =>
                                            setValues({
                                                ...values,
                                                season: e.target.value,
                                            })
                                        }
                                    />
                                    {errors.season && clicked && (
                                        <div className='p-2 rounded bg-red-100 text-red-700'>
                                            {errors.season}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Form.Group>
                    </div>

                    {/* Görseller */}
                    <div className="p-3 border shadow-sm rounded-md bg-white">
                        <div className="flex justify-between">
                            <p>Ürün Görseli</p>
                            <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                onChange={handleImagesUpload}
                            />
                            <button disabled={values?.photos?.length === 10} onClick={handleImageInput} className="textPurple flex items-center gap-2">
                                <ImFolderPlus />
                                Daha Fazla
                            </button>
                        </div>
                        {values.photos.length === 0 && clicked && (
                            <div className='p-2 rounded bg-red-100 text-red-700'>
                                Lütfen Resim Seçiniz
                            </div>
                        )}
                        <div className="grid grid-cols-4 justify-center items-center gap-3">
                            {values?.photos?.map((photo, index) => {
                                return (
                                    <div key={index} className="relative trashMain">
                                        <img
                                            src={photo}
                                            className="w-full h-[250px]  object-cover border"
                                            style={{ borderRadius: "7px" }}
                                            alt=""
                                        />
                                        <div>
                                            <button onClick={() => setValues({ ...values, photos: values?.photos?.filter(item => item !== photo) })} className="absolute top-4 right-3 p-1  trashDiv">
                                                <FaRegTrashCan color="red" />{" "}
                                            </button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    {/* Kategori */}
                    <div
                        className="bg-white p-3 shadow-sm border rounded-md flex flex-col gap-3"
                        id="kategori"
                    >
                        <div className='flex flex-1 flex-col space-y-1'>
                            <div className=" flex justify-between items-center">
                                <h6>Kategori</h6>
                            </div>

                            {categoriesData?.page?.length !== 0 && (
                                <Select
                                    isSearchable
                                    value={values?.category}
                                    onChange={(e) =>
                                        setValues({ ...values, category: e })
                                    }
                                    options={categoriesData?.page?.map((category) => {
                                        return { value: category._id, label: category?.title };
                                    })}
                                    placeholder="Kategori Seçiniz"
                                />
                            )}
                            {!values?.category && clicked && (
                                <div className='p-2 rounded bg-red-100 text-red-700'>
                                    Lütfen Kategori Seçiniz
                                </div>
                            )}
                        </div>

                        <div className='flex flex-1 flex-col space-y-1'>
                            <h6>Açıklama</h6>
                            <ReactQuill
                                theme="snow"
                                value={values?.description}
                                onChange={(value) =>
                                    setValues({ ...values, description: value })
                                }
                            />
                            <span className="h-[50px]"></span>
                            {errors.description && clicked && (
                                <div className='p-2 rounded bg-red-100 text-red-700'>
                                    {errors.description}
                                </div>
                            )}
                        </div>


                    </div>

                    {/* Nitelik */}
                    <div id='nitelik' className="flex flex-1 flex-col space-y-3 border border-gray-200  bg-white p-3  shadow-sm rounded-md">
                        <h6>Nitelikler</h6>

                        {productattributesData?.length !== 0 && (
                            <Select
                                isMulti
                                isSearchable
                                value={values?.attributes}
                                onChange={(value) =>
                                    setValues({ ...values, attributes: value })
                                }
                                options={productattributesData?.map((attribute) => {
                                    return { value: attribute._id, label: attribute?.title };
                                })}
                                placeholder="Nitelik Seçiniz"
                            />
                        )}
                        {values.attributes.length === 0 && clicked && (
                            <div className='p-2 rounded bg-red-100 text-red-700'>
                                Lütfen Nitelik Seçiniz
                            </div>
                        )}
                    </div>

                    {/* Arama Motoru */}
                    <div id='seo' className="grid grid-cols-2 gap-3 border border-gray-200  bg-white p-3  shadow-sm rounded-md">
                        <div>
                            <h5 className="">Arama Motoru Optimizasyonu (SEO)</h5>
                            <InputGroup size="md">
                                <InputGroup.Text id="inputGroup-sizing-lg">/</InputGroup.Text>
                                <Form.Control
                                    aria-label="Large"
                                    aria-describedby="inputGroup-sizing-sm"
                                    value={values?.slug}
                                    onChange={(e) =>
                                        setValues({
                                            ...values,
                                            slug: e.target.value,
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.slug && clicked && (
                                <div className='mt-1 p-2 rounded bg-red-100 text-red-700'>
                                    {errors.slug}
                                </div>
                            )}
                            <Form.Group className="mt-3">
                                <Form.Label>Sayfa Başlığı</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="max-w-[738px]"
                                    value={values?.pageTitle}
                                    onChange={(e) =>
                                        setValues({
                                            ...values,
                                            pageTitle: e.target.value,
                                        })
                                    }
                                />
                                {errors.pageTitle && clicked && (
                                    <div className='mt-1 p-2 rounded bg-red-100 text-red-700'>
                                        {errors.pageTitle}
                                    </div>
                                )}
                                <Form.Label>Açıklama</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    type="text"
                                    value={values?.pageDescription}
                                    onChange={(e) =>
                                        setValues({
                                            ...values,
                                            pageDescription: e.target.value,
                                        })
                                    }
                                />
                                {errors.pageDescription && clicked && (
                                    <div className='mt-1 p-2 rounded bg-red-100 text-red-700'>
                                        {errors.pageDescription}
                                    </div>
                                )}
                            </Form.Group>
                        </div>
                        <div>
                            <h5 className="text-purple-800">Önizleme</h5>
                            <div className="p-3 bg-gray-100 border border-radius shadow-sm rounded-md">
                                <p>sutok.com/shopping/{values?.slug}</p>
                                <p className="text-purple-900">
                                    {values?.pageTitle || "-"}
                                </p>
                                <p>{values?.pageDescription}</p>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-1 flex-row space-x-3'>
                        <div className='flex flex-1'>
                            <button type='button' onClick={() => {
                                setClicked(true);
                                handleSubmit("");
                            }} className='w-max px-3 py-2 rounded buttonPurple'>
                                Oluştur
                            </button>
                        </div>

                        <button onClick={() => navigate("/products")} className='w-max px-3 py-2 rounded bg-gray-100 hover:bg-gray-200'>
                            Vazgeç
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AddProduct