import React, { useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  SwipeableDrawer,
  TableContainer,
  Paper,
  Box,
  IconButton,
} from "@material-ui/core"
import { Typography } from "@mui/material"
import { useStyles } from "../styles"
import { Info as InfoIcon } from "@material-ui/icons"
import OrderDialog from "./OrderDialog"
import OrderDrawerCard from "./OrderDrawerCard"

const orderStatusMessages = {
  PENDING: "Beklemede",
  DECLINED: "İptal Edildi",
  SHIPPED: "Kargoya Verildi",
}

const OrderDrawer = ({ showDrawer, setShowDrawer, selectedOrder }) => {
  const classes = useStyles()
  const [openOrderDialog, setOpenOrderDialog] = React.useState(false)

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={showDrawer}
      onClose={() => setShowDrawer(false)}
      onOpen={() => setShowDrawer(true)}
      PaperProps={{
        style: {
          minWidth: "75%",
        },
      }}
    >
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ürün Fotoğrafı</TableCell>
              <TableCell>Marka</TableCell>
              <TableCell>Ürün Adı</TableCell>
              <TableCell>Satış Fiyatı</TableCell>
              <TableCell>Numara</TableCell>
              <TableCell>Adet</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedOrder.basket.map((basketItem) => (
              <OrderDrawerCard basketItem={basketItem} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.titleContainer}>
        <Typography variant="h6">
          ID: {selectedOrder._id.toUpperCase()}
        </Typography>
        <div style={{ flex: 1 }} />
        <Typography variant="h6">
          {orderStatusMessages[selectedOrder.status]}
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Total
              </TableCell>
              <TableCell align="right">
                {selectedOrder.totalAmount} TL
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Komisyon
              </TableCell>
              <TableCell align="right">
                {selectedOrder.totalAmount * 0.18} TL
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Durum
              </TableCell>
              <TableCell align="right">
                <InfoIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenOrderDialog(true)
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <OrderDialog
        orderId={selectedOrder._id}
        openOrderDialog={openOrderDialog}
        setOpenOrderDialog={setOpenOrderDialog}
      />
    </SwipeableDrawer>
  )
}

export default OrderDrawer
