import React, { useState } from "react";
import {
  Toolbar,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
} from "@material-ui/core";
import { Pagination } from "@mui/material";
import { Add as AddIcon } from "@material-ui/icons";
import { useQuery, useQueryClient } from "react-query";
import ModelDialog from "./ModelDialog";
import { useStyles } from "../styles";
import * as Queries from "../../utils/queries";
import CustomDrawer from "../../components/CustomDrawer";
import FilterPopover from "./FilterPopover";
import ModelCard from "./ModelCard";
import { IoFilterOutline } from "react-icons/io5";
import { IoMdSearch } from "react-icons/io";
import { Toaster } from "react-hot-toast";

const Models = () => {
  const classes = useStyles();
  const [openModelDialog, setOpenModelDialog] = useState(false);
  const [selectedModel, setSelectedModel] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const queryClient = useQueryClient();
  const [seeFiltre, setSeeFiltre] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    limit: 10,
    filterName: "",
  });
  const [filter, setFilter] = useState({
    brandContaining: "",
    brandLabel: "",
    parentModelContaining: "",
    parentModelLabel: "",
  });

  const {
    data,
    refetch: modelsRefetch
  } = useQuery(
    ["LIST_MODELS", searchQuery],
    () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      return Queries.listModels(
        new URLSearchParams({
          ...searchQuery,
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalModels: 0,
        totalPage: 0,
      },
      keepPreviousData: false,
      onError: (err) => {
        console.log("err", err);
      },
    }
  );

  const handleSearch = (event) => {
    setSearchQuery({
      ...searchQuery,
      filterName: event.target.value,
    });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="flex flex-1 bg-white">
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />
        <Toaster />
        <div className="flex flex-1 flex-row space-x-4 items-center px-3 bg-white p-3 shadow-sm rounded-md border">
          <div className="flex flex-1 text-xl font-semibold">Modeller</div>

          <button onClick={() => setOpenModelDialog(true)} className="flex flex-row space-x-2 items-center justify-center buttonPurple px-3 py-2 rounded">
            <AddIcon />
            <span>Model Oluştur</span>
          </button>
        </div>

        <div className="flex gap-3 bg-white w-full shadow-sm border p-3 rounded-md ">
          <div className="flex items-center border border-gray-200 rounded-md">
            <IoMdSearch size={20} className="ml-3" />{" "}
            <input
              type="text"
              placeholder="Ara"
              variant="outlined"
              size="small"
              className={classes.searchInput}
              value={searchQuery.filterName}
              onChange={e => {
                setSearchQuery({ ...searchQuery, filterName: e.target.value });
              }}
            />
          </div>
          <button className="flex items-center gap-2 bg-[#6f55ff] text-white p-2 rounded-md" onClick={() => setSeeFiltre(!seeFiltre)}>
            <IoFilterOutline /> Filtre
          </button>

          {/* 
            <Popover.Root>
            <Popover.Trigger asChild>
              <button className="px-3 py-2 borderGray rounded flex flex-row space-x-2 items-center justify-center">
                <MixerHorizontalIcon />
              </button>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content className="PopoverContent mr-4" sideOffset={5}>
                <div className="flex flex-col space-y-3">
                  <p className="font-semibold">
                    Sıralama
                  </p>
 
                  <select value={sorted} onChange={(e) => setSorted(e.target.value)} className="borderGray p-2 rounded">
                    <option disabled value={""} selected={sorted === ""}>Seçiniz</option>
                    <option value={"price_asc"} selected={sorted === "price_asc"}>Artan Fiyat</option>
                    <option value={"price_desc"} selected={sorted === "price_desc"}>Azalan Fiyat</option>
                    <option value={"stock_asc"} selected={sorted === "price_asc"}>Artan Stok</option>
                    <option value={"stock_desc"} selected={sorted === "price_asc"}>Azalan Stok</option>
                  </select>

                  <div className="flex flex-1 flex-row space-x-4">
                    <button onClick={() => setSorted("")} className="px-4 py-2 border border-gray-100 hover:bg-gray-100 rounded">
                      Sıfırla
                    </button>

                    <button onClick={() => setSearchQuery({ ...searchQuery, sorted: sorted })} className="px-4 py-2 buttonPurple rounded">
                      Uygula
                    </button>
                  </div>
                </div>
                <Popover.Close className="PopoverClose" aria-label="Close">
                  <Cross2Icon />
                </Popover.Close>
                <Popover.Arrow className="PopoverArrow" />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
          */}
        </div>
        <div className={`${seeFiltre ? " bg-white p-3 border rounded-md shadow-sm" : "hidden"}`}>
          <FilterPopover
            key={"filterPopover"}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            filter={filter}
            setFilter={setFilter}
          />
        </div>

        <div className=" bg-white p-3 shadow-sm rounded-md border">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className="bg-[#EEF2F6]">
                  <TableCell>Fotoğraf</TableCell>
                  <TableCell>Başlık</TableCell>
                  <TableCell>Üst Model</TableCell>
                  <TableCell>Marka</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.page?.length > 0 &&
                  data?.page?.map((model) => {
                    return (
                      <ModelCard
                        key={model._id}
                        modelData={model}
                        setSelectedModel={setSelectedModel}
                        setOpenModelDialog={setOpenModelDialog}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="flex flex-1 justify-center py-2">
            <Pagination
              page={searchQuery.page}
              onChange={(_, value) => {
                setSearchQuery({
                  ...searchQuery,
                  page: value,
                });
              }}
              count={data?.totalPage}
              shape="rounded"
              variant="outlined"
              color="primary"
            />
          </div>
        </div>
      </main>

      {openModelDialog && (
        <ModelDialog
          openModelDialog={openModelDialog}
          setOpenModelDialog={setOpenModelDialog}
          modelsRefetch={modelsRefetch}
        />
      )}
    </div>
  );
};

export default Models;
