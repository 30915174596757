import ReactGA from 'react-ga4';

export const initGA = () => {
    ReactGA.initialize('G-05MP8Q1NCM'); // Burada 'YOUR_TRACKING_ID' yerine kendi izleme kimliğinizi girin.
};

export const logPageView = (pathname) => {
    ReactGA.pageview(pathname);
};

export const sendEvent = (action, category, label, value) => {
    /*ReactGA.event({
        action: action,
        category: category,
        label: label,
        value: value
    });*/
    console.log({
        action: 'add_to_cart',
        category: 'E-commerce',
        label: "product.name",
        value: 1
    })
    ReactGA.event({
        action: 'add_to_cart',
        category: 'E-commerce',
        label: "product.name",
        value: 1
    });
};