import React, { useEffect, useState } from "react";
import { useStyles } from "../styles";
import { Toolbar } from "@material-ui/core";
import CustomDrawer from "../../components/CustomDrawer";
import { InputGroup } from "react-bootstrap";
import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import * as Queries from "../../utils/queries";
import { useQuery } from "react-query";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import VariantCard from "./VariantCard";
import ProductattributeCard from "./ProductattributeCard";
import AddVariantSelectBox from "./AddVariantSelectBox";
import CreateProductattributeForm from "./CreateProductattributeForm";
import { useSearchParams } from "react-router-dom";

const ProductAttribute = () => {
  const classes = useStyles();

  const [isProductattributeFormVisible, setIsProductattributeFormVisible] =
    useState(false);

  return (
    <div className="flex">
      <CustomDrawer />
      <Toaster />
      <div className={classes.content}>
        <Toolbar />

        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-3 bg-white shadow-sm border rounded-md p-3">
            <h6 className="text-purple-700">Nitelikler</h6>
          </div>

          {/* Nitelikler */}

          <div
            className="bg-white p-3 shadow-sm border rounded-md flex flex-col gap-3 "
            id="asds"
          >
            <div className="flex items-center justify-between">
              <h6>
                {isProductattributeFormVisible ? "Nitelik Ekle" : "Nitelikler"}
              </h6>
              {!isProductattributeFormVisible && (
                <button
                  onClick={() => setIsProductattributeFormVisible(true)}
                  className="text-purple-800 pb-2 flex items-center gap-3"
                >
                  Nitelik Ekle
                </button>
              )}

              
            </div>

            {!isProductattributeFormVisible ? (
              <div className="flex flex-col gap-3">
                <div className="flex flex-1 gap-3 items-center">
                  <button className=" p-2 whitespace-nowrap text-purple-600 border  rounded-md">
                    Yeni Ekle
                  </button>
                  <div className="flex flex-1"></div>
                </div>
              </div>
            ) : (
              <CreateProductattributeForm
                setIsProductattributeFormVisible={
                  setIsProductattributeFormVisible
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductAttribute;
