import {
  Dialog,
  DialogContent,
  DialogTitle,
  Portal,
  Snackbar,
} from "@material-ui/core"
import React from "react"
import { useForm } from "react-hook-form"
import DialogField from "../../components/DialogField"
import { useMutation, useQueryClient } from "react-query"
import * as Queries from "../../utils/queries"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import CustomDialogActions from "../../components/CustomDialogActions"
import moment from "moment"
import { useAtom } from "jotai"
import { userAtom } from "../../utils/atoms"

const getProductFields = ({ user, attributeInformations }) => {
  if (user?.role === "SELLER") {
    return [
      {
        label: "Sku",
        id: "sku",
      },
      {
        label: "Çıkış Tarihi",
        id: "releaseDate",
        type: "DATE",
      },
      {
        label: "Colorway",
        id: "colorway",
      },
      {
        label: "Style",
        id: "style",
      }, {
        label: "Stok",
        id: "stock",
      },
      {
        label: "Fiyat",
        id: "price",
      },
      ...attributeInformations.map((attr) => ({
        label: attr.title,
        id: attr._id,
        type: "SELECT",
        options: attr.terms.map((term) => ({
          label: term.value,
          id: term._id,
        })),
      })),
    ]
  } else {
    return [
      {
        label: "Sku",
        id: "sku",
      },
      {
        label: "Çıkış Tarihi",
        id: "releaseDate",
        type: "DATE",
      },
      {
        label: "Colorway",
        id: "colorway",
      },
      {
        label: "Style",
        id: "style",
      },
      ...attributeInformations.map((attr) => ({
        label: attr.title,
        id: attr._id,
        type: "SELECT",
        options: attr.terms.map((term) => ({
          label: term.value,
          id: term._id,
        })),
      })),
    ]
  }

}

const ProductDialog = ({
  openProductDialog,
  setOpenProductDialog,
  initialData,
  selectedProductGroup,
  attributeInformations,
}) => {
  const [message, setMessage] = React.useState()
  const queryClient = useQueryClient()

  const [user, setUser] = useAtom(userAtom);

  const productSchema = yup.object(user?.role === "SELLER" ?
    {
      // terms: yup.array().of(yup.mixed()).required(),
      sku: yup.mixed().optional().nullable(),
      releaseDate: yup.mixed().optional().nullable(),
      colorway: yup.mixed().optional().nullable(),
      style: yup.mixed().optional().nullable(),
      stock: yup.mixed().optional().required(),
      price: yup.mixed().optional().required(),
    } :
    {
      // terms: yup.array().of(yup.mixed()).required(),
      sku: yup.mixed().optional().nullable(),
      releaseDate: yup.mixed().optional().nullable(),
      colorway: yup.mixed().optional().nullable(),
      style: yup.mixed().optional().nullable(),
    })

  const { register, watch, control, handleSubmit } = useForm({
    resolver: yupResolver(productSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: initialData
      ? {
        ...initialData,
        ...initialData.terms.reduce((prev, cur) => {
          return {
            ...prev,
            [cur.attribute]: cur._id,
          }
        }, {}),
      }
      : {},
  })

  const createMutation = useMutation(Queries.createProduct, {
    onSuccess: (res) => {
      console.log("res", res)
      setOpenProductDialog(false)
      setMessage("Varyant oluşturuldu.")
      queryClient.invalidateQueries(["LIST_PRODUCTS"])
    },
    onError: (err) => {
      console.log("err", err)
    },
  })

  const updateMutation = useMutation(
    ([termId, termDto]) => Queries.updateProduct(termId, termDto),
    {
      onSuccess: (res) => {
        console.log("res", res)
        setOpenProductDialog(false)
        setMessage("Varyant güncellendi.")
        queryClient.invalidateQueries(["LIST_PRODUCTS"])
      },
      onError: (err) => {
        console.log("err", err)
      },
    }
  )

  const deleteMutation = useMutation(Queries.deleteProduct, {
    onSuccess: (res) => {
      console.log("res", res)
      setOpenProductDialog(false)
      setMessage("Varyant silindi.")
      queryClient.invalidateQueries(["LIST_PRODUCTS"])
    },
    onError: (err) => {
      console.log("err", err)
    },
  })

  const onValid = (data) => {
    const { sku, releaseDate, colorway, style, stock, price, terms, ...rest } = data

    /*if (
      Object.values(rest).filter((val) => !!val).length !==
      attributeInformations.length
    ) {
      return setMessage("Please fill all required fields")
    }*/

    const veri = rest;
    const anahtar = '64e35922fa23d8b59c904203';

    const deger = veri[anahtar];

    const list = [];
    if (terms) {
      for (let i = 0; i < terms.length; i++) {
        list.push(terms[i]._id)
      }
    }

    list.push(deger)

    const dto = user?.role === "SELLER" ? {
      sku,
      releaseDate: moment(releaseDate).toDate(),
      colorway,
      style,
      stock,
      price,
      group: selectedProductGroup._id,
      terms: [list[0]],
    } : {
      sku,
      releaseDate: moment(releaseDate).toDate(),
      colorway,
      style,
      group: selectedProductGroup._id,
      terms: list,
    }

    if (initialData) {
      updateMutation.mutate([initialData._id, dto])
    } else {
      createMutation.mutate(dto)
    }
  }

  const onInvalid = (errors) => {
    const firstErr = Object.values(errors)[0]

    if (Array.isArray(firstErr)) {
      setMessage(firstErr[0].message)
    } else {
      setMessage(firstErr.message)
    }
  }

  return (
    <>
      <Dialog
        open={openProductDialog}
        onClose={() => setOpenProductDialog(false)}
        fullWidth
      >
        <DialogTitle>{"Varyant Detayı"}</DialogTitle>
        <DialogContent>
          {getProductFields({
            user,
            attributeInformations,
          }).map((item) => {
            return (
              <DialogField
                item={item}
                control={control}
                register={register}
                watch={watch}
                disabled={false}
              />
            )
          })}

          <CustomDialogActions
            isUpdate={initialData}
            onDelete={() => {
              const isConfirmed = window.confirm(
                `Varyantı silmek istediğinize emin misiniz?`
              )

              if (isConfirmed) {
                deleteMutation.mutate(initialData._id)
              }
            }}
            onConfirm={handleSubmit(onValid, onInvalid)}
          />
        </DialogContent>
      </Dialog>

      <Portal>
        <Snackbar
          open={!!message}
          autoHideDuration={3000}
          onClose={() => setMessage(undefined)}
          message={message}
        />
      </Portal>
    </>
  )
}

export default ProductDialog
