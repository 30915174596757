import { Dialog, DialogContent, DialogTitle, Snackbar } from "@material-ui/core"
import React from "react"
import { useForm } from "react-hook-form"
import DialogField from "../../components/DialogField"
import { useMutation, useQueryClient } from "react-query"
import * as Queries from "../../utils/queries"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import CustomDialogActions from "../../components/CustomDialogActions"

const getHomeSectionFields = () => [
  {
    label: "Bölüm Adı",
    id: "title",
  },
  {
    type: "AUTOCOMPLETE",
    label: "Ürünler",
    id: "products",
    multiple: true,
    renderText: (option) => option.title,
    options: [],
    fetchFn: (sq) => {
      return Queries.listProductGroups(
        new URLSearchParams({
          filterName: sq,
          page: 1,
          limit: 25
        }).toString()
      )
    },
  },
]

const homeSectionSchema = yup.object({
  title: yup.string().required(),
  products: yup.array().of(yup.mixed()).required(),
})

const HomeSectionDialog = ({
  openHomeSectionDialog,
  setOpenHomeSectionDialog,
  initialData,
}) => {
  const [message, setMessage] = React.useState()
  const queryClient = useQueryClient()

  const { register, watch, control, handleSubmit } = useForm({
    resolver: yupResolver(homeSectionSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: initialData,
  })

  const updateSectionMutation = useMutation(
    ([sectionId, dto]) => Queries.updateSection(sectionId, dto),
    {
      onSuccess: (res) => {
        console.log("res", res)
        setOpenHomeSectionDialog(false)
        setMessage("Bölüm güncellendi.")
        queryClient.invalidateQueries(["LIST_HOME_SECTIONS"])
      },
      onError: (err) => {
        console.log("err", err)
      },
    }
  )

  const createSectionMutation = useMutation(Queries.createSection, {
    onSuccess: (res) => {
      console.log("res", res)
      setOpenHomeSectionDialog(false)
      setMessage("Bölüm oluşturuldu.")
      queryClient.invalidateQueries(["LIST_HOME_SECTIONS"])
    },
    onError: (err) => {
      console.log("err", err)
    },
  })

  const deleteSectionMutation = useMutation(Queries.deleteSection, {
    onSuccess: (res) => {
      console.log("res", res)
      setOpenHomeSectionDialog(false)
      setMessage("Bölüm silindi.")
      queryClient.invalidateQueries(["LIST_HOME_SECTIONS"])
    },
    onError: (err) => {
      console.log("err", err)
    },
  })

  const onValid = (data) => {
    const dto = {
      ...data,
      products: data.products.map((item) => item._id),
    }

    if (initialData) {
      updateSectionMutation.mutate([initialData._id, dto])
    } else {
      createSectionMutation.mutate(dto)
    }
  }

  const onInvalid = (errors) => {
    const firstErr = Object.values(errors)[0]

    if (Array.isArray(firstErr)) {
      setMessage(firstErr[0].message)
    } else {
      setMessage(firstErr.message)
    }
  }

  return (
    <>
      <Dialog
        open={openHomeSectionDialog}
        onClose={() => setOpenHomeSectionDialog(false)}
        fullWidth
      >
        <DialogTitle>{"Bölüm Detayı"}</DialogTitle>
        <DialogContent>
          {getHomeSectionFields().map((item) => {
            return (
              <DialogField
                item={item}
                control={control}
                register={register}
                watch={watch}
                disabled={false}
              />
            )
          })}

          <CustomDialogActions
            isUpdate={initialData}
            onDelete={() => {
              const isConfirmed = window.confirm(
                `${initialData.title} isimli bölümü silmek istediğinize emin misiniz?`
              )

              if (isConfirmed) {
                deleteSectionMutation.mutate(initialData._id)
              }
            }}
            onConfirm={handleSubmit(onValid, onInvalid)}
          />
        </DialogContent>
      </Dialog>

      <Snackbar
        open={!!message}
        autoHideDuration={3000}
        onClose={() => setMessage(undefined)}
        message={message}
      />
    </>
  )
}

export default HomeSectionDialog
