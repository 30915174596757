import React, { useState } from 'react'
import AddressCard from './AddressCard';

const Addresses = ({ userData }) => {

    const [selectedAddress, setSelectedAddress] = useState(null);

    return (
        <div className='flex flex-col h-max bg-white rounded border-1 border-gray-200' style={{ flex: 1 }}>
            <div className='p-3 border-b border-gray-200 font-semibold'>
                Adresler
            </div>
            {userData.address.length !== 0 ?
                <div className='flex flex-col space-y-2 p-3 h-max'>
                    {userData.address.map(address => {
                        return (
                            <AddressCard
                                key={address._id}
                                address={address}
                                selectedAddress={selectedAddress}
                                setSelectedAddress={setSelectedAddress}
                            />
                        )
                    })}
                </div>
                :
                <div className='flex flex-1 justify-center items-center p-4 font-semibold'>
                    {userData?.role === "SELLER" ? "Satıcıya" : "Kullanıcıya"} ait henüz bir adres yok
                </div>}
        </div>
    )
}

export default Addresses