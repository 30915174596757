import { Dialog, DialogContent, DialogTitle } from "@material-ui/core"
import React, { useRef, useState } from "react"
import { useQuery } from "react-query"
import * as Queries from "../../utils/queries"
import * as yup from "yup"
import { useFormik } from "formik"
import toast from "react-hot-toast"
import { Form } from "react-bootstrap"
import ReactQuill from "react-quill"
import Select from 'react-select'

const brandSchema = yup.object({
  cover: yup.string().required("Lütfen kapak detay görseli seçiniz"),
  background: yup.string().required("Lütfen arkaplan görseli seçiniz"),
  logo: yup.string().required("Lütfen logo görseli seçiniz"),
  title: yup.string().required("Lütfen başlık giriniz"),
  description: yup.string().required("Lütfen açıklama giriniz"),
  category: yup.object().required("Lütfen kategori seçiniz"),
})

const BrandDialog = ({ openBrandDialog, setOpenBrandDialog, brandsRefetch }) => {
  const [clicked, setClicked] = useState(false);
  const logoFileInputRef = useRef(null);
  const coverFileInputRef = useRef(null);
  const backgroundFileInputRef = useRef(null);

  const { handleSubmit, handleChange, handleReset, values, touched, errors, setValues } = useFormik({
    initialValues: {
      title: "",
      description: "",
      category: "",
      logo: "",
      cover: "",
      background: ""
    },
    onSubmit: async (val) => {
      if (values.description !== "<p><br></p>" && values?.category?.value) {
        const response = await Queries.createBrand({
          ...values,
          category: values.category.value
        });
        if (response?._id) {
          toast.success("Marka başarılı bir şekilde oluşturuldu");
          handleReset();
          brandsRefetch();
          setOpenBrandDialog(false);
        } else {
          toast.error("Marka oluşturulurken bir hata meydana geldi. Lürfen tekrar deneyiniz");
        }
      }

    },
    validationSchema: brandSchema
  });

  const { data: categoriesData } = useQuery(
    ["LIST_CATEGORIES"],
    Queries.listCategories,
    {
      onError: (err) => {
        console.log("err", err)
      },
      placeholderData: {
        page: [],
      },
    }
  )

  const handleLogoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const response = await Queries.uploadMedia(file);
      setValues({
        ...values,
        logo: response?.secure_url
      });
    } else {
      toast.error('Logo Görseli Seçilirken Bir Hata Oluştu. Lütfen Tekrar Deneyiniz');
      e.target.value = null;
    }
  };

  const handleCoverUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const response = await Queries.uploadMedia(file);
      setValues({
        ...values,
        cover: response?.secure_url,
      });
    } else {
      toast.error('Kapak Detay Görseli Seçilirken Bir Hata Oluştu. Lütfen Tekrar Deneyiniz');
      e.target.value = null;
    }
  };

  const handleBackgroundUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const response = await Queries.uploadMedia(file);
      setValues({
        ...values,
        background: response?.secure_url
      });
    } else {
      toast.error('Arkaplan Görseli Seçilirken Bir Hata Oluştu. Lütfen Tekrar Deneyiniz');
      e.target.value = null;
    }
  };

  const handleImageInput = (imageInputRef) => {
    imageInputRef.current.click();
  };

  return (
    <Dialog
      open={openBrandDialog}
      onClose={() => setOpenBrandDialog(false)}
      fullWidth
    >
      <DialogTitle>{"Marka Oluştur"}</DialogTitle>
      <DialogContent>

        <form onSubmit={handleSubmit} className="flex flex-1 flex-col space-y-3">

          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            ref={coverFileInputRef}
            onChange={handleCoverUpload}
          />

          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            ref={logoFileInputRef}
            onChange={handleLogoUpload}
          />

          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            ref={backgroundFileInputRef}
            onChange={handleBackgroundUpload}
          />

          <div className="flex flex-1 flex-col space-y-1">
            <div className="font-semibold">Kapak Detay Görseli</div>

            {values?.cover ?
              <div onClick={() => handleImageInput(coverFileInputRef)} className="flex flex-1 h-max cursor-pointer">
                <img
                  src={values?.cover}
                  alt="cover"
                  className="w-full h-72 border"
                />
              </div>
              : <div onClick={() => handleImageInput(coverFileInputRef)} className="cursor-pointer flex items-center justify-center w-full h-72 bg-gray-300">
                <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>}
            {errors.cover && clicked && (
              <div className="flex flex-1 p-2 rounded bg-red-100 text-red-700">
                {errors.cover}
              </div>
            )}
          </div>

          <div className="flex flex-1 flex-col space-y-1">
            <div className="font-semibold">Arkaplan Görseli</div>

            {values?.background ?
              <div onClick={() => handleImageInput(backgroundFileInputRef)} className="flex flex-1 h-max cursor-pointer">
                <img
                  src={values?.background}
                  alt="background"
                  className="w-full h-72 border"
                />
              </div>
              : <div onClick={() => handleImageInput(backgroundFileInputRef)} className="cursor-pointer flex items-center justify-center w-full h-72 bg-gray-300">
                <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>}
            {errors.background && clicked && (
              <div className="flex flex-1 p-2 rounded bg-red-100 text-red-700">
                {errors.background}
              </div>
            )}
          </div>

          <div className="flex flex-1 flex-col space-y-1">
            <div className="font-semibold">Logo Görseli</div>

            {values?.logo ?
              <div onClick={() => handleImageInput(logoFileInputRef)} className="flex flex-1 h-max cursor-pointer">
                <img
                  src={values?.logo}
                  alt="logo"
                  className="w-full h-72 border"
                />
              </div>
              : <div onClick={() => handleImageInput(logoFileInputRef)} className="cursor-pointer flex items-center justify-center w-full h-72 bg-gray-300">
                <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>}
            {errors.logo && clicked && (
              <div className="flex flex-1 p-2 rounded bg-red-100 text-red-700">
                {errors.logo}
              </div>
            )}
          </div>

          <div className="flex flex-1 flex-col space-y-1">
            <div className="font-semibold">Kategori</div>

            {categoriesData?.page?.length !== 0 ?
              <Select
                isSearchable
                value={values.category}
                onChange={(value) => setValues({ ...values, category: value })}
                options={categoriesData?.page?.map(item => { return { value: item?._id, label: item?.title } })}
              /> : <></>}
            {(errors.category || !values?.category?.value) && clicked && (
              <div className="flex flex-1 p-2 rounded bg-red-100 text-red-700">
                Lütfen Kategori Seçiniz
              </div>
            )}
          </div>

          <div className="flex flex-1 flex-col space-y-1">
            <div className="font-semibold">Marka Adı</div>

            <Form.Control
              type="text"
              value={values.title}
              onChange={handleChange("title")}
            />
            {errors.title && clicked && (
              <div className="flex flex-1 p-2 rounded bg-red-100 text-red-700">
                {errors.title}
              </div>
            )}
          </div>

          <div className="flex flex-1 flex-col space-y-1">
            <div className="font-semibold">Marka Açıklaması</div>

            <ReactQuill
              theme="snow"
              value={values.description}
              onChange={handleChange("description")}
            />
            {(errors.description || values.description === "<p><br></p>") && clicked && (
              <div className="flex flex-1 p-2 rounded bg-red-100 text-red-700">
                Lütfen açıklama giriniz
              </div>
            )}
          </div>

          <div className="flex flex-1 flex-row space-x-3">
            <button onClick={() => setClicked(true)} type="submit" className="px-3 py-2 rounded buttonPurple">
              Oluştur
            </button>

            <button type="button" onClick={() => setOpenBrandDialog(false)} className="px-3 py-2 rounded bg-gray-100 hover:bg-gray-200">
              Vazgeç
            </button>
          </div>
        </form>

      </DialogContent>
    </Dialog>
  )
}

export default BrandDialog
