import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableCell, TableRow } from "@material-ui/core";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import '../../checkbox.css';

const ProductGroupCard = ({ productGroup, handleSelect, selectedProducts }) => {
  const navigate = useNavigate();

  return (
    <TableRow>
      <TableCell>
        <div className="flex items-center gap-3">
          <Checkbox.Root className="CheckboxRoot" id="c1"
            checked={selectedProducts.find(item => item._id === productGroup?._id) ? true : false}
            onCheckedChange={(value) => {
              handleSelect(value, productGroup)
            }}>
            <Checkbox.Indicator className="CheckboxIndicator">
              <CheckIcon />
            </Checkbox.Indicator>
          </Checkbox.Root>

          <img
            src={productGroup?.photos[0]}
            alt={productGroup.name}
            className="w-10 h-10"
          />
        </div>
      </TableCell>
      <TableCell>
        {" "}
        {productGroup.title}
        <small className="textPurple block">
          {productGroup.variantCount} varyant
        </small>
      </TableCell>
      <TableCell>
        {" "}
        {productGroup.price ? `₺ ${productGroup.price}` : "Belirsiz"}
      </TableCell>
      <TableCell>
        {productGroup.advertsCount}
        <small className="textPurple block">
          {productGroup.variantCount} varyant
        </small>
      </TableCell>
      <TableCell>
        <button
          onClick={() => {
            navigate(`/product/detail?id=${productGroup._id}`);
          }}
        >
          <FaArrowUpRightFromSquare />
        </button>
      </TableCell>
    </TableRow>
  );
};

export default ProductGroupCard;
