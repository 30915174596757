import React, { useState } from "react";
import {
  General,
  Shipping,
  Stock,
  LinkedProducts,
  Advanced,
  Attributes,
} from "./Forms";

const SimpleProduct = () => {
  const [page, setPage] = useState("Genel");

  const buttons = [
    "Genel",
    "Stok",
    "Gönderim",
    "Bağlantılı Ürünler",
    "Niteklikler",
    "Gelişmiş",
  ];
  return (
    <div className="flex gap-3 my-3">
      <div className="bg-gray-100 flex flex-col min-w-[200px]">
        {buttons.map((button, index) => (
          <button
            className={`text-[14px] p-2 text-blue-700 ${
              button === page && "bg-gray-300 text-gray-700"
            }`}
            key={index}
            onClick={() => setPage(`${button}`)}
          >
            {button}
          </button>
        ))}
      </div>
      
        {page === "Genel" ? (
          <General />
        ) : page === "Stok" ? (
          <Stock />
        ) : page === "Gönderim" ? (
          <Shipping />
        ) : page === "Bağlantılı Ürünler" ? (
          <LinkedProducts />
        ) : page === "Niteklikler" ? (
          <Attributes />
        ) : page === "Gelişmiş" ? (
          <Advanced />
        ) : null}
      
    </div>
  );
};

export default SimpleProduct;
