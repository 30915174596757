import React, { useState } from "react";
import {
  Toolbar,
  Typography,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { useQuery } from "react-query";
import { useStyles } from "../styles";
import * as Queries from "../../utils/queries";
import CustomDrawer from "../../components/CustomDrawer";
import moment from "moment";
import "moment/locale/tr";
import CargoCard from "./CargoCard";

const Cargo = () => {
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [filterStatus, setFilterStatus] = useState("WAITING_FOR_CARGO");

  const [page, setPage] = useState(1);

  const {
    data,
  } = useQuery(
    ["LIST_CARGOS", page, filterStatus],
    () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      return Queries.listCargos(
        new URLSearchParams({
          status: filterStatus,
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalAdverts: 0,
      },
      keepPreviousData: false,
    }
  );

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className={classes.root}>
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />

        <div className="bg-white p-3 border rounded-md shadow-sm">
          <TextField
            select
            label="Kargo Durumu"
            variant="outlined"
            size="small"
            fullWidth
            value={filterStatus}
            className={classes.searchTypeSelect}
            onChange={(e) => {
              setFilterStatus(e.target.value);
            }}
          >
            <MenuItem value="WAITING_FOR_CARGO">Kargo Bekleyenler</MenuItem>
            <MenuItem value="WAITING_FOR_CONTROL">Kontrol Bekleyenler</MenuItem>
            <MenuItem value="SHIPPED">Kargoya Çıkanlar</MenuItem>
          </TextField>
        </div>

        <div className="p-3 bg-white border rounded-md shadow-sm">
          <TableContainer component={Paper}>
            <Table >
              <TableHead className="bg-[#EEF2F6]">
                <TableRow>
                  <TableCell>Kullanıcı</TableCell>
                  <TableCell>Ürün</TableCell>
                  <TableCell>Fiyat</TableCell>
                  <TableCell>Tarih</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.page?.map((advert) => (
                  <CargoCard key={advert._id} advert={advert} />
                ))}

              </TableBody>
              <TableFooter>
                <TablePagination
                  count={data?.totalAdverts}
                  page={page - 1}
                  rowsPerPageOptions={[{ value: 10 }]}
                  rowsPerPage={10}
                  onPageChange={(_, newPage) => {
                    setPage(newPage + 1);
                  }}
                />
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </main>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="İşlem başarılı."
      />
    </div>
  );
};

export default Cargo;
