import React, { useState } from "react";
import {
  Toolbar,
  Typography,
  Snackbar,
  TextField,
  Button,
} from "@material-ui/core";
import { useStyles } from "../styles";
import CustomDrawer from "../../components/CustomDrawer";
import { useQuery, useMutation } from "react-query";
import * as Queries from "../../utils/queries";
import toast from "react-hot-toast";

const SaleSettings = () => {
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [serviceFee, setServiceFee] = useState("");
  const [commissionRate, setCommissionRate] = useState("");
  const [sellerServiceFee, setSellerServiceFee] = useState("");
  const [sellerCommissionRate, setSellerCommissionRate] = useState("");
  const [kdvRate, setKdvRate] = useState("");

  useQuery(["SALE_SETTINGS"], Queries.getSaleSettings, {
    onSuccess: (res) => {
      setServiceFee(res?.serviceFee);
      setCommissionRate(res?.commissionRate);
      setSellerServiceFee(res?.sellerServiceFee);
      setSellerCommissionRate(res?.sellerCommissionRate);
      setKdvRate(res?.kdvRate);
    },
  });

  const updateSaleSettingsMutation = useMutation(Queries.updateSaleSettings, {
    onSuccess: (res) => {
      if (res?._id) {
        toast.success("Satış Ayarları Başarılı Bir Şekilde Güncellendi")
      } else {
        toast.error("Satış ayarları güncellenirken bir hata oluştu. Lütfen tekrar deneyiniz")
      }
    },
    onError: (res) => {
      console.log("res", res);
    },
  });

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const onUpdatePress = () => {
    updateSaleSettingsMutation.mutate({
      serviceFee,
      commissionRate,
      sellerServiceFee,
      sellerCommissionRate,
      kdvRate
    });
  };

  return (
    <div className={classes.root}>
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />
        <Typography variant="h6" gutterBottom>
          Bireysel Satış Ayarları
        </Typography>
        <div className="p-3 border rounded-md bg-white shadow-sm">
          <TextField
            label="Hizmet Bedeli (TL)"
            variant="outlined"
            size="medium"
            margin="normal"
            type="number"
            fullWidth
            value={serviceFee}
            onChange={(e) => {
              setServiceFee(e.target.value);
            }}
          />

          <TextField
            label="Komisyon Tutarı (%)"
            variant="outlined"
            size="medium"
            margin="normal"
            type="number"
            fullWidth
            value={commissionRate}
            onChange={(e) => {
              setCommissionRate(e.target.value);
            }}
          />
        </div>

        <Typography variant="h6" gutterBottom>
          Satıcı Satış Ayarları
        </Typography>

        <div className="bg-white p-3 shadow-sm rounded-md border">
          <TextField
            label="Hizmet Bedeli (TL)"
            variant="outlined"
            size="medium"
            margin="normal"
            type="number"
            fullWidth
            value={sellerServiceFee}
            onChange={(e) => {
              setSellerServiceFee(e.target.value);
            }}
          />

          <TextField
            label="Komisyon Tutarı (%)"
            variant="outlined"
            size="medium"
            margin="normal"
            type="number"
            fullWidth
            value={sellerCommissionRate}
            onChange={(e) => {
              setSellerCommissionRate(e.target.value);
            }}
          />
        </div>

        <Typography variant="h6" gutterBottom>
          KDV Oranı (%)
        </Typography>

        <div className="bg-white p-3 shadow-sm rounded-md border">
          <TextField
            label="KDV Oranı (%)"
            variant="outlined"
            size="medium"
            margin="normal"
            type="number"
            fullWidth
            value={kdvRate}
            onChange={(e) => {
              setKdvRate(e.target.value);
            }}
          />
        </div>

        <Button
          variant="contained"
          onClick={onUpdatePress}
          style={{
            backgroundColor: "#000000",
            color: "white",
            marginTop: "1rem",
          }}
          fullWidth
        >
          Güncelle
        </Button>
      </main>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="İşlem başarılı."
      />
    </div>
  );
};

export default SaleSettings;
