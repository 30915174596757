import { TextField } from "@material-ui/core"
import { Autocomplete } from "@mui/material"
import React from "react"
import { Controller } from "react-hook-form"
import { useQuery } from "react-query"

const AutocompleteField = ({ item, value, disabled, control }) => {
  const [searchQuery, setSearchQuery] = React.useState("")

  const fetchQuery = useQuery(
    ["FETCH_OPTIONS", item.id, searchQuery],
    () => {
      return item.fetchFn(searchQuery)
    },
    {
      placeholderData: {
        page: [],
      },
      enabled: searchQuery.length > 2,
    }
  )

  return (
    <Controller
      key={item.id}
      name={item.id}
      control={control}
      render={({ field }) => {
        return (
          <Autocomplete
            getOptionLabel={(option) =>
              typeof option === "string" ? option : item.renderText(option)
            }
            filterOptions={(x) => x}
            defaultValue={field.value}
            options={fetchQuery.data.page}
            autoComplete
            multiple={item.multiple}
            includeInputInList
            filterSelectedOptions
            noOptionsText="No items"
            onChange={(event, newValue) => {
              field.onChange(newValue)
            }}
            onInputChange={(event, newInputValue) => {
              setSearchQuery(newInputValue)
            }}
            sx={{
              "& .MuiInputBase-root": {
                padding: 0,
              },
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={item.label}
                  value={value}
                  variant="outlined"
                  size="lg"
                  disabled={disabled}
                  fullWidth
                  margin="normal"
                  inputProps={{
                    ...params.inputProps,
                    className: "",
                  }}
                />
              )
            }}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <span>{item.renderText(option)}</span>
                </li>
              )
            }}
          />
        )
      }}
    />
  )
}

export default AutocompleteField
