import React, { useEffect, useState } from "react";
import { Toolbar } from "@material-ui/core";
import { useStyles } from "../styles";
import CustomDrawer from "../../components/CustomDrawer";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Table } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";
import { CiClock2 } from "react-icons/ci";
import { AiFillCreditCard } from "react-icons/ai";
import * as Queries from "../../utils/queries";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import Select from "react-select";
import toast from "react-hot-toast";
import BasketCard from "./BasketCard";
import TimelineCard from "./TimelineCard";
import moment from "moment";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { useAtom } from "jotai";
import { userAtom } from "../../utils/atoms";
import CustomLoading from '../../components/CustomLoading'
import axios from "axios";

const OrderDetail = () => {
  const navigate = useNavigate();
  const [user, setUser] = useAtom(userAtom);
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const [selectedOrderStatus, setSelectedOrderStatus] = useState({
    value: "",
    label: "",
  });
  const [orderSummary, setOrderSummary] = useState({
    totalCount: 0,
    totalPrice: 0,
  });
  const [loading, setLoading] = useState(false)
  const [orderStateList, setOrderStateList] = useState([
    { value: "SHIPPED", label: "Kargoda" },
  ]);
  const [cargoData, setCargoData] = useState(null)

  const { data: orderData, refetch: orderDataRefetch } = useQuery(
    ["getOrderDetails", searchParams.get("id")],
    () => Queries.getOrderDetails(searchParams.get("id")),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: null,
    }
  );

  const { data: saleSettingsData, refetch: saleSettingsDataRefetch } = useQuery(
    ["getSaleSettings"],
    () => Queries.getSaleSettings(),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: null,
    }
  );

  const changeDatas = async () => {
    if (user?.role === "ADMIN") {
      setOrderStateList([
        { value: "DELIVERED", label: "Teslim Edildi" },
        { value: "PENDING", label: "Beklemede" },
        { value: "DECLINED", label: "Reddedildi" },
        { value: "SHIPPED", label: "Kargoda" },
        { value: "SUTOKCARGO", label: "Sutok Kargo" },
        { value: "INTHEREVIEW", label: "İncelemede" },
        { value: "PAYMENTPENDING", label: "Satıcı Ödemesi Bekleniyor" },
        { value: "PAYMENTMADE", label: "Satıcı Ödeme Tamamlandı" },
      ])
    }

    let totalCount = 0;
    let totalPrice = 0;

    for (let i = 0; i < orderData?.basket?.length; i++) {
      totalCount += orderData?.basket[i].quantity;
      totalPrice += orderData?.basket[i].quantity * orderData?.basket[i].price;
    }
    setOrderSummary({
      totalCount: totalCount,
      totalPrice: totalPrice,
    });
  };

  const handleUpdateOrder = async (data) => {
    setLoading(true);

    const response = await Queries.updateOrder(searchParams.get("id"), {
      ...data,
      createdBy: user?._id
    });
    if (response?._id) {
      toast.success("Sipariş başarılı bir şekilde güncellendi");
    } else {
      toast.error(
        "Sipariş güncellenirken bir hata oluştu. Lütfen tekrar deneyiniz"
      );
    }
    orderDataRefetch();

    setLoading(false);
  };

  const formatOrderStatus = async () => {
    const response = await axios.get(orderData?.apiTrackingUrl);
    setCargoData(response?.data?.data);
    const trackingStatus = response?.data?.data?.trackingStatus;
    const trackingSubStatus = response?.data?.data?.trackingSubStatus;

    if (trackingStatus === "PRE_TRANSIT") {
      if (trackingSubStatus === "information_received") {
        setCargoData({ ...response?.data?.data, cargoStatus: { id: "information_received", label: "Kargo sistemde oluşturuldu" } })
      }
    } else if (trackingStatus === "TRANSIT") {
      if (trackingSubStatus === "package_accepted") {
        setCargoData({ ...response?.data?.data, cargoStatus: { id: "package_accepted", label: "Kargo, Gönderici şube tarafından teslim alındı." } })
      } else if (trackingSubStatus === "package_departed") {
        setCargoData({ ...response?.data?.data, cargoStatus: { id: "package_departed", label: "Kargo, aktarma merkezinden ayrıldı" } })
      } else if (trackingSubStatus === "package_processing") {
        setCargoData({ ...response?.data?.data, cargoStatus: { id: "package_processing", label: "Kargo aktarma merkezinde" } })
      } else if (trackingSubStatus === "delivery_scheduled") {
        setCargoData({ ...response?.data?.data, cargoStatus: { id: "delivery_scheduled", label: "Kargo alıcı şubede" } })
      } else if (trackingSubStatus === "out_for_delivery") {
        setCargoData({ ...response?.data?.data, cargoStatus: { id: "out_for_delivery", label: "Kargo dağıtımda" } })
      } else if (trackingSubStatus === "package_damaged") {
        setCargoData({ ...response?.data?.data, cargoStatus: { id: "package_damaged", label: "Paket hasarlı" } })
      } else if (trackingSubStatus === "package_forwarded_to_another_carrier") {
        setCargoData({ ...response?.data?.data, cargoStatus: { id: "package_forwarded_to_another_carrier", label: "Kargo aracı firmaya verildi" } })
      } else if (trackingSubStatus === "delivery_rescheduled") {
        setCargoData({ ...response?.data?.data, cargoStatus: { id: "delivery_rescheduled", label: "Kargo dağıtım zamanı değişti (yanlış şube vs)" } })
      }
    } else if (trackingStatus === "DELIVERED") {
      if (trackingSubStatus === "delivered") {
        setCargoData({ ...response?.data?.data, cargoStatus: { id: "delivered", label: "Kargo alıcıya teslim edildi" } })
      }
    } else if (trackingStatus === "FAILURE") {
      if (trackingSubStatus === "package_lost") {
        setCargoData({ ...response?.data?.data, cargoStatus: { id: "package_lost", label: "Paket kayboldu" } })
      } else if (trackingSubStatus === "package_undeliverable") {
        setCargoData({ ...response?.data?.data, cargoStatus: { id: "package_undeliverable", label: "Kargo dağıtılamıyor" } })
      }
    } else if (trackingStatus === "RETURNED") {
      if (trackingSubStatus === "return_to_sender") {
        setCargoData({ ...response?.data?.data, cargoStatus: { id: "return_to_sender", label: "Kargo iade edildi" } })
      }
    } else if (trackingStatus === "UNKNOWN") {
      if (trackingSubStatus === "other") {
        setCargoData({ ...response?.data?.data, cargoStatus: { id: "other", label: "Bilinmeyen Durum" } })
      }
    }
  }

  useEffect(() => {
    const status = orderStateList?.find((item) => item.value == orderData?.status)
    setSelectedOrderStatus(status);
  }, [orderStateList])

  useEffect(() => {
    if (orderData) {
      changeDatas();
      if (orderData?.apiTrackingUrl) {
        formatOrderStatus();
      }
    }
  }, [orderData]);

  return (
    <div className={classes.root}>
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />

        <div className="grid grid-cols-[75%,20%] gap-4">
          <div>
            <div className="border bg-white p-3 shadow-sm rounded-md ">
              <div className="bg-gray-100 border-l-2 border-gray-400 flex items-center  gap-3 p-2">
                <span className="">
                  <IoMdInformationCircleOutline size={25} />
                </span>
                <span>
                  {
                    orderStateList?.find(
                      (item) => item.value === orderData?.status
                    )?.label
                  }{" "}
                  - {orderSummary.totalCount} adet
                </span>
              </div>
            </div>
            <div className="my-3 border bg-white p-3 shadow-sm rounded-md ">
              <Table hovered responsive>
                <thead>
                  <tr>
                    <th style={{ background: "#EEF2F6" }}>Ürün</th>
                    <th style={{ background: "#EEF2F6" }}>Adet</th>
                    <th style={{ background: "#EEF2F6" }}>Fiyat</th>
                    <th style={{ background: "#EEF2F6" }}>Toplam Tutar</th>
                  </tr>
                </thead>
                <tbody>
                  {orderData?.basket?.map((item, index) => {
                    return <BasketCard key={index} data={item} />;
                  })}
                </tbody>
              </Table>
              <div className="flex flex-1 flex-row space-x-3">
                <div className="flex flex-col space-y-1">
                  <label className="font-semibold">Sipariş Durumu</label>

                  <Select
                    className="w-72"
                    placeholder="Sipariş Durumu"
                    value={selectedOrderStatus}
                    onChange={(value) => setSelectedOrderStatus(value)}
                    options={orderStateList}
                  />
                </div>

                <div className="flex flex-col space-y-1 justify-end">
                  <label className="font-semibold"> </label>

                  {!loading ?
                    <button
                      onClick={() =>
                        handleUpdateOrder({
                          status: selectedOrderStatus.value,
                          timelines: [
                            ...orderData?.timelines,
                            {
                              id: orderData?.timelines?.length + 1,
                              type: selectedOrderStatus.value,
                              date: new Date().toString(),
                            },
                          ],
                        })
                      }
                      className="px-3 py-2 rounded buttonPurple"
                    >
                      Kaydet
                    </button> : <CustomLoading
                      type={"clip"}
                      size={16}
                      color={"white"}
                      className={"px-5 py-2 rounded bgPurple"}
                    />}
                </div>
              </div>
            </div>
            {/* Müşteri ise */}
            <div className="border my-3 p-3 shadow-sm rounded-md bg-white relative">
              <h6 className="flex items-center gap-2 p-2 afterLine">
                <FaUserAlt color="#000000" />
                Müşteri
              </h6>

              <div onClick={() => navigate(`/userDetail?id=${orderData?.user?._id}`)} className="absolute top-2.5 right-4 cursor-pointer">
                <FaArrowUpRightFromSquare />
              </div>

              <div className="grid grid-cols-3">
                <div className="border-r p-2 border-gray-200">
                  <h6 className="text-purple-800">
                    {(orderData?.user?.name &&
                      orderData?.user?.surname &&
                      `${orderData?.user?.name} ${orderData?.user?.surname}`) ||
                      (orderData?.user?.name && `${orderData?.user?.name} -`) ||
                      (orderData?.user?.name &&
                        `- ${orderData?.user?.surname}`) ||
                      "-"}
                  </h6>
                  <p>{orderData?.user?.email}</p>
                  <p>{orderData?.user?.phoneNumber}</p>
                </div>
                <div className="border-r border-gray-200 p-2">
                  <div className="flex items-center justify-between">
                    <h5>Sevkiyat Adresi</h5>
                    {/*<PiNotePencil size={20} color="#000000" />*/}
                  </div>
                  <p>{orderData?.shippingAddress?.nameSurname}</p>
                  <p className="text-gray-600">
                    {`${orderData?.shippingAddress?.address} ${orderData?.shippingAddress?.street}/${orderData?.shippingAddress?.district}/${orderData?.shippingAddress?.city}`}
                  </p>
                </div>
                <div className="p-2">
                  <div className="flex items-center justify-between">
                    <h5>Fatura Adresi</h5>
                    {/*<PiNotePencil size={20} color="#000000" />*/}
                  </div>
                  <p>{orderData?.billingAddress?.nameSurname}</p>
                  <p className="text-gray-600">
                    {`${orderData?.billingAddress?.address} ${orderData?.billingAddress?.street}/${orderData?.billingAddress?.district}/${orderData?.billingAddress?.city}`}
                  </p>
                </div>
              </div>
            </div>

            {/* Satıcı ise */}
            {orderData?.sellers?.map(seller => {
              return (
                <div className="border my-3 p-3 shadow-sm rounded-md bg-white relative">
                  <h6 className="flex items-center gap-2 p-2 afterLine">
                    <FaUserAlt color="#000000" />
                    Satıcı
                  </h6>

                  {seller?.role === "SELLER" ?
                    <div onClick={() => navigate(`/satici-detay?id=${seller?._id}`)} className="absolute top-2.5 right-4 cursor-pointer">
                      <FaArrowUpRightFromSquare />
                    </div> : <></>}

                  <div className="grid grid-cols-3">
                    <div className="border-r p-2 border-gray-200">
                      <h6 className="text-purple-800">
                        {(seller?.name &&
                          seller?.surname &&
                          `${seller?.name} ${seller?.surname}`) ||
                          (seller?.name && `${seller?.name} -`) ||
                          (seller?.name &&
                            `- ${seller?.surname}`) ||
                          "-"}
                      </h6>
                      <p>{seller?.email}</p>
                      <p>{seller?.phoneNumber}</p>
                    </div>
                    <div className="border-r border-gray-200 p-2">
                      <div className="flex items-center justify-between">
                        <h5>Komisyon Oranı</h5>
                        {/*<PiNotePencil size={20} color="#000000" />*/}
                      </div>

                      <p className="text-gray-600">
                        {seller?.commissionRate || saleSettingsData?.sellerCommissionRate} %
                      </p>
                    </div>
                    <div className="p-2">
                      <div className="flex items-center justify-between">
                        <h5>Satıcı Adresi</h5>
                        {/*<PiNotePencil size={20} color="#000000" />*/}
                      </div>
                      <p className="text-gray-600">
                        {`${seller?.openAddress} ${seller?.district}/${seller?.city}`}
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-1 flex-row space-x-3 items-center mt-2">
                    <h6>
                      Satıcı Ürünleri
                    </h6>

                    <div className="flex flex-1 h-1 mb-1.5 rounded" style={{ backgroundColor: "#EEF2F6" }} />
                  </div>
                  <Table hovered responsive>
                    <thead>
                      <tr>
                        <th style={{ background: "#EEF2F6" }}>Ürün</th>
                        <th style={{ background: "#EEF2F6" }}>Adet</th>
                        <th style={{ background: "#EEF2F6" }}>Fiyat</th>
                        <th style={{ background: "#EEF2F6" }}>Toplam Tutar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderData?.basket?.map((item, index) => {
                        return <BasketCard key={index} data={item} seller={seller} />;
                      })}
                    </tbody>
                  </Table>
                </div>
              )
            })}

            {/* Zaman Çizelgesi */}
            <div className="border bg-white shadow-sm rounded-md p-3">
              <div className="flex items-center gap-2 p-2 afterLine2">
                <CiClock2 size={20} />
                <h5 className="mt-2 ">Zaman Çizelgesi</h5>
              </div>
              <div className="flex flex-col gap-3 p-5">
                {orderData?.timelines
                  ?.sort((a, b) => new Date(b.id) - new Date(a.id))
                  ?.map((timeline) => {
                    return <TimelineCard key={timeline?.id} data={timeline} />;
                  })}
              </div>
            </div>
          </div>

          {/* 
            //TODO: Right Side
          */}
          <div className="flex flex-col gap-4">
            <div className="border  p-3 shadow-sm rounded-md bg-white">
              <h5 className="pt-2">Sipariş Özeti</h5>
              <hr />
              <p>
                {moment(orderData?.createdAt).format("LL")},
                {` ${new Date(orderData?.createdAt).getHours() < 10
                  ? "0" + new Date(orderData?.createdAt).getHours()
                  : new Date(orderData?.createdAt).getHours()
                  }`}
                :
                {`${new Date(orderData?.createdAt).getMinutes() < 10
                  ? "0" + new Date(orderData?.createdAt).getMinutes()
                  : new Date(orderData?.createdAt).getMinutes()
                  }`}
              </p>
              <div className="grid grid-cols-2">
                <p className="text-gray-500">Toplam Tutar</p>
                <p>{orderSummary.totalPrice} TL</p>
                <p className="text-gray-500">Kargo Ücreti</p>
                <p>{orderData?.cargoPrice || 0} TL</p>
                <p className="text-gray-500">Hizmet Bedeli</p>
                <p>{orderData?.serviceFee || 0} TL</p>
                <p className="text-gray-500">KDV</p>
                <p>{orderData?.kdv || 0} TL</p>
                <p className="text-gray-500">Toplam Tutar</p>
                <p>{orderSummary.totalPrice} TL</p>
                <p className="text-gray-500">Kazanç</p>
                <p>{(orderSummary.totalPrice -
                  (orderSummary.totalPrice * (saleSettingsData?.sellerCommissionRate) / 100)
                  - (orderData?.cargoPrice || 0)
                  - (orderData?.serviceFee || 0)
                  - (orderData?.kdv || 0)).toFixed(2)} TL</p>
              </div>
            </div>

            {cargoData ?
              <div className="border  p-3 shadow-sm rounded-md bg-white ">
                <h5 className="pt-2">Kargo Detayı</h5>
                <hr />
                <div className="grid grid-cols-2">
                  <p className="text-gray-500">Taşıyıcı Firma</p>
                  <p>{cargoData?.oProviderCode}</p>
                </div>
                <div className="grid grid-cols-2">
                  <p className="text-gray-500">Kargo Durumu</p>
                  <p>{cargoData?.cargoStatus?.label}</p>
                </div>
                <div className="grid grid-cols-2">
                  <p className="text-gray-500">Son Güncelleme Tarihi</p>
                  <p>{moment(cargoData?.lastUpdatedAt).format("LL")}</p>
                </div>
                <div className="grid grid-cols-2">
                  <p className="text-gray-500">Son Konum</p>
                  <p>{cargoData?.currentLocation}</p>
                </div>
                <div className="flex flex-1">
                  <a target="_blank" href={orderData?.trackingUrl}>
                    Kargo Takip Bağlantısı
                  </a>
                </div>
              </div> : <></>}

            <div className="border  p-3 shadow-sm rounded-md bg-white ">
              <h5 className="pt-2">Ödemeler</h5>
              <hr />
              <div className="grid grid-cols-2">
                <p className="text-gray-500">Toplam Tutar</p>
                <p>{orderSummary.totalPrice} TL</p>
              </div>
              <div className="flex">
                <AiFillCreditCard size={20} /> ****{" "}
                {orderData?.creditCard?.number?.slice(15, 19)} Tek Çekim
              </div>
            </div>

            <div className="border  p-3 shadow-sm rounded-md bg-white">
              <h5 className="pt-2">Dönüşüm Detayları</h5>
              <hr />
              <div>
                <p className="text-gray-500">Sipariş Kaynağı</p>
                <p>
                  <span className="text-gray-500">Cihaz</span> Akıllı Telefon
                </p>
                <p>
                  <span className="text-gray-500">Oturum Süresi</span> 21 Dk
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default OrderDetail;
