import { Cross2Icon, TextAlignCenterIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { useQuery } from "react-query";
import * as Queries from "../../utils/queries";
import Select from "react-select";
import { IoFilterOutline } from "react-icons/io5";

const FilterPopover = ({ searchQuery, setSearchQuery, filter, setFilter }) => {
  const { data: brandsData } = useQuery(
    ["LIST_BRANDS"],
    () => Queries.getBrands("page=1&limit=1000"),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: {
        page: [],
      },
    }
  );

  const { data: modelsData } = useQuery(
    [
      "LIST_MODELS",
      {
        page: 1,
        limit: 200,
      },
    ],
    () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      return Queries.listModels(
        new URLSearchParams({
          page: 1,
          limit: 200,
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalModels: 0,
        totalPage: 0,
      },
      keepPreviousData: false,
      onError: (err) => {
        console.log("err", err);
      },
    }
  );

  const handleReset = async () => {
    setSearchQuery({
      ...searchQuery,
      brandContaining: "",
      brandLabel: "",
      parentModelContaining: "",
      parentModelLabel: "",
    });

    setFilter({
      brandContaining: "",
      brandLabel: "",
      parentModelContaining: "",
      parentModelLabel: "",
    });
  };

  return (
    <>
      <div className="flex gap-3 items-center">
        <div className="flex flex-col gap-2">
          <div className="font-semibold">Marka</div>
          <div className="flex gap-3">
            <select
              className="px-4 py-2 borderGray rounded w-40"
              value={filter.brandContaining}
              onChange={(e) =>
                setFilter({ ...filter, brandContaining: e.target.value })
              }
            >
              <option
                disabled
                value={""}
                selected={filter.brandContaining === ""}
              >
                Seçiniz
              </option>
              <option value={true} selected={filter.brandContaining === true}>
                İçeren
              </option>
              <option value={false} selected={filter.brandContaining === false}>
                İçermeyen
              </option>
            </select>

            {brandsData?.page?.length !== 0 && (
              <Select
                className="w-40"
                isSearchable
                value={
                  filter.brandLabel && {
                    value: filter.brandLabel,
                    label: brandsData?.page.find(
                      (item) => item._id === filter.brandLabel
                    )?.title,
                  }
                }
                defaultValue={filter.brandLabel}
                onChange={(e) => setFilter({ ...filter, brandLabel: e.value })}
                options={brandsData?.page?.map((brand) => {
                  return { value: brand._id, label: brand?.title };
                })}
              />
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2 ">
          <div className="font-semibold">Üst Model</div>
          <div className="flex gap-3 ">
            <select
              className="px-4 py-2 borderGray rounded w-40"
              value={filter.parentModelContaining}
              onChange={(e) =>
                setFilter({
                  ...filter,
                  parentModelContaining: e.target.value,
                })
              }
            >
              <option
                disabled
                value={""}
                selected={filter.parentModelContaining === ""}
              >
                Seçiniz
              </option>
              <option
                value={true}
                selected={filter.parentModelContaining === true}
              >
                İçeren
              </option>
              <option
                value={false}
                selected={filter.parentModelContaining === false}
              >
                İçermeyen
              </option>
            </select>

            {modelsData?.page?.length !== 0 && (
              <Select
                className="w-40"
                isSearchable
                value={
                  filter.parentModelLabel && {
                    value: filter.parentModelLabel,
                    label: modelsData?.page.find(
                      (item) => item._id === filter.parentModelLabel
                    )?.title,
                  }
                }
                defaultValue={filter.parentModelLabel}
                onChange={(e) =>
                  setFilter({ ...filter, parentModelLabel: e.value })
                }
                options={modelsData?.page?.map((model) => {
                  return { value: model?._id, label: model?.title };
                })}
              />
            )}
          </div>
        </div>

        <div className="flex gap-2 items-center mt-4">
          <button
            onClick={() => handleReset()}
            className="px-3 py-2 bg-gray-100 hover:bg-gray-200 rounded"
          >
            Sıfırla
          </button>

          <button
            onClick={() => setSearchQuery({ ...searchQuery, ...filter })}
            className="px-3 py-2 buttonPurple rounded"
          >
            Uygula
          </button>
        </div>
      </div>
    </>
  );
};

export default FilterPopover;
