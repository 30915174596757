import React from "react"
import { useMutation } from "react-query"
import * as Queries from "../utils/queries"
import { useStyles } from "../screens/styles"
import { Controller } from "react-hook-form"
import CircularProgress from "@mui/material/CircularProgress"
import { Box, FormControl } from "@material-ui/core"
import { Typography } from "@mui/material"

const SliderField = ({ item, control }) => {
  const classes = useStyles()

  const uploadImageMutation = useMutation(Queries.uploadMedia)

  return (
    <Controller
      key={item.id}
      name={item.id}
      control={control}
      render={({ field }) => {
        return (
          <>
            <Typography variant="body1">{item.label}</Typography>
            <Box className="MuiFormControl-marginNormal">
              <div className={classes.formImgContainer}  >
                <input
                  type="file"
                  id={item.id}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    uploadImageMutation.mutate(e.target.files[0], {
                      onSuccess: (res) => {
                        console.log("res", res)

                        field.onChange(res.secure_url)
                      },
                      onError: (err) => {
                        console.log("err", err)
                      },
                    })
                  }}
                />
                <label
                  htmlFor={item.id}
                  className={classes.sliderContainer}
                >
                  {uploadImageMutation.isLoading ? (
                    <div className={classes.sliderContainer} >
                      <CircularProgress />
                    </div>
                  ) : field.value ? (
                    <div className={classes.sliderContainer}>
                      <img
                        src={field.value}
                        alt="Slider"
                        className={classes.slider}
                      />
                    </div>
                  ) : (
                    <div className={classes.sliderContainer} />
                  )}
                </label>
              </div>
            </Box>
          </>
        )
      }}
    />
  )
}

export default SliderField
