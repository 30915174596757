import { Dialog, DialogContent, DialogTitle, Snackbar } from "@material-ui/core"
import React from "react"
import { useStyles } from "../styles"
import { useForm } from "react-hook-form"
import DialogField from "../../components/DialogField"
import { useMutation, useQueryClient } from "react-query"
import * as Queries from "../../utils/queries"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import CustomDialogActions from "../../components/CustomDialogActions"

const sliderFields = [
  {
    label: "Fotoğraf",
    id: "url",
    type: "SLIDER",
  },
]

const sliderSchema = yup.object({
  url: yup.string().url().required(),
})

const SliderDialog = ({
  openSliderDialog,
  setOpenSliderDialog,
  initialData,
}) => {
  const disabled = true
  const classes = useStyles()
  const [message, setMessage] = React.useState()
  const queryClient = useQueryClient()

  const { register, watch, control, handleSubmit } = useForm({
    defaultValues: initialData,
    resolver: yupResolver(sliderSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const createSliderMutation = useMutation(Queries.createSlider, {
    onSuccess: (res) => {
      console.log("res", res)
      setOpenSliderDialog(false)
      setMessage("Kapak fotoğrafı oluşturuldu.")
      queryClient.invalidateQueries(["LIST_SLIDERS"])
    },
    onError: (err) => {
      console.log("err", err)
    },
  })

  const updateSliderMutation = useMutation(
    ([sliderId, sliderDto]) => Queries.updateSlider(sliderId, sliderDto),
    {
      onSuccess: (res) => {
        console.log("res", res)
        setOpenSliderDialog(false)
        setMessage("Kapak fotoğrafı güncellendi.")
        queryClient.invalidateQueries(["LIST_SLIDERS"])
      },
      onError: (err) => {
        console.log("err", err)
      },
    }
  )

  const deleteSliderMutation = useMutation(Queries.deleteSlider, {
    onSuccess: (res) => {
      console.log("res", res)
      setOpenSliderDialog(false)
      setMessage("Kapak fotoğrafı silindi.")
      queryClient.invalidateQueries(["LIST_SLIDERS"])
    },
    onError: (err) => {
      console.log("err", err)
    },
  })

  const onValid = (data) => {
    if (initialData) {
      updateSliderMutation.mutate([initialData._id, data])
    } else {
      createSliderMutation.mutate(data)
    }
  }

  const onInvalid = (errors) => {
    setMessage(Object.values(errors)[0].message)
  }

  return (
    <>
      <Dialog
        open={openSliderDialog}
        onClose={() => setOpenSliderDialog(false)}
        fullWidth
      >
        <DialogTitle>{"Kapak Fotoğrafı Detayı"}</DialogTitle>
        <DialogContent>
          {sliderFields.map((item) => {
            return (
              <DialogField
                item={item}
                control={control}
                disabled={disabled}
                register={register}
                watch={watch}
              />
            )
          })}
        </DialogContent>

        <CustomDialogActions
          isUpdate={initialData}
          onDelete={() => {
            const isConfirmed = window.confirm(
              `${initialData.title} isimli kapak fotoğrafını silmek istediğinize emin misiniz?`
            )

            if (isConfirmed) {
              deleteSliderMutation.mutate(initialData._id)
            }
          }}
          onConfirm={handleSubmit(onValid, onInvalid)}
        />
      </Dialog>

      <Snackbar
        open={!!message}
        autoHideDuration={3000}
        onClose={() => setMessage(undefined)}
        message={message}
      />
    </>
  )
}

export default SliderDialog
