const ProductCard = ({ product }) => {
    return (
        <tr className="border-b hover:bg-gray-100">
            <th scope="row" class="px-6 py-3 font-medium textPurple">
                {product._id.toUpperCase()}
            </th>
            <td class="flex flex-row gap-2 items-center px-6 py-3 text-black">
                <img
                    src={product?.productGroup?.photos[0]}
                    alt={product?.productGroup?.name}
                    className="w-10 h-10"
                />
                <div className="flex flex-col">
                    <span>{product?.productGroup?.title}</span>
                </div>
            </td>
            <td class="px-6 py-3 text-black">
                <div>{product?.price ? `₺ ${product?.price}` : `Belirsiz`}</div>
            </td>
            <td class="px-6 py-3 text-black">
            {product?.productGroup?.stock || 0}
            </td>
        </tr>
    )
}

export default ProductCard