import {
    TableCell,
    TableRow,
} from "@material-ui/core";
import { FaArrowUpRightFromSquare } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

const CategoryCard = ({ category }) => {

    const navigate = useNavigate();

    return (
        <TableRow onClick={() => navigate(`/categoriesdetail?id=${category._id}`)} className="hover:bg-gray-100 cursor-pointer">
            <TableCell>{category._id}</TableCell>
            <TableCell>
                <img
                    className='w-16 h-16'
                    alt={category._id}
                    src={category.photo}
                />
            </TableCell>
            <TableCell>{category.title}</TableCell>
        </TableRow>
    )
}

export default CategoryCard