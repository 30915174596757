import { Button, DialogActions } from "@material-ui/core"
import React from "react"

const CustomDialogActions = ({ isUpdate, onDelete, onConfirm }) => {
  return (
    <DialogActions>
      {isUpdate && (
        <Button
          onClick={() => {
            if (false) {
              return alert(
                "Bu işlemi yapabilmek için gerekli izinlere sahip değilsiniz."
              )
            }

            return onDelete()
          }}
          color="secondary"
        >
          Sil
        </Button>
      )}
      <div style={{ flex: 1 }} />
      <Button onClick={onConfirm}             style={{backgroundColor: "#000000", color: "white"}}
>
        {isUpdate ? "Güncelle" : "Kaydet"}
      </Button>
    </DialogActions>
  )
}

export default CustomDialogActions
