import React from "react"
import { useMutation } from "react-query"
import * as Queries from "../utils/queries"
import { useStyles } from "../screens/styles"
import { Controller } from "react-hook-form"
import CircularProgress from "@mui/material/CircularProgress"
import { Box } from "@material-ui/core"

const PhotoField = ({ item, control }) => {
  const classes = useStyles()

  const uploadImageMutation = useMutation(Queries.uploadMedia)

  return (
    <Controller
      key={item.id}
      name={item.id}
      control={control}
      render={({ field }) => {
        return (
          <Box className="MuiFormControl-marginNormal">
            <div className={classes.formImgContainer} >
              <input
                type="file"
                id="user-photo"
                style={{ display: "none" }}
                onChange={(e) => {
                  uploadImageMutation.mutate(e.target.files[0], {
                    onSuccess: (res) => {
                      field.onChange(res.secure_url)
                    },
                    onError: (err) => {
                      console.log("err", err)
                    },
                  })
                }}
              />
              <label
                htmlFor="user-photo"
                className={classes.userPhotoLargeContainer}
              >
                {uploadImageMutation.isLoading ? (
                  <div className={classes.userPhotoLargeContainer}>
                    <CircularProgress />
                  </div>
                ) : field.value ? (
                  <img
                    src={field.value}
                    alt="Photo"
                    className={classes.userPhotoLarge}
                  />
                ) : (
                  <div className={classes.userPhotoLargeContainer}  />
                )}
              </label>
            </div>
          </Box>
        )
      }}
    />
  )
}

export default PhotoField
