import { TableCell, TableRow } from '@material-ui/core'
import { BiPencil } from 'react-icons/bi'
import * as Queries from '../../utils/queries'
import toast from 'react-hot-toast'
import { FaTrash } from 'react-icons/fa6'

const DiscountCouponCard = ({ discountCouponData, discountCouponsRefetch, setSelectedDiscountCoupon }) => {

    const handleDeleteDiscountCoupon = async () => {
        const response = await Queries.deleteDiscountCoupon(discountCouponData?._id);
        if (response?._id) {
            toast.success("İndirim kuponu başarılı bir şekilde silindi");
        } else {
            toast.error("İndirim kuponu silinirken bir hata oluştu. Lütfen tekrar deneyiniz")
        }
        discountCouponsRefetch();
    }

    return (
        <TableRow className='hover:bg-gray-100'>
            <TableCell>
                {discountCouponData?._id}
            </TableCell>
            <TableCell>{discountCouponData?.couponCode}</TableCell>
            <TableCell>{discountCouponData?.couponType === "Yüzdelik" ? `%${discountCouponData?.discountRate}` : `${discountCouponData?.discountRate}₺`}</TableCell>
            <TableCell align="right" className='w-72 flex flex-row space-x-1'>
                <button onClick={() => setSelectedDiscountCoupon(discountCouponData)}>
                    <BiPencil size={20} />
                </button>

                <button onClick={() => handleDeleteDiscountCoupon()}>
                    <FaTrash size={20} color='red' />
                </button>
            </TableCell>
        </TableRow >
    )
}

export default DiscountCouponCard