import { Cross2Icon, InfoCircledIcon } from '@radix-ui/react-icons'
import React from 'react'

const AddressCard = ({ address, selectedAddress, setSelectedAddress }) => {
    return (
        <div className={`flex flex-col p-2 justify-center rounded border border-gray-50 ${selectedAddress?._id !== address._id ? "hover:bg-gray-50" : "border border-gray-50"}`}>
            <div className={`flex flex-1 flex-row`}>
                <div className='flex flex-1 items-center'>
                    {address.address}
                </div>
                <div onClick={() => {
                    if (selectedAddress?._id !== address._id) { setSelectedAddress(address) }
                    else { setSelectedAddress(null) }
                }} className='p-2 rounded-full hover:bg-gray-200 cursor-pointer'>
                    {selectedAddress?._id === address._id ? <Cross2Icon /> : <InfoCircledIcon />}
                </div>
            </div>

            {selectedAddress?._id === address._id && (
                <div className={`flex flex-1 flex-col space-y-1 pt-2 mt-1 ${selectedAddress?._id === address._id && "border-t border-gray-100"}`}>
                    <div className='flex flex-col mt-3 border-b border-gray-200 pb-2'>
                        <div style={{ flex: 1 }} className='font-semibold'>Adres Türü</div>
                        <div style={{ flex: 1 }}>{address.typeBilling || "-"}</div>
                    </div>
                    <div className='flex flex-col mt-3 border-b border-gray-200 pb-2'>
                        <div style={{ flex: 1 }} className='font-semibold'>Ad - Soyad</div>
                        <div style={{ flex: 1 }}>{address.nameSurname || "-"}</div>
                    </div>
                    <div className='flex flex-col mt-3 border-b border-gray-200 pb-2'>
                        <div style={{ flex: 1 }} className='font-semibold'>Telefon</div>
                        <div style={{ flex: 1 }}>{address.phone || "-"}</div>
                    </div>
                    <div className='flex flex-col mt-3 border-b border-gray-200 pb-2'>
                        <div style={{ flex: 1 }} className='font-semibold'>E-Posta</div>
                        <div style={{ flex: 1 }}>{address.email || "-"}</div>
                    </div>
                    <div className='flex flex-col mt-3 border-b border-gray-200 pb-2'>
                        <div style={{ flex: 1 }} className='font-semibold'>T.C Kimlik Numarası</div>
                        <div style={{ flex: 1 }}>{address.tcIdentity || "-"}</div>
                    </div>
                    <div className='flex flex-col mt-3 border-b border-gray-200 pb-2'>
                        <div style={{ flex: 1 }} className='font-semibold'>Şehir</div>
                        <div style={{ flex: 1 }}>{address.city}</div>
                    </div>
                    <div className='flex flex-col mt-3 border-b border-gray-200 pb-2'>
                        <div style={{ flex: 1 }} className='font-semibold'>İlçe</div>
                        <div style={{ flex: 1 }}>{address.district}</div>
                    </div>
                    <div className='flex flex-col mt-3 border-b border-gray-200 pb-2'>
                        <div style={{ flex: 1 }} className='font-semibold'>Mahalle</div>
                        <div style={{ flex: 1 }}>{address.street}</div>
                    </div>
                    <div className='flex flex-col mt-3 pb-2'>
                        <div style={{ flex: 1 }} className='font-semibold'>Açık Adres</div>
                        <div style={{ flex: 1 }}>{address.addressDefinition}</div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AddressCard