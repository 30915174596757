import { Dialog, DialogContent, DialogTitle } from "@material-ui/core"
import React, { useEffect, useState } from 'react'
import * as Queries from '../../utils/queries'
import toast from "react-hot-toast";

const CommissionRateDialog = ({ sellerData, openDialog, setOpenDialog, getSellerDetail }) => {

    const [formValues, setFormValues] = useState({
        commissionRate: parseInt(sellerData?.commissionRate) || 0,
        serviceFee: parseInt(sellerData?.serviceFee) || 0
    });
    const [loading, setLoading] = useState(false);
    const [clicked, setClicked] = useState(false);

    const handleSubmit = async (e) => {
        e?.preventDefault();
        setLoading(true)
        setClicked(true)

        const response = await Queries.updateUser(sellerData?._id, formValues);
        if (response?._id) {
            toast.success("Satış ayarları başarılı bir şekilde güncellendi");
            await getSellerDetail(sellerData?._id);
        } else {
            toast.error("Satış ayarları güncellenirken bir hata oluştu. Lütfen tekrar deneyiniz")
        }

        setLoading(false)
    }

    useEffect(() => {
        if (sellerData) {
            setFormValues({
                commissionRate: parseInt(sellerData?.commissionRate) || 0,
                serviceFee: parseInt(sellerData?.serviceFee) || 0
            });
        }
    }, [sellerData])


    return (
        <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}>

            <DialogTitle>{"Satış Ayarları"}</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit} className="flex flex-1 flex-col space-y-3">
                    <div className="flex flex-1 flex-col space-y-1">
                        <label className="font-semibold">Komisyon Oranı</label>
                        <input
                            type="number"
                            name="commissionRate"
                            min={0}
                            max={100}
                            required
                            value={formValues.commissionRate}
                            onChange={(e) => setFormValues({ ...formValues, commissionRate: e.target.value })}
                            className="w-72 flex flex-1 p-2 rounded borderGray"
                        />
                    </div>

                    <div className="flex flex-1 flex-col space-y-1">
                        <label className="font-semibold">Hizmet Bedeli</label>
                        <input
                            type="number"
                            name="serviceFee"
                            min={0}
                            max={100}
                            required
                            value={formValues.serviceFee}
                            onChange={(e) => setFormValues({ ...formValues, serviceFee: e.target.value })}
                            className="w-72 flex flex-1 p-2 rounded borderGray"
                        />
                    </div>

                    <div className="flex flex-1 flex-row space-x-3">
                        <button type="submit" className="px-3 py-2 rounded buttonPurple">
                            Kaydet
                        </button>

                        <button type="button" onClick={() => setOpenDialog(false)} className="px-3 py-2 rounded bg-gray-100 hover:bg-gray-200">
                            Vazgeç
                        </button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default CommissionRateDialog