import React, { useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  SwipeableDrawer,
  Typography,
  Box,
} from "@material-ui/core"
import { useQuery } from "react-query"
import * as Queries from "../../utils/queries"

const SalesDrawer = ({ showDrawer, setShowDrawer, selectedSeller }) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [page, setPage] = useState(1)

  const {
    data: { page: sales, totalSales },
  } = useQuery(
    ["LIST_SALES", searchQuery, page, selectedSeller],
    () => {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0

      return Queries.listSales(
        new URLSearchParams({
          filterName: searchQuery,
          page,
          user: selectedSeller._id,
        }).toString()
      )
    },
    {
      placeholderData: {
        page: [],
        totalSales: 0,
      },
      keepPreviousData: false,
      onError: (err) => {
        console.log("err", err)
      },
    }
  )

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={showDrawer}
      onClose={() => setShowDrawer(false)}
      onOpen={() => setShowDrawer(true)}
      PaperProps={{
        style: {
          minWidth: "75%",
        },
      }}
    >
      <Box
        style={{
          display: "flex",
          padding: "1rem",
          alignItems: "center",
          borderBottom: "1px solid #E0E0E0",
        }}
      >
        <Typography variant="h6">Satışlar</Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Ürün</TableCell>
              <TableCell>Brüt Fiyat</TableCell>
              <TableCell>Stoka Kalan</TableCell>
              <TableCell>Satıcıya Kalan</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sales.map((sale) => (
              <TableRow key={sale._id}>
                <TableCell component="th" scope="row">
                  {sale._id.toUpperCase()}
                </TableCell>
                <TableCell>{sale.productGroup?.title}</TableCell>
                <TableCell>{sale.price} TL</TableCell>
                <TableCell>{sale.price - sale.finalAmount} TL</TableCell>
                <TableCell>{sale.finalAmount} TL</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TablePagination
              count={totalSales}
              page={page - 1}
              rowsPerPageOptions={[{ value: 10 }]}
              rowsPerPage={10}
              onPageChange={(_, newPage) => {
                setPage(newPage + 1)
              }}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </SwipeableDrawer>
  )
}

export default SalesDrawer
