import { Dialog, DialogContent, DialogTitle } from "@material-ui/core"
import React, { useEffect, useState } from 'react'
import * as Queries from '../../utils/queries'
import toast from "react-hot-toast";
import { Form } from 'react-bootstrap'
import { useQuery } from "react-query";
import Select from 'react-select'

const UpdateModelsDialog = ({ sellerData, openDialog, setOpenDialog, getSellerDetail }) => {

    const [searchQuery, setSearchQuery] = useState({
        page: 1,
        limit: 10,
        filterName: ""
    });
    const [sellerModels, setSellerModels] = useState([]);

    const {
        data: { page: models, totalModels, totalPage },
        refetch: modelsRefetch
    } = useQuery(
        ["LIST_MODELS", searchQuery],
        () => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;

            return Queries.listModels(
                new URLSearchParams({
                    ...searchQuery,
                }).toString()
            );
        },
        {
            placeholderData: {
                page: [],
                totalModels: 0,
                totalPage: 0,
            },
            keepPreviousData: false,
            onError: (err) => {
                console.log("err", err);
            },
        }
    );

    const handleSubmit = async () => {
        const response = await Queries.updateUser(sellerData?._id, { models: sellerModels?.map(item => { return item?.value }) });
        if (response?._id) {
            toast.success("Bağlı modeller başarılı bir şekilde güncellendi");
            await getSellerDetail(sellerData?._id);
        } else {
            toast.error("Bağlı modeller güncellenirken bir hata oluştu. Lütfen tekrar deneyiniz")
        }
    }

    const changeSellerModels = async () => {
        const list = [];
        for (let i = 0; i < sellerData?.models?.length; i++) {
            const findModel = models?.find(item => item?._id === sellerData?.models[i]);
            list.push({ value: findModel?._id, label: findModel?.title })
        }
        setSellerModels(list)
    }

    useEffect(() => {
        if (sellerData?.models && models?.length !== 0) {
            changeSellerModels();
        }
    }, [sellerData, models])

    return (
        <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}>

            <DialogTitle>{"Bağlı Modeller"}</DialogTitle>
            <DialogContent>
                <Form.Group className='flex flex-col gap-2 h-max w-72'>
                    <div className='flex flex-col gap-1 mb-3'>
                        <Form.Label>Model</Form.Label>
                        <Select
                            className="w-72"
                            value={sellerModels}
                            isMulti
                            isSearchable
                            onInputChange={e => setSearchQuery({ ...searchQuery, filterName: e })}
                            onChange={(e) =>
                                setSellerModels(e)
                            }
                            options={models?.map(item => { return { value: item?._id, label: item?.title } })}
                        />
                    </div>
                </Form.Group>
                <button onClick={() => handleSubmit()} className="buttonPurple px-4 py-2 my-3 text-center  rounded-md shadow-sm w-full">
                    Güncelle
                </button>
            </DialogContent>
        </Dialog>
    )
}

export default UpdateModelsDialog