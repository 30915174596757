import React, { useEffect, useState } from 'react'
import { Accordion, Form } from 'react-bootstrap'
import { CgClose } from 'react-icons/cg'
import { useQuery } from 'react-query';
import * as Queries from '../../utils/queries'
import Select from 'react-select'
import toast from 'react-hot-toast';
import { ChromePicker } from "react-color"
import { TableCell, TableRow } from '@mui/material';
import { BiPencil } from 'react-icons/bi';
import { GridCloseIcon } from '@mui/x-data-grid';
import { TrashIcon } from '@radix-ui/react-icons';
import { FaTrash } from 'react-icons/fa6';

const ProductattributeCard = ({ id, productGroup, productattributesData, handleUpdateProductGroup, selectedAttribute, setSelectedAttribute }) => {

    const [productattribute, setProductattribute] = useState(null);
    const [productattributeterms, setProductattributeterms] = useState({ value: [] });

    /*const { data: productattributesData } = useQuery(
        ["listAllProductattributes"],
        () => Queries.listAllProductattributes(),
        {
            onError: (err) => {
                console.log("err", err)
            },
            placeholderData: null,
        }
    )*/

    const { data: productattributetermsData, refetch: productattributetermsRefetch } = useQuery(
        ["listAllProductattributeterms", id],
        () => Queries.listAllProductattributeterms(id),
        {
            onError: (err) => {
                console.log("err", err)
            },
            placeholderData: null,
        }
    );

    const getProductattribute = async () => {
        const data = productattributesData?.find(item => item?._id === id);
        setProductattribute(data)
    }

    useEffect(() => {
        if (productGroup?.productattributeterms) {
            setProductattributeterms({ value: productGroup?.productattributeterms?.filter(item => item.attribute === id) || [] })
        }
    }, [productGroup])

    useEffect(() => {
        getProductattribute();
    }, [productattributesData]);

    if (productattribute) {
        return (
            <TableRow>
                <TableCell>{productattribute?.title}</TableCell>
                <TableCell>
                    <div className="flex gap-2 flex-wrap">
                        {productGroup?.productattributeterms?.filter(item => item.attribute === productattribute?._id)?.map(item => {
                            return (
                                <>
                                    {productattribute?.view === "COLOR_PICKER" && (
                                        <div key={item.value} className='w-[30px] h-[30px] flex items-center justify-center m-0.1 rounded border float-left relative hoverMain' style={{ backgroundColor: item.colorCode }}>
                                            <p className="p-3 bg-slate-800 text-white absolute bottom-[50%] hoverMainText rounded-md shadow-sm">
                                                {item.label}
                                            </p>
                                        </div>
                                    ) || productattribute?.view === "LIST" && (
                                        <div key={item.value} className='flex flex-row space-x-2 items-center justify-center px-2 py-1 m-0.1 rounded border float-left'>
                                            <span className=''>
                                                {item.label}
                                            </span>
                                        </div>
                                    )}
                                </>
                            )
                        })}
                    </div>
                </TableCell>
                <TableCell className='flex flex-row space-x-2' align="center">
                    <button
                        onClick={() => {
                            setSelectedAttribute(productattribute);
                            if (selectedAttribute._id === productattribute?._id) {
                                setSelectedAttribute(false);
                            }
                        }}
                    >
                        <BiPencil size={20} />
                    </button>

                    <button onClick={() => handleUpdateProductGroup({
                        attributes: productGroup?.attributes?.filter(item => item !== id) || [],
                        productattributeterms: productGroup?.productattributeterms?.filter(item => item.attribute !== id) || []
                    })}>
                        <FaTrash size={20} color='red' />
                    </button>
                </TableCell>
            </TableRow>
        )
    }
}

export default ProductattributeCard