import React, { useState } from "react";
import {
  Toolbar,
  Typography,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
} from "@material-ui/core";
import { useQuery, useQueryClient } from "react-query";
import { useStyles } from "../styles";
import * as Queries from "../../utils/queries";
import CustomDrawer from "../../components/CustomDrawer";
import "moment/locale/tr";
import { Add as AddIcon, ChevronRightRounded } from "@material-ui/icons";
import ArticleDrawer from "./ArticleDrawer";

const Articles = () => {
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState();
  const [showDrawer, setShowDrawer] = useState(false);
  const [message, setMessage] = useState();

  const [searchQuery, setSearchQuery] = useState();
  const [page, setPage] = useState(1);

  const {
    data,
  } = useQuery(
    ["LIST_ARTICLES", searchQuery, page],
    () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      return Queries.listArticles(
        new URLSearchParams({
          filterName: searchQuery,
          page,
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalArticles: 0,
      },
      keepPreviousData: false,
    }
  );

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className={classes.root}>
      <CustomDrawer />
      <main className={classes.content}>
        <Toolbar />

        <div className="p-3 border rounded-md shadow-sm bg-white flex justify-between">
          <Typography variant="h6">Blog Yazıları</Typography>
          <div style={{ flex: 1 }} />
          <Button
            variant="contained"
            style={{ backgroundColor: "#000000", color: "white" }}
            startIcon={<AddIcon />}
            onClick={() => {
              if (false) {
                return alert(
                  "Bu işlemi yapabilmek için gerekli izinlere sahip değilsiniz."
                );
              }

              setSelectedArticle(undefined);
              setShowDrawer(true);
            }}
          >
            Blog Yaz
          </Button>
        </div>

        <div className="p-3 bg-white rounded shadow-sm border">
          <TableContainer component={Paper}>
            <Table>
              <TableHead className="bg-[#EEF2F6]">
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Başlık</TableCell>
                  <TableCell>Açıklama</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.page?.map((article) => (
                  <TableRow key={article.id} className="hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      setSelectedArticle(article);
                      setShowDrawer(true);
                    }}>
                    <TableCell component="th" scope="row">
                      {article._id.toUpperCase()}
                    </TableCell>
                    <TableCell>{article.title}</TableCell>
                    <TableCell>{article.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TablePagination
                  count={data?.totalArticles}
                  page={page - 1}
                  rowsPerPageOptions={[{ value: 10 }]}
                  rowsPerPage={10}
                  onPageChange={(_, newPage) => {
                    setPage(newPage + 1);
                  }}
                />
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </main>

      {showDrawer && (
        <ArticleDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          selectedArticle={selectedArticle}
          setMessage={setMessage}
        />
      )}

      <Snackbar
        open={!!message}
        autoHideDuration={3000}
        onClose={() => setMessage(undefined)}
        message={message}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="İşlem başarılı."
      />
    </div>
  );
};

export default Articles;
