import React, { useState } from "react";
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@material-ui/core";
import { useQuery } from "react-query";
import { useStyles } from "../styles";
import * as Queries from "../../utils/queries";
import CustomDrawer from "../../components/CustomDrawer";
import { IoMdSearch } from "react-icons/io";
import { Pagination } from '@mui/material'
import { Toaster } from "react-hot-toast";
import { Add as AddIcon } from "@material-ui/icons";
import DiscountCouponCard from "./DiscountCouponCard";
import AddDiscountCouponDialog from './AddDiscountCouponDialog'
import UpdateDiscountCouponDialog from "./UpdateDiscountCouponDialog";

const DiscountCoupons = () => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedDiscountCoupon, setSelectedDiscountCoupon] = useState(null);
    const [searchQuery, setSearchQuery] = useState({
        filterName: "",
        page: 1,
        limit: 10
    });

    const {
        data,
        refetch: discountCouponsRefetch
    } = useQuery(
        ["LIST_DISCOUNTCOUPONS", searchQuery],
        () => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;

            return Queries.listDiscountCoupons(
                new URLSearchParams({
                    ...searchQuery
                }).toString()
            );
        },
        {
            placeholderData: {
                page: [],
                totalCount: 0,
                totalPage: 0,
            },
            keepPreviousData: false,
        }
    );

    const handleSearch = (event) => {
        setSearchQuery({ ...searchQuery, filterName: event.target.value });
    };

    return (
        <div className="flex flex-1 bg-white">
            <CustomDrawer />
            <main className={classes.content}>
                <Toolbar />
                <Toaster />
                <div className="flex flex-1 flex-row space-x-4 items-center px-3 bg-white p-3 shadow-sm rounded-md border">
                    <div className="flex flex-1 text-xl font-semibold">İndirim Kuponları</div>

                    <button onClick={() => setOpenDialog(true)} className="flex flex-row space-x-2 items-center justify-center buttonPurple px-3 py-2 rounded">
                        <AddIcon />
                        <span>İndirim Kuponu Oluştur</span>
                    </button>
                </div>

                <div className="flex justify-between items-center bg-white p-3 shadow-sm rounded-md border ">
                    <div className="flex items-center border border-gray-200 rounded-md">
                        <IoMdSearch size={20} className="ml-3" />{" "}
                        <input
                            type="text"
                            placeholder="Ara"
                            variant="outlined"
                            size="small"
                            value={searchQuery.filterName}
                            onChange={handleSearch}
                            className={classes.searchInput}
                        />
                    </div>
                </div>

                <div className="p-3 bg-white rounded-md border shadow-sm ">
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead className="bg-[#EEF2F6]">
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Kupon Kodu</TableCell>
                                    <TableCell>İndirim</TableCell>
                                    <TableCell align="right">{" "}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.page?.map(discountCoupon => {
                                    return (
                                        <DiscountCouponCard
                                            key={discountCoupon?._id}
                                            discountCouponData={discountCoupon}
                                            discountCouponsRefetch={discountCouponsRefetch}
                                            setSelectedDiscountCoupon={setSelectedDiscountCoupon}
                                        />
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div className="flex flex-1 mt-3 justify-center items-center">
                        <Pagination
                            count={Math.ceil(data?.totalPage)}
                            page={searchQuery.page}
                            onChange={(_, value) => setSearchQuery({ ...searchQuery, page: value })}
                            shape="rounded"
                            variant="outlined"
                            color="primary"
                        />
                    </div>
                </div>
            </main>

            {openDialog && (
                <AddDiscountCouponDialog
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    discountCouponsRefetch={discountCouponsRefetch}
                />
            )}

            {selectedDiscountCoupon && (
                <UpdateDiscountCouponDialog
                    discountCouponsRefetch={discountCouponsRefetch}
                    selectedDiscountCoupon={selectedDiscountCoupon}
                    setSelectedDiscountCoupon={setSelectedDiscountCoupon}
                />
            )}
        </div>
    );
};

export default DiscountCoupons;
