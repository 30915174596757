import { TableCell, TableRow } from '@material-ui/core'
import { BiPencil } from 'react-icons/bi'
import * as Queries from '../../utils/queries'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'
import noPhoto from '../../utils/images/noPhoto.png'

const ProductsPendingApprovalCard = ({ productsPendingApprovalData, productsPendingApprovalsRefetch }) => {

    const [productGroupData, setProductGroupData] = useState(null)

    const handleDelete = async () => {
        const response = await Queries.deleteProductsPendingApproval(productsPendingApprovalData?._id);
        if (response?._id) {
            toast.success("Silme işlemi başarılı bir şekilde gerçekleştirildi");
        } else {
            toast.error("Silme işlemi yapılırken bir hata oluştu");
        }
        productsPendingApprovalsRefetch();
    }

    const getProductGroupData = async (id) => {
        const response = await Queries.getProductGroupDetails(id);
        if (response?.productGroup) {
            setProductGroupData(response?.productGroup);
        }
    }

    const handleAccept = async () => {
        if (productsPendingApprovalData?.type === "productGroup") {
            const response = await Queries.createGroup({
                ...productsPendingApprovalData?.productGroup,
                createdBy: productsPendingApprovalData?.createdBy?._id
            });

            if (response?._id) {
                const deleteResponse = await Queries.deleteProductsPendingApproval(productsPendingApprovalData?._id);
                if (deleteResponse?._id) {
                    toast.success("Ürün Oluşturma İsteği Onaylandı");
                } else {
                    toast.error("Ürün Oluşturma İsteği Onaylanırken Bir Hata Oluştu");
                }
            } else {
                toast.error("Ürün Oluşturma İsteği Onaylanırken Bir Hata Oluştu");
            }
        } else {
            console.log("productsPendingApprovalData",productsPendingApprovalData)
            const response = await Queries.createAdvert({
                stock: `${productsPendingApprovalData?.stock}`,
                data: {
                    ...productsPendingApprovalData?.advert,
                    user: productsPendingApprovalData?.createdBy?._id,
                    price: parseInt(productsPendingApprovalData?.advert?.price),
                    serviceFee: parseInt(productsPendingApprovalData?.advert?.serviceFee),
                    commission: parseInt(productsPendingApprovalData?.advert?.commission),
                    finalAmount: parseInt(productsPendingApprovalData?.advert?.finalAmount),
                }
            });
            if (response?.slug) {
                const deleteResponse = await Queries.deleteProductsPendingApproval(productsPendingApprovalData?._id);

                if (deleteResponse?._id) {
                    toast.success("Ürün Stoğu Oluşturma İsteği Onaylandı");
                } else {
                    toast.error("Ürün Stoğu Oluşturma İsteği Onaylanırken Bir Hata Oluştu");
                }
            } else {
                toast.error("Ürün Stoğu Oluşturma İsteği Onaylanırken Bir Hata Oluştu");
            }
        }
        productsPendingApprovalsRefetch();
    }

    useEffect(() => {
        if (productsPendingApprovalData?.type === "advert") {
            getProductGroupData(productsPendingApprovalData?.advert?.productGroup);
        }
    }, [productsPendingApprovalData])

    return (
        <TableRow className='hover:bg-gray-100'>
            <TableCell>
                {productsPendingApprovalData?._id}
            </TableCell>
            <TableCell>{
                productsPendingApprovalData?.type === "productGroup" ?
                    <div className='w-max flex flex-row space-x-1 items-center'>
                        <img src={productsPendingApprovalData?.productGroup?.photos[0] || noPhoto} className='w-16 h-16' />

                        <div className='flex flex-col space-y-1 items-center justify-center'>
                            <span className='font-semibold'>{productsPendingApprovalData?.productGroup?.title}</span>
                            <span>{productsPendingApprovalData?.productGroup?.subtitle}</span>
                        </div>
                    </div> : <div className='w-max flex flex-row space-x-1 items-center'>
                        <img src={productGroupData?.photos[0] || noPhoto} className='w-16 h-16' />

                        <div className='flex flex-col space-y-1 justify-center'>
                            <span className='font-semibold'>{productGroupData?.title}</span>
                            <span>{productGroupData?.subtitle}</span>
                            <span>{productsPendingApprovalData?.advert?.size?.value} Numara</span>
                        </div>
                    </div>
            }</TableCell>
            <TableCell>{productsPendingApprovalData?.createdBy?.email}</TableCell>
            <TableCell>{productsPendingApprovalData?.stock || "-"}</TableCell>
            <TableCell>{productsPendingApprovalData?.price || productsPendingApprovalData?.productGroup?.price}</TableCell>
            <TableCell>{productsPendingApprovalData?.type === "productGroup" ? "Ürün" : "Ürün Stoğu"}</TableCell>
            <TableCell>
                <div className='flex flex-col space-y-2'>
                    <button onClick={() => handleAccept()} className='w-max px-3 py-2 rounded bg-green-600 hover:bg-green-700 text-white'>Onayla</button>
                    <button onClick={() => handleDelete()} className='w-max px-3 py-2 rounded bg-red-600 hover:bg-red-700 text-white'>Reddet</button>
                </div>
            </TableCell>
        </TableRow >
    )
}

export default ProductsPendingApprovalCard