import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Snackbar,
  SwipeableDrawer,
} from "@material-ui/core";
import { useMutation } from "react-query";
import * as Queries from "../utils/queries";
import ReactQuill from 'react-quill'
import toast from "react-hot-toast";

const AgreementsDrawer = ({ selectedItem, closeDrawer }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [agreementValue, setAgreementValue] = useState("")

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const getAgreement = async () => {
    const response = await Queries.getAgreement(selectedItem.type);
    console.log(response)
    if (response?._id) {
      setAgreementValue(response?.content)
    }
  }

  useEffect(() => {
    getAgreement();
  }, [])

  const saveAgreementsMutation = useMutation(Queries.saveAgreement, {
    onSuccess: (res) => {
      closeDrawer();
      setTimeout(() => {
        alert("Kaydedildi.");
      }, 100);
    },
    onError: (err) => {
      console.log("err", err);
    },
  });

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={true}
      onClose={() => closeDrawer()}
      PaperProps={{
        style: {
          minWidth: "75%",
        },
      }}
    >
      <div
        style={{
          margin: 20,
        }}
      >
        <>
          <Typography variant="h6" gutterBottom>
            {selectedItem.title}
          </Typography>

          <ReactQuill
            className="h-56 mb-12"
            value={agreementValue}
            onChange={value => setAgreementValue(value)}
          />

          {/*
          <Editor
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={getAgreementQuery.data.content}
            init={{
              height: 250,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          */}
 
          <Button
            variant="contained"
            fullWidth
            size="large"
            onClick={() => {
              if (agreementValue && agreementValue !== "<p><br></p>") {
                saveAgreementsMutation.mutate({
                  type: selectedItem.type,
                  content: agreementValue,
                });
              } else {
                toast.error("Lütfen Boş Bırakmayınız")
              }
            }}
            style={{
              backgroundColor: "#555",
              color: "white",
              marginTop: 15,
            }}
          >
            Kaydet
          </Button>

          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            message={openSnackbar}
          />
        </>
      </div>
    </SwipeableDrawer>
  );
};

export default AgreementsDrawer;
