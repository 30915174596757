import React, { useEffect, useRef, useState } from "react";
import { Toolbar } from "@material-ui/core";
import { Pagination } from '@mui/material'
import { useStyles } from "../styles";
import CustomDrawer from "../../components/CustomDrawer";
import { Form, InputGroup } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import * as Queries from '../../utils/queries'
import Select from 'react-select'
import toast from "react-hot-toast";
import DetailsPagesProductCard from "../../components/DetailsPagesProductCard";
import { CameraIcon, MagnifyingGlassIcon, PlusIcon } from "@radix-ui/react-icons";

const ModelDetail = () => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const [modelData, setModelData] = useState(null);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    limit: 10,
    filterName: "",
    sorted: { value: "new", label: "En Yeni" }
  });
  const fileInputRef = useRef(null);

  const sortedList = [
    { value: "new", label: "En Yeni" },
    { value: "price_asc", label: "Fiyat Artan" },
    { value: "price_desc", label: "Fiyat Azalan" }
  ]

  const { data: model, refetch: modelRefetch } = useQuery(
    ["getModelDetails", searchParams.get("id")],
    () => Queries.getModelDetails(searchParams.get("id")),
    {
      onError: (err) => {
        console.log("err", err)
      },
      placeholderData: null,
    }
  );

  const { data: brandsData } = useQuery(
    ["LIST_BRANDS"],
    () => Queries.getBrands("page=1&limit=1000"),
    {
      onError: (err) => {
        console.log("err", err)
      },
      placeholderData: {
        page: [],
      },
    }
  )

  const {
    data: { page: productGroupsData, totalCount, totalPage },
  } = useQuery(
    ["LIST_PRODUCT_GROUPS", {
      ...searchQuery,
      modelId: searchParams.get("id"),
      sorted: searchQuery.sorted.value,
    }],
    () => {
      return Queries.listProductGroups(
        new URLSearchParams({
          ...searchQuery,
          modelId: searchParams.get("id"),
          sorted: searchQuery.sorted.value,
        }).toString()
      );
    },
    {
      placeholderData: {
        page: [],
        totalCount: 0,
        totalPage: 0
      },
      keepPreviousData: false,
    }
  );

  const { data: productModelsData } = useQuery(
    ["PRODUCT_MODELS_Q"],
    () => Queries.listModels("page=1&limit=1000"),
    {
      onError: (err) => {
        console.log("err", err);
      },
      placeholderData: {
        page: [],
      },
    }
  );

  const handleUpdate = async (data) => {
    const response = await Queries.updateModel(model?._id, data);
    if (response?._id) {
      toast.success("Model başarılı bir şekilde güncellendi");
    } else {
      toast.error("Model güncellenirken bir hata oluştu. Lütfen tekrar deneyiniz.")
    }
    modelRefetch();
  }

  const handleImagesUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const response = await Queries.uploadMedia(file);
      handleUpdate({
        cover: response?.secure_url
      });
    } else {
      toast.error('Fotoğraf Seçilirken Bir Hata Oluştu. Lütfen Tekrar Deneyiniz');
      e.target.value = null;
    }
  };

  const handleImageInput = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (model) {
      setModelData({
        ...model,
        brand: { value: model?.brand?._id, label: model?.brand?.title },
        parentModel: { value: model?.parentModel?._id, label: model?.parentModel?.title }
      });
      console.log(model)
    }
  }, [model])

  return (
    <div className={classes.root}>
      <CustomDrawer />

      <main className={classes.content}>
        <Toolbar />

        <div>
          <div className="flex flex-col gap-3">
            <div className="bg-white p-3 shadow-sm border rounded-md flex flex-col gap-3">
              <h5 className="font-semibold afterLine">Temel Bilgi</h5>

              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleImagesUpload}
              />

              <div className="flex flex-1 justify-center items-center">
                {modelData?.cover ?
                  <div onClick={handleImageInput} className="cursor-pointer relative w-64 h-64 rounded border shadow-md">
                    <img
                      src={modelData?.cover}
                      alt="cover"
                      className="w-full h-full rounded"
                    />

                    <div className="border border-black bg-white rounded-full p-2 absolute right-2 bottom-2">
                      <CameraIcon />
                    </div>
                  </div>
                  :
                  <div onClick={handleImageInput} className="cursor-pointer relative w-64 h-64 rounded border border-black flex flex-col space-y-2 justify-center items-center">
                    <div>
                      <PlusIcon />
                    </div>
                    <div>Fotoğraf Seç</div>
                  </div>}
              </div>

              <div>
                <Form.Group className="flex flex-1 flex-col">
                  <Form.Label>Model Adı *</Form.Label>
                  <Form.Control
                    type="text"
                    value={modelData?.title}
                    onChange={(e) => setModelData({ ...modelData, title: e.target.value })} />
                </Form.Group>
              </div>

              <div className="">
                <Form.Label>Açıklama</Form.Label>

                <ReactQuill
                  theme="snow"
                  value={modelData?.description}
                  onChange={(value) => setModelData({ ...modelData, description: value })} />
              </div>

              <div>
                <Form.Group className="flex flex-1 flex-col">
                  <Form.Label>Marka</Form.Label>
                  {brandsData?.page?.length !== 0 ?
                    <Select
                      isSearchable
                      value={modelData?.brand}
                      onChange={value => setModelData({ ...modelData, brand: value })}
                      options={brandsData?.page?.map(brand => { return { value: brand._id, label: brand?.title } })}
                    /> : <></>}
                </Form.Group>
              </div>

              <div>
                <Form.Group className="flex flex-1 flex-col">
                  <Form.Label>Üst Model</Form.Label>
                  {productModelsData?.page?.length !== 0 ?
                    <Select
                      isSearchable
                      value={modelData?.parentModel}
                      onChange={value => setModelData({ ...modelData, parentModel: value })}
                      options={productModelsData?.page?.map(brand => { return { value: brand._id, label: brand?.title } })}
                    /> : <></>}
                </Form.Group>
              </div>

              <button onClick={() => handleUpdate({
                title: modelData.title,
                description: modelData.description,
                brand: modelData.brand.value,
                parentModel: modelData.parentModel.value,
              })} className="w-max buttonPurple px-3 py-2 rounded">
                Kaydet
              </button>
            </div>

            <div className="bg-white p-3 shadow-sm border rounded-md flex flex-col gap-1">
              <h5 className="font-semibold afterLine">Ürünler</h5>

              <Form.Group className="flex flex-1 flex-col  my-3">
                <Form.Label>Sıralama Ölçütü</Form.Label>
                <Select
                  value={searchQuery.sorted}
                  onChange={(value) => setSearchQuery({ ...searchQuery, sorted: value })}
                  options={sortedList}
                />
              </Form.Group>

              <Form.Group className="flex flex-1 flex-row space-x-1 border border-gray-200 rounded items-center mb-3">
                <label
                  htmlFor="searchInput"
                  className="px-2 py-3 flex items-center justify-center"
                >
                  <MagnifyingGlassIcon />
                </label>
                <input
                  id="searchInput"
                  className="h-full flex flex-1"
                  placeholder="Tabloda Arama Yapın"
                  value={searchQuery.filterName}
                  onChange={(e) => setSearchQuery({ ...searchQuery, filterName: e.target.value })}
                />
              </Form.Group>

              {totalCount !== 0 ?
                <div className="flex flex-col">
                  {productGroupsData?.map(product => {
                    return (
                      <DetailsPagesProductCard
                        key={product?._id}
                        product={product}
                      />
                    )
                  })}
                </div>
                :
                <div className="flex flex-1 p-2 rounded bg-blue-100 text-blue-700">
                  Modele bağlı ürün bulunmamakta
                </div>}

              <div className="flex flex-1 justify-center items-center mt-2">
                <Pagination
                  page={searchQuery.page}
                  onChange={(_, value) => setSearchQuery({ ...searchQuery, page: value })}
                  count={totalPage}
                  shape="rounded"
                  color="primary"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-3 border bg-white shadow-sm rounded-md p-3">
              <div>
                <h5 className=" py-2">Arama Motoru Optimizasyonu (SEO)</h5>
                <InputGroup size="md">
                  <InputGroup.Text id="inputGroup-sizing-lg">/</InputGroup.Text>
                  <Form.Control
                    aria-label="Large"
                    aria-describedby="inputGroup-sizing-sm"
                    value={modelData?.slug}
                    onChange={(e) => setModelData({ ...modelData, slug: e.target.value })}
                  />
                </InputGroup>
                <Form.Group className="mt-3">
                  <Form.Label>Sayfa Başlığı</Form.Label>
                  <Form.Control
                    type="text"
                    className="max-w-[738px]"
                    value={modelData?.pageTitle}
                    onChange={(e) => setModelData({ ...modelData, pageTitle: e.target.value })}
                  />
                  <Form.Label>Açıklama</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={modelData?.pageDescription}
                    onChange={(e) => setModelData({ ...modelData, pageDescription: e.target.value })}
                  />
                </Form.Group>

                <button onClick={() => handleUpdate({
                  slug: modelData.slug,
                  pageTitle: modelData.pageTitle,
                  pageDescription: modelData.pageDescription,
                })} className="w-max buttonPurple px-3 py-2 rounded mt-2 select-none">
                  Kaydet
                </button>
              </div>
              <div>
                <h5 className="py-2 text-purple-800">Önizleme</h5>
                <div className="p-3 bg-gray-100 border border-radius shadow-sm">
                  <p>sutok.com / filter?modelId={modelData?.slug}</p>
                  <p className="text-purple-900">
                    {modelData?.pageTitle || "Sayfa Başlığı"}
                  </p>
                  <p>
                    {modelData?.pageDescription || "Sayfa Açıklaması"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ModelDetail;
