import {
    TableCell,
    TableRow,
} from "@material-ui/core";
import moment from "moment";
import "moment/locale/tr";
import { FaArrowUpRightFromSquare, FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const UserCard = ({ userData }) => {

    const navigate = useNavigate();

    return (
        <TableRow onClick={() => { navigate(`/userDetail?id=${userData?._id}`) }} className="hover:bg-gray-100 cursor-pointer">
            <TableCell>{userData._id.toUpperCase()}</TableCell>
            <TableCell>
                {((userData.name && userData.surname) && `${userData.name} ${userData.surname}`) || (userData.name && `${userData.name} -`) || (userData.name && `- ${userData.surname}`) || "-"}
            </TableCell>
            <TableCell>
                {userData.email || "-"}
            </TableCell>
            <TableCell>
                {moment(userData.createdAt).format("LL")}
            </TableCell>
            <TableCell>
                <div className="font-semibold">₺ {userData.totalOrdersPrice}</div>
                <div>{userData.totalOrdersCount} ürün</div>
            </TableCell>
        </TableRow>
    )
}

export default UserCard