import React from 'react'
import { FaArrowUpRightFromSquare } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom'

const DetailsPagesProductCard = ({ product }) => {

    const navigate = useNavigate();

    return (
        <div className='flex flex-row space-x-8 items-center p-3 border border-gray-200'>
            <div className='flex flex-1 space-x-8 items-center'>
                <img
                    className='w-16 h-16'
                    src={product?.photos[0]}
                    alt={product?._id}
                />

                <div>
                    {product?.title}
                </div>
            </div>

            <div
                onClick={() => navigate(`/product/detail?id=${product?._id}`)}
                className='p-2.5 rounded-full cursor-pointer hover:bg-gray-100'>
                <FaArrowUpRightFromSquare />
            </div>
        </div>
    )
}

export default DetailsPagesProductCard