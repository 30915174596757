import {
    IconButton,
    TableCell,
    TableRow,
} from "@material-ui/core"
import {
    Info as InfoIcon,
    ArrowUpwardOutlined,
    ArrowDownwardOutlined,
} from "@material-ui/icons"
import { useMutation, useQueryClient } from "react-query"
import * as Queries from "../../utils/queries"

const HomeSectionCard = ({ homeSections, homeSection, homeSectionIndex, setOpenHomeSectionDialog, setSelectedHomeSection, provided }) => {

    const queryClient = useQueryClient()
    const swapOrderMutation = useMutation(Queries.swapHomeSections, {
        onSuccess: () => {
            queryClient.invalidateQueries(["LIST_HOME_SECTIONS"])
        },
        onError: (err) => {
            console.log("err", err.response.data)
        },
    })

    return (
        <div onClick={() => {
            setSelectedHomeSection(homeSection)
            setOpenHomeSectionDialog(true)
        }} key={homeSection._id}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="flex flex-1 flex-row px-3 py-3 border-b border-t border-gray-200 hover:bg-gray-100 cursor-pointer">
            <div className="flex flex-1 items-center">{homeSection.title}</div>
        </div>
    )
}

export default HomeSectionCard